<template>
	<div>
		<div v-if="isCompetitionMode && competitionHasntStarted">
			<div v-html="fields.not_open_message"></div>
		</div>
		<div v-else-if="isCompetitionMode && competitionHasEnded">
			<div v-html="fields.closed_message"></div>
		</div>
		<div v-else>
			<div v-if="error">
				{{ error }}
			</div>
			<div v-else-if="completed">
				<div
					v-html="fields.thank_you_message"
					class="text-left newsletter-content px-3 lg:px-0 mt-12"
				></div>
			</div>
			<div v-else>
				<form
					id="newsletter"
					class="px-3 lg:px-0 py-6 xl:mt-4"
					@submit.prevent="submitNewsletterForm"
					ref="newsletterForm"
				>
					<div class="">
						<select
							v-model="title"
							:required="titleRequired"
							autocomplete="honorific-prefix"
							class="w-4/12 block bg-transparent border border-brand h-[50px] px-3 py-1.5 mb-5 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
						>
							<option value="" disabled>
								Title {{ titleRequired ? '*' : '' }}
							</option>
							<option
								v-for="title in titles"
								:value="title"
								v-text="title"
								:key="title"
							/>
						</select>

						<input
							type="text"
							v-model="firstName"
							autocomplete="given-name"
							placeholder="First Name *"
							class="w-5/12 mr-4 bg-transparent border border-brand h-[50px] px-3 py-1.5 mb-5 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
							required
						/>
						<input
							type="text"
							v-model="lastName"
							autocomplete="family-name"
							placeholder="Last Name *"
							class="w-5/12 bg-transparent border border-brand h-[50px] px-3 py-1.5 mb-5 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
							required
						/>
						<input
							type="email"
							v-model="email"
							autocomplete="email"
							placeholder="Email *"
							class="w-full bg-transparent border border-brand h-[50px] px-3 py-1.5 mb-5 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
							required
							:disabled="disableEmailField"
						/>

						<InputBirthday
							:value="birthday"
							:required="dateOfBirthRequired"
							@update-birthday="(date) => (this.birthday = date)"
						/>

						<select
							v-if="showFavouriteRestaurant"
							v-model="locationId"
							:required="favouriteRestaurantRequired"
							class="w-6/12 mr-4 bg-transparent border border-brand h-[50px] px-3 py-1.5 mb-5 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
						>
							<option value="" disabled>
								Favourite Brasserie
								{{ favouriteRestaurantRequired ? '*' : '' }}
							</option>
							<option
								v-for="restaurant in allRestaurants"
								:value="restaurant.quandoo_id"
								:key="restaurant.id"
							>
								{{ restaurant.post_title }}
							</option>
						</select>
						<select
							v-if="!genderHidden"
							v-model="gender"
							:required="genderRequired"
							autocomplete="sex"
							class="w-5/12 bg-transparent border border-brand h-[50px] px-3 py-1.5 mb-5 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
						>
							<option value="" disabled>
								Gender {{ genderRequired ? '*' : '' }}
							</option>
							<option
								v-for="gender in genders"
								:value="gender.toLowerCase()"
								v-text="gender"
								:key="gender"
							/>
						</select>
						<input
							v-if="!postcodeHidden"
							type="text"
							v-model="postcode"
							autocomplete="postal-code"
							:placeholder="`Postcode${
								postcodeRequired ? ' *' : ''
							}`"
							class="w-6/12 bg-transparent border border-brand h-[50px] px-3 py-1.5 mb-5 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
							:required="postcodeRequired"
						/>
					</div>
					<fieldset class="flex flex-col">
						<label class="w-full font-normal bold">
							Dietary Requirements/Interests:
						</label>
						<label for="vegetarian">
							<input
								v-model="vegetarian"
								type="checkbox"
								class="hidden"
								id="vegetarian"
								name="vegetarian"
							/>
							<img
								loading="lazy"
								alt="Image"
								:class="vegetarian ? 'hidden' : ''"
								src="@/assets/checkbox-unchecked.svg"
								class="cursor-pointer inline w-5 mr-2"
							/>
							<img
								loading="lazy"
								alt="Image"
								:class="vegetarian ? '' : 'hidden'"
								src="@/assets/checkbox-checked.svg"
								class="cursor-pointer inline w-5 mr-2"
							/>
							<span>Vegetarian</span>
						</label>
						<label for="glutenFree">
							<input
								v-model="glutenFree"
								type="checkbox"
								class="hidden"
								id="glutenFree"
								name="glutenFree"
							/>
							<img
								loading="lazy"
								alt="Image"
								:class="glutenFree ? 'hidden' : ''"
								src="@/assets/checkbox-unchecked.svg"
								class="cursor-pointer inline w-5 mr-2"
							/>
							<img
								loading="lazy"
								alt="Image"
								:class="glutenFree ? '' : 'hidden'"
								src="@/assets/checkbox-checked.svg"
								class="cursor-pointer inline w-5 mr-2"
							/>
							<span>Gluten Free</span>
						</label>
						<label for="childrensMenu">
							<input
								v-model="childrensMenu"
								type="checkbox"
								class="hidden"
								id="childrensMenu"
								name="childrensMenu"
							/>
							<img
								loading="lazy"
								alt="Image"
								:class="childrensMenu ? 'hidden' : ''"
								src="@/assets/checkbox-unchecked.svg"
								class="cursor-pointer inline w-5 mr-2"
							/>
							<img
								loading="lazy"
								alt="Image"
								:class="childrensMenu ? '' : 'hidden'"
								src="@/assets/checkbox-checked.svg"
								class="cursor-pointer inline w-5 mr-2"
							/>
							<span>Children's Menu</span>
						</label>
					</fieldset>

					<fieldset
						class="mt-2 flex flex-col"
						v-if="
							isCompetitionMode &&
							(fields.partner_terms || fields.competition_terms)
						"
					>
						<label class="w-full font-normal bold"> Terms: </label>
						<div v-if="fields.competition_terms">
							<div
								v-if="competitionTermsError"
								class="text-red-500"
							>
								This field is required
							</div>
							<label for="competitionTerms" class="block">
								<input
									v-model="competitionTerms"
									type="checkbox"
									class="hidden"
									id="competitionTerms"
									name="competitionTerms"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="competitionTerms ? 'hidden' : ''"
									src="@/assets/checkbox-unchecked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="competitionTerms ? '' : 'hidden'"
									src="@/assets/checkbox-checked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<span
									class="linkable"
									v-html="fields.competition_terms"
								></span>
							</label>
						</div>
						<div v-if="fields.partner_terms">
							<div v-if="partnerTermsError" class="text-red-500">
								This field is required
							</div>
							<label for="partnerTerms" class="block">
								<input
									v-model="partnerTerms"
									type="checkbox"
									class="hidden"
									id="partnerTerms"
									name="partnerTerms"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="partnerTerms ? 'hidden' : ''"
									src="@/assets/checkbox-unchecked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="partnerTerms ? '' : 'hidden'"
									src="@/assets/checkbox-checked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<span
									class="linkable"
									v-html="fields.partner_terms"
								></span>
							</label>
						</div>
					</fieldset>

					<fieldset
						class="flex flex-col my-3"
						v-if="showCahSubscribe || showCoteSubscribe"
					>
						<label class="w-full font-normal bold">
							Newsletters:
						</label>
						<div v-if="showCoteSubscribe">
							<div v-if="coteSubscribeError" class="text-red-500">
								This field is required
							</div>
							<label for="newsletterConsent" class="block">
								<input
									v-model="newsletter"
									type="checkbox"
									class="hidden"
									id="newsletterConsent"
									name="newsletterConsent"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="newsletter ? 'hidden' : ''"
									src="@/assets/checkbox-unchecked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="newsletter ? '' : 'hidden'"
									src="@/assets/checkbox-checked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<span class="linkable" id="coteTerms"
									>I agree to receive news and updates from
									<strong>Côte Brasserie</strong> via
									email</span
								>
							</label>
						</div>
						<div v-if="showCahSubscribe">
							<div v-if="cahSubscribeError" class="text-red-500">
								This field is required
							</div>
							<label for="cah">
								<input
									v-model="cah"
									type="checkbox"
									class="hidden"
									id="cah"
									name="cah"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="cah ? 'hidden' : ''"
									src="@/assets/checkbox-unchecked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="cah ? '' : 'hidden'"
									src="@/assets/checkbox-checked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<span class="linkable" id="cahTerms"
									>I agree to receive news and updates from
									<strong>Côte at Home</strong> via
									email.</span
								>
							</label>
						</div>
						<span class="mt-2">
							<i
								>(Please note, your privacy is important to us,
								we won't share your information with any other
								parties. View our privacy policy
								<a
									href="/privacy-policy/"
									target="_blank"
									rel="noopener"
								>
									<u>here</u></a
								>.)
							</i>
						</span>
					</fieldset>
					<input
						type="submit"
						:value="buttonText"
						class="standard-button header-type-newsletter-form-button mt-0"
						:class="
							getButtonStyle(
								fields.header_type_newsletter_form_button_colour
									.value
							)
						"
					/>
				</form>
			</div>
		</div>
		<div
			id="preferencesEmailPrompt"
			v-if="showEmailPrompt"
			class="fixed absolute-center z-40 bg-cream p-10 px-20 h-[100vh] w-[100vw] flex justify-center items-center"
		>
			<form
				id="newsletter"
				class="px-3 lg:px-0 py-6 xl:mt-4 max-w-[400px]"
				@submit.prevent="sendManagePreferencesEmail"
				ref="fetchPreferencesForm"
				v-if="!managePreferencesEmailSent"
			>
				<h3>Please enter your email</h3>
				<p class="mb-5">
					And we'll send you a link to manage your preferences
				</p>
				<input
					type="email"
					v-model="email"
					autocomplete="email"
					placeholder="Email *"
					class="w-full bg-transparent border border-brand h-[50px] px-3 py-1.5 mb-5 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
					required
					:disabled="disableEmailField"
				/>
				<input
					type="submit"
					:value="'Fetch Preferences'"
					class="standard-button header-type-newsletter-form-button mt-0"
					:class="
						getButtonStyle(
							fields.header_type_newsletter_form_button_colour
								.value
						)
					"
				/>
			</form>
			<div
				class="px-3 lg:px-0 py-6 xl:mt-4 max-w-[400px]"
				v-if="managePreferencesEmailSent"
			>
				<h3>We've sent you an email</h3>
				<p class="mb-5">
					It contains a link to manage your preferences, please check
					your junk folder!
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { DateTime } from 'luxon';
import exponea from '@/plugins/exponea';
import wordpress from '@/plugins/wordpress';
import InputBirthday from './common/InputBirthday.vue';

export default {
	name: 'NewsLetterForm',
	components: {
		InputBirthday,
	},
	props: {
		fields: {
			type: Object,
		},
	},
	data() {
		return {
			titles: ['Mr', 'Mrs', 'Ms', 'Miss', 'Dr', 'Professor', 'Other'],
			genders: ['Male', 'Female', 'Prefer not to say'],
			title: '',
			firstName: null,
			lastName: null,
			email: null,
			location: null,
			locationId: '',
			gender: '',
			postcode: null,
			birthday: null,
			vegetarian: false,
			glutenFree: false,
			childrensMenu: false,
			newsletter: false,
			cah: false,
			competitionTerms: false,
			partnerTerms: false,
			coteSubscribeError: false,
			cahSubscribeError: false,
			competitionTermsError: false,
			partnerTermsError: false,
			error: '',
			disableEmailField: false,
			isUpdating: false,
			completed: false,
			showEmailPrompt: false,
			managePreferencesEmailSent: false,
		};
	},
	computed: {
		hasError() {
			return (
				this.coteSubscribeError ||
				this.cahSubscribeError ||
				this.competitionTermsError ||
				this.partnerTermsError
			);
		},
		buttonText() {
			if (this.isCompetitionMode && this.isUpdating) {
				return 'Save and Enter';
			}
			if (this.isCompetitionMode) {
				return 'Enter';
			}
			if (this.isUpdating) {
				return 'Save';
			}
			return this.fields.header_type_newsletter_form_button_text
				? this.fields.header_type_newsletter_form_button_text
				: 'Sign Up';
		},
		allRestaurants() {
			if (this.fields.restaurants === 'all') {
				return this.$store.state.restaurants;
			}
			if (this.fields.restaurants === 'only') {
				return this.$store.state.restaurants.filter((r) =>
					this.fields.restaurant_ids.includes(r.ID)
				);
			}
			return this.$store.state.restaurants.filter(
				(r) => !this.fields.restaurant_ids.includes(r.ID)
			);
		},
		isCompetitionMode() {
			return this.fields.include_competition;
		},
		competitionCode() {
			return this.isCompetitionMode ? this.fields.competition_code : null;
		},
		competitionName() {
			return this.isCompetitionMode ? this.fields.competition_name : null;
		},
		competitionHasntStarted() {
			if (!this.fields.start_date) {
				return false;
			}
			return (
				DateTime.fromISO(this.fields.start_date).toJSDate() >
				DateTime.now()
			);
		},
		competitionHasEnded() {
			if (!this.fields.end_date) {
				return false;
			}
			return (
				DateTime.fromISO(this.fields.end_date).toJSDate() <
				DateTime.now()
			);
		},
		competitionTermsRequired() {
			return (
				this.fields.competition_terms !== '' && this.isCompetitionMode
			);
		},
		partnerTermsRequired() {
			return (
				this.fields.partner_terms && this.fields.partner_terms_required
			);
		},
		showCoteSubscribe() {
			return !this.fields.hide_cote_subscribe;
		},
		coteSubscribeRequired() {
			return (
				this.fields.cote_subscribe_required &&
				this.showCoteSubscribe &&
				!this.isUpdating
			);
		},
		showCahSubscribe() {
			return !this.fields.hide_cah_subscribe;
		},
		cahSubscribeRequired() {
			return (
				this.fields.cah_subscribe_required &&
				this.showCahSubscribe &&
				!this.isUpdating
			);
		},
		showFavouriteRestaurant() {
			return !this.fields.hide_favourite_restaurant;
		},
		favouriteRestaurantRequired() {
			return (
				this.showFavouriteRestaurant &&
				this.fields.favourite_restaurant_required
			);
		},
		titleRequired() {
			return this.fields.title_required;
		},
		genderRequired() {
			return this.fields.gender_required;
		},
		dateOfBirthRequired() {
			return this.fields.date_of_birth_required;
		},
		postcodeRequired() {
			return this.fields.postcode_required;
		},
		postcodeHidden() {
			return this.fields.hide_postcode;
		},
		genderHidden() {
			return this.fields.hide_gender;
		},
		placementText() {
			const pathName = window.location.pathname.replaceAll('/', '');

			return pathName === 'newsletter-sign-up'
				? 'Newsletter Header'
				: this.$route.meta.title;
		},
	},
	mounted() {
		const { ee } = this.$route.query;

		if (ee) {
			// Convert it to an email address
			this.email = atob(ee).trim().toLowerCase();

			// block the email field from being changed
			this.disableEmailField = true;

			this.fetchPreferences();
		} else if (window.location.href.indexOf('/preferences') > 0) {
			// if no email in url and on preferences page
			this.triggerEmailPrompt();
		}
	},
	methods: {
		async sendManagePreferencesEmail() {
			await exponea.identify(this.email);
			const hashedEmail = btoa(this.email);
			exponea.track('manage_preferences_request', {
				hashedUserEmail: hashedEmail,
			});
			this.managePreferencesEmailSent = true;
		},
		async fetchPreferences() {
			// tell exponea who we've got
			await exponea.identify(this.email);

			// Grab all their details from exponea.
			wordpress
				.exponeaCustomer({ email: this.email })
				.then((r) => r.json())
				.then((resp) => {
					if (!resp.success || resp.success !== true) {
						this.error = 'We can\'t find your account';
						return;
					}
					this.isUpdating = true;
					this.firstName = resp.customer.first_name;
					this.lastName = resp.customer.last_name;
					this.birthday = resp.customer.birth_date
						? DateTime.fromSeconds(
								parseInt(resp.customer.birth_date)
						  ).toJSDate()
						: null;
					this.childrensMenu = resp.customer.childrens_menu;
					this.location = resp.favourite_restaurant;
					this.locationId = resp.customer.favourite_merchant_id;
					this.glutenFree = resp.customer.gluten_free;
					this.vegetarian = resp.customer.vegetarian;
					this.cah = resp.customer.cah;
					this.newsletter = resp.customer.newsletter;
					this.postcode = resp.customer.postcode;
					this.title = resp.customer.title;
					this.gender = resp.customer.gender;
					this.showEmailPrompt = false;
				});
		},
		triggerEmailPrompt() {
			this.showEmailPrompt = true;
		},
		submitNewsletterForm() {
			// reset all our errors.
			this.coteSubscribeError = false;
			this.cahSubscribeError = false;
			this.competitionTermsError = false;
			this.partnerTermsError = false;

			if (!this.competitionTerms && this.competitionTermsRequired) {
				this.competitionTermsError = true;
			}

			if (!this.partnerTerms && this.partnerTermsRequired) {
				this.partnerTermsError = true;
			}

			if (!this.newsletter && this.coteSubscribeRequired) {
				this.coteSubscribeError = true;
			}

			if (!this.cah && this.cahSubscribeRequired) {
				this.cahSubscribeError = true;
			}

			if (this.hasError) {
				return;
			}

			this.completed = false;

			// Set constants to use below.
			const title = this.title || '';
			const { firstName } = this;
			const { lastName } = this;
			const email = this.email
				? this.email.toLowerCase().replace(/\s/g, '')
				: null;

			const location = this.showFavouriteRestaurant
				? this.allRestaurants.find(
						(r) =>
							parseInt(r.quandoo_id) === parseInt(this.locationId)
				  )?.post_title
				: null;
			const locationId = location ? this.locationId : null;

			// Birthdays' are set to midday.
			const birthday = this.birthday
				? DateTime.fromJSDate(this.birthday)
						.set({
							hour: 12,
							minute: 0,
							second: 0,
						})
						.toUnixInteger()
				: null;

			const gender = this.gender ? this.gender.toLowerCase() : '';
			const { vegetarian } = this;
			const { glutenFree } = this;
			const { childrensMenu } = this;
			const { postcode } = this;
			const { competitionName } = this;
			const { competitionCode } = this;
			const competitionTerms = this.fields.competition_terms;
			const competitionTermsAccepted = this.competitionTerms;
			const { partnerTerms } = this.fields;
			const partnerTermsAccepted = this.partnerTerms;
			const subscribedToNewsletter = this.newsletter;
			const subscribeToCah = this.cah;

			wordpress
				.exponeaConsents({ email })
				.then((r) => r.json())
				.then(async (resp) => {
					const currentlySubscribedToNewsletter = resp.newsletter;
					const previouslySubscribedToNewsletter =
						resp.newsletter_sign_up;
					const currentlySubscribedToCah = resp.cah;
					const previouslySubscribedToCah = resp.cah_sign_up;

					// Identify this user to Exponea
					await exponea.identify(email);

					const updateData = {
						first_name: firstName,
						last_name: lastName,
						email,
						vegetarian,
						gluten_free: glutenFree,
						childrens_menu: childrensMenu,
					};

					if (title) {
						updateData.title = title;
					}
					if (gender) {
						updateData.gender = gender;
					}
					if (birthday) {
						updateData.birth_date = birthday;
					}
					if (postcode) {
						updateData.postcode = postcode;
					}

					//  cah - Doesnt have any restaurants
					if (location) {
						updateData.favourite_restaurant = location;
						updateData.favourite_merchant_id = locationId;
					}

					// Update Exponea!
					exponea.update(updateData);

					// Handle the cote newsletter
					if (this.showCoteSubscribe) {
						// Add in the consent if they're not already subscribed
						if (
							subscribedToNewsletter &&
							!currentlySubscribedToNewsletter
						) {
							exponea.track('consent', {
								action: 'accept',
								category: 'newsletter',
								valid_until: 'unlimited',
								message:
									document.getElementById('coteTerms')
										.innerHTML,
								location: window.location.href,
								domain: 'cote.co.uk',
								language: 'en',
								placement: this.placementText,
							});
						}

						// Send a newsletter event that will trigger the scenario to send the sign-up offer.
						if (
							!previouslySubscribedToNewsletter &&
							subscribedToNewsletter
						) {
							exponea.track('newsletter', {
								company: 'cote',
								favourite_restaurant: location,
								favourite_merchant_id: locationId,
								first_name: firstName,
								last_name: lastName,
								title,
								email,
								postcode,
								birth_date: birthday,
								vegetarian,
								gluten_free: glutenFree,
								childrens_menu: childrensMenu,
								location: window.location.href,
							});
						}

						// Did they just unsubscribe?  remove the consent.
						if (
							currentlySubscribedToNewsletter &&
							!subscribedToNewsletter
						) {
							exponea.track('consent', {
								action: 'reject',
								category: 'newsletter',
								valid_until: 'unlimited',
								location: window.location.href,
								domain: 'cote.co.uk',
								language: 'en',
								placement: this.placementText,
							});
						}
					}

					// Handle the Cote at Home Newsletter
					if (this.showCahSubscribe) {
						// Add in the consent if they're not already subscribed
						if (subscribeToCah && !currentlySubscribedToCah) {
							exponea.track('consent', {
								action: 'accept',
								category: 'cah',
								valid_until: 'unlimited',
								message:
									document.getElementById('cahTerms')
										.innerHTML,
								location: window.location.href,
								domain: 'cote.co.uk',
								language: 'en',
								placement: this.placementText,
							});
						}

						// Only send this if it's the first time.
						if (!previouslySubscribedToCah && subscribeToCah) {
							exponea.track('newsletter', {
								company: 'cah',
								first_name: firstName,
								last_name: lastName,
								title,
								email,
								postcode,
								birth_date: birthday,
								vegetarian,
								gluten_free: glutenFree,
								childrens_menu: childrensMenu,
								location: window.location.href,
							});
						}

						// Did they just unsubscribe?
						if (currentlySubscribedToCah && !subscribeToCah) {
							exponea.track('consent', {
								action: 'reject',
								category: 'cah',
								valid_until: 'unlimited',
								location: window.location.href,
								domain: 'cote.co.uk',
								language: 'en',
								placement: this.placementText,
							});
						}
					}

					// if we're updating, send the preference event.
					if (this.isUpdating && !this.isCompetitionMode) {
						exponea.track('preferences', {
							title,
							first_name: firstName,
							last_name: lastName,
							email,
							gender,
							birth_date: birthday,
							vegetarian,
							gluten_free: glutenFree,
							childrens_menu: childrensMenu,
							postcode,
							newsletter: subscribedToNewsletter,
							cah: subscribeToCah,
						});
					}

					if (this.isCompetitionMode) {
						wordpress
							.exponeaHasEnteredCompetition({
								email,
								code: competitionCode,
							})
							.then((r) => r.json())
							.then((resp) => {
								const alreadyEntered = resp.entered || false;

								if (alreadyEntered) {
									this.error = this.fields.already_entered;
									return;
								}

								exponea.track('competition', {
									title,
									first_name: firstName,
									last_name: lastName,
									email,
									gender,
									birth_date: birthday,
									vegetarian,
									gluten_free: glutenFree,
									childrens_menu: childrensMenu,
									postcode,
									newsletter: subscribedToNewsletter,
									cah: subscribeToCah,
									competition_name: competitionName,
									competition_code: competitionCode,
									competition_terms: competitionTerms,
									competition_terms_accepted:
										competitionTermsAccepted,
									partner_terms_accepted: partnerTerms,
									partner_terms: partnerTermsAccepted,
									location: window.location.href,
								});
								this.completed = true;
							});
					} else {
						this.completed = true;
					}
				});
		},

		setBirthday(date) {
			this.birthday = date;
		},
	},
};
</script>

<style>
.linkable a {
	@apply underline;
}
</style>
