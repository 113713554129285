<template>
	<div class="flex flex-col items-center" id="scrollTop">
		<transition name="vt-fade">
			<div v-if="sent">
				<div ref="thankyou">
					<slot name="thankyou" />
				</div>
			</div>

			<div v-else>
				<div v-if="competitionNotOpen">
					<div ref="not_open">
						<slot name="not_open" />
						<slot name="terms" />
					</div>
				</div>

				<div v-if="competitionClosed">
					<div ref="closed">
						<slot name="closed" />
						<slot name="terms" />
					</div>
				</div>

				<div v-if="competitionIsOpen">
					<slot name="intro" />

					<form action="#" method="post" @submit.prevent="validate">
						<!-- title -->
						<fieldset class="input-fieldset">
							<InputSelect
								:value="title"
								:items="titles"
								labelText="Title"
								:lightTheme="true"
								@select="titleSelected"
								placeholder=""
							/>
						</fieldset>
						<!-- firstname -->
						<fieldset class="input-fieldset">
							<label class="block border border-brand">
								<div>
									<p
										class="mb-0 px-3 text-left smaller leading-1500 text-grey-600 tracking-wide mt-1"
									>
										First Name*
									</p>
								</div>
								<div>
									<input
										v-model="trimmedFirstName"
										class="input input-style-light w-full appearance-none p-3 pt-0 pb-0250 border-none"
										type="text"
										placeholder=""
										required
									/>
								</div>
							</label>
						</fieldset>
						<!-- lastname -->
						<fieldset class="input-fieldset">
							<label class="block border border-brand">
								<div>
									<p
										class="mb-0 px-3 text-left smaller leading-1500 text-grey-600 tracking-wide mt-1"
									>
										Last Name*
									</p>
								</div>
								<div>
									<input
										v-model="trimmedLastName"
										class="input input-style-light w-full appearance-none p-3 pt-0 pb-0250 border-none"
										type="text"
										placeholder=""
										required
									/>
								</div>
							</label>
						</fieldset>
						<!-- email -->
						<fieldset class="input-fieldset">
							<div class="text-left">
								<label class="block border border-brand">
									<div>
										<p
											class="mb-0 px-3 smaller leading-1500 text-grey-600 tracking-wide mt-0250"
										>
											Email *
										</p>
									</div>
									<div>
										<input
											type="email"
											name="email"
											@change="validateEmail($event)"
											@keyup="validateEmail($event)"
											@keydown="validateEmail($event)"
											@blur="validateEmail($event)"
											class="input w-full appearance-none p-3 border-none"
											required
										/>
									</div>
								</label>
							</div>
						</fieldset>
						<!-- birthday -->
						<fieldset class="input-fieldset">
							<InputBirthday
								v-model="birthday"
								utils="input-birthday input-style-light"
								:minimumAge="18"
								:required="true"
								@hasError="
									(bool) => (this.birthdayError = bool)
								"
								@update-birthday="
									(date) => (this.birthday = date)
								"
							/>
						</fieldset>
						<!-- postcode -->
						<fieldset v-if="showPostcode" class="input-fieldset">
							<label class="block border border-brand">
								<div>
									<p
										class="mb-0 px-3 smaller text-left leading-1500 text-grey-600 tracking-wide mt-1"
									>
										Postcode*
									</p>
								</div>
								<div>
									<input
										v-model="postcode"
										class="input input-style-light w-full appearance-none p-3 pt-0 pb-0250 border-none"
										type="text"
										placeholder=""
										required
									/>
								</div>
							</label>
						</fieldset>
						<!-- favourite restaurant -->
						<fieldset
							v-if="showFavouriteRestaurant"
							class="input-fieldset"
						>
							<InputSelect
								field="post_title"
								labelText="Favourite Restaurant"
								placeholder=""
								:items="brasseries"
								:lightTheme="true"
								:value="location"
								@select="locationSelected"
							/>
						</fieldset>
						<!-- diet -->
						<fieldset
							class="input-fieldset"
							v-if="!hideDietaryRequirements"
						>
							<label class="text-18 leading-18"
								>Dietary Requirements/Interests:</label
							>
							<label
								class="input-checkbox input-style-light mb-half"
								for="vegetarian"
							>
								<input
									type="checkbox"
									id="vegetarian"
									name="vegetarian"
									v-model="vegetarian"
								/>
								<span>Vegetarian</span>
							</label>
							<label
								class="input-checkbox input-style-light mb-half"
								for="glutenFree"
							>
								<input
									type="checkbox"
									id="glutenFree"
									name="glutenFree"
									v-model="glutenFree"
								/>
								<span>Gluten Free</span>
							</label>
							<label
								class="input-checkbox input-style-light mb-half"
								for="childrensMenu"
							>
								<input
									type="checkbox"
									id="childrensMenu"
									name="childrensMenu"
									v-model="childrensMenu"
								/>
								<span>Children's Menu</span>
							</label>
						</fieldset>
						<!-- newsletter -->
						<div class="mt-4 mb-1">
							<fieldset class="input-fieldset">
								<label
									class="input-checkbox small input-style-light"
									for="competitionTerms"
								>
									<input
										type="checkbox"
										id="competitionTerms"
										name="competitionTerms"
										v-model="competitionTermsAccepted"
										:value="competitionTermsAccepted"
									/>
									<span v-html="competitionTerms"></span>
								</label>
								<span
									class="mt-half text-red"
									v-if="competitionTermsError"
									>{{ competitionTermsError }}</span
								>
							</fieldset>

							<fieldset class="input-fieldset">
								<div v-if="!hideCahSubscribe">
									<label
										class="input-checkbox input-style-light mb-half"
										for="cah"
									>
										<input
											type="checkbox"
											id="cah"
											name="cah"
											ref="unsubscribe_cah"
											v-model="cah"
										/>
										<span v-html="cahLabel" />
									</label>
								</div>
								<div v-if="!hideCorporateSubscribe">
									<label
										class="input-checkbox input-style-light mb-half"
										for="corporateSubscribe"
									>
										<input
											type="checkbox"
											id="corporateSubscribe"
											name="corporateSubscribe"
											ref="unsubscribe_corporate"
											v-model="corporateSubscribe"
										/>
										<span
											v-html="corporateSubscribeLabel"
										/>
									</label>
								</div>
								<div v-if="!hideCoteSubscribe">
									<label
										class="input-checkbox input-style-light mb-half"
										for="newsletter"
									>
										<input
											type="checkbox"
											id="newsletter"
											name="newsletter"
											ref="unsubscribe_cote"
											v-model="newsletter"
										/>
										<span v-html="newsletterLabel" />
									</label>
								</div>

								<div
									class="font-normal text-xs ml-10 text-left italic"
									v-html="consentText"
								/>

								<p
									class="mt-half text-red"
									v-if="consentError"
									v-text="consentError"
								/>
							</fieldset>

							<fieldset class="input-fieldset" v-if="partner1">
								<label
									class="input-checkbox small input-style-light"
									for="partner1Terms"
								>
									<input
										type="checkbox"
										id="partner1Terms"
										name="partner1Terms"
										v-model="partner1TermsAccepted"
										:value="partner1TermsAccepted"
									/>
									<span v-html="partner1Terms"></span>
								</label>
								<span
									class="mt-half text-red"
									v-if="partner1TermsError"
									>{{ partner1TermsError }}</span
								>
							</fieldset>

							<fieldset class="input-fieldset" v-if="partner2">
								<label
									class="input-checkbox small input-style-light"
									for="partner2Terms"
								>
									<input
										type="checkbox"
										id="partner2Terms"
										name="partner2Terms"
										v-model="partner2TermsAccepted"
										:value="partner2TermsAccepted"
									/>
									<span v-html="partner2Terms"></span>
								</label>
								<span
									class="mt-half text-red"
									v-if="partner2TermsError"
									>{{ partner2TermsError }}</span
								>
							</fieldset>
						</div>
						<!-- submit -->
						<fieldset class="input-fieldset">
							<button
								v-text="`Submit`"
								type="submit"
								class="button-light w-full"
								:class="{ 'button-loading': disabled }"
								:disabled="disabled"
							></button>
						</fieldset>
					</form>

					<slot name="terms" />
				</div>
			</div>
		</transition>
		<MessagePopup v-if="hasError" @close="buttonClicked">
			<h2 class="mt-1 mb-half text-h3 uppercase">
				{{ this.error.title }}
			</h2>
			<p
				class="mb-1 text-18 with-links leading-14"
				v-html="this.error.error"
			></p>
			<button
				v-if="hasButton"
				type="button"
				class="button-light message-popup-close"
			>
				{{ buttonText() }}
			</button>
		</MessagePopup>
	</div>
</template>

<script type="text/javascript">
// Npm
// Components
import Validating from '@/mixins/Validating';
import wordpress from '@/plugins/wordpress';
import exponea from '@/plugins/exponea';
import { DateTime } from 'luxon';
import InputSelect from '@/components/common/InputSelect.vue';
import InputBirthday from '@/components/common/InputBirthday.vue';
import MessagePopup from '@/components/common/MessagePopup.vue';
// Mixins
import Utilities from '../mixins/Utilities';

export default {
	components: {
		// InputPhone,
		// InputEmail,
		InputSelect,
		InputBirthday,
		// InputLocations,
		MessagePopup,
	},

	mixins: [
		// Locations,
		Validating,
		Utilities,
	],

	props: {
		company: { type: String, required: true },
		competitionClosed: { type: Boolean, default: false },
		competitionCode: { type: String, required: true },
		competitionName: { type: String, required: true },
		competitionNotOpen: { type: Boolean, default: false },
		competitionTerms: { type: String, default: false },
		hideCahSubscribe: { type: Boolean, default: false },
		hideCorporateSubscribe: { type: Boolean, default: false },
		hideCoteSubscribe: { type: Boolean, default: false },
		hideDietaryRequirements: { type: Boolean, default: false },
		showFavouriteRestaurant: { type: Boolean, required: false },
		showPostcode: { type: Boolean, required: true },

		// Partner 1
		partner1: { type: Boolean, required: true },
		partner1Code: { type: String, required: true },
		partner1TermsRequired: { type: Boolean, required: true },
		partner1Terms: { type: String, required: true },

		// Partner 2
		partner2: { type: Boolean, required: true },
		partner2Code: { type: String, required: true },
		partner2TermsRequired: { type: Boolean, required: true },
		partner2Terms: { type: String, required: true },
	},
	data() {
		return {
			alreadyEntered: false,
			birthday: null,
			birthdayError: false,
			cah: false,
			cahLabel:
				'I agree to receive news and updates from <br /><strong>Côte at Home</strong> via email.',
			childrensMenu: false,
			competitionTermsAccepted: false,
			competitionTermsError: null,
			consentError: null,
			consentText:
				'(Please note, your privacy is important to us, we won’t share your information with any other parties unless you specifically authorise us to do so. View our privacy policy <a href="/privacy-policy/" target="_blank" rel="noopener"><u>here</u></a>.)',
			corporateSubscribe: false,
			corporateSubscribeLabel:
				'I agree to receive Corporate news and updates from <br /><strong>Côte Brasserie</strong> via email.',
			email: null,
			emailValid: null,
			error: false,
			firstName: null,
			glutenFree: false,
			lastName: null,
			loading: false,
			location: null,
			locationId: null,
			newsletter: false,
			newsletterLabel:
				'I agree to receive news and updates from <br /><strong>Côte Brasserie</strong> via email',
			partner1TermsAccepted: false,
			partner2TermsAccepted: false,
			partner1TermsError: null,
			partner2TermsError: null,
			postcode: null,
			sent: false,
			title: null,
			titles: ['Mr', 'Mrs', 'Ms', 'Miss', 'Dr', 'Professor', 'Other'],
			vegetarian: false,
		};
	},

	computed: {
		trimmedFirstName: {
			get() {
				return this.firstName;
			},
			set(value) {
				this.firstName = value.trim();
			},
		},
		trimmedLastName: {
			get() {
				return this.lastName;
			},
			set(value) {
				this.lastName = value.trim();
			},
		},
		brasseries() {
			return this.$store.state.restaurants;
			// return this.$store.state.restaurants
		},
		//  is this form in a disabled state?
		disabled() {
			return this.isValidating || this.loading || this.hasError;
		},

		// is the Competition Open ?
		competitionIsOpen() {
			return !this.competitionClosed && !this.competitionNotOpen;
		},

		hasError() {
			return this.error !== false;
		},
	},

	methods: {
		validateEmail($event) {
			const { value } = $event.target;
			const re =
				/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			if (!re.test(value)) {
				this.emailValid = false;
			} else {
				this.email = value;
				this.emailValid = true;
			}
		},
		buttonClicked() {
			if (this.error.redirectOnClose) {
				window.location = `${window.location.protocol}//${window.location.host}${this.error.redirectOnClose}`;
			}

			this.error = false;
		},

		buttonLink() {
			return this.error.button_link;
		},

		buttonText() {
			if (this.error.button === 'close') {
				return 'Close';
			}

			return this.error.button_text;
		},

		emailEntered({ email, isValid }) {
			this.email = email;
			this.emailValid = isValid;
		},

		hasButton() {
			return this.error.button !== 'none';
		},

		locationSelected(item) {
			if (item !== null) {
				this.location = item.post_title;
				this.locationId = item.quandoo_id;
			} else {
				this.location = null;
				this.locationId = null;
			}
		},

		removeWhiteSpace(str) {
			return str.replace(/\s+/g, '');
		},

		async submit() {
			if (!this.loading) {
				this.loading = true;
				this.competitionTermsError = null;

				const partnersCodes = [];
				if (this.partner1 && this.partner1TermsAccepted) {
					partnersCodes.push(this.partner1Code);
				}

				if (this.partner2 && this.partner2TermsAccepted) {
					partnersCodes.push(this.partner2Code);
				}

				const birthdayTimestanmp = this.birthday
					? DateTime.fromJSDate(this.birthday)
							.set({
								hour: 12,
								minute: 0,
								second: 0,
							})
							.toUnixInteger()
					: null;

				// Set up the vars we will be using.
				const consolidatedData = {
					alreadyEnteredCompetition: false,
					alreadyRegisteredForNewsletter: false,
					birthday: birthdayTimestanmp,
					cah: this.cah,
					childrensMenu: this.childrensMenu,
					company: this.company,
					competitionCode: this.competitionCode,
					competitionName: this.competitionName,
					competitionTerms: this.competitionTerms,
					consentText: this.consentText,
					corporateSubscribe: this.corporateSubscribe,
					email: this.email
						? this.removeWhiteSpace(this.email.toLowerCase())
						: null,
					error: false,
					firstName: this.firstName,
					glutenFree: this.glutenFree,
					lastName: this.lastName,
					location: this.location,
					locationId: parseInt(this.locationId),
					newsletter: this.newsletter,
					partners: partnersCodes.join(','),
					postcode: this.postcode,
					title: this.title || '',
					vegetarian: this.vegetarian,
				};

				// GA Tracking
				if (this.$store.getCookieConsent) {
					this.$gtag.event('competition', {
						event_category: 'conversions',
					});
				}

				// Let's check if this person already entered the competition
				wordpress
					.exponeaHasEnteredCompetition({
						email: consolidatedData.email,
						code: consolidatedData.competitionCode,
					})
					.then((r) => r.json())
					.then((resp) => {
						consolidatedData.alreadyEntered = resp.entered || false;

						if (consolidatedData.alreadyEntered) {
							this.error = {
								when: 'This happens when a customer tried to enter a competition they have already entered',
								title: 'Already entered',
								error: 'It looks like you have already entered this competition. Please note that this competition is limited to one entry per customer.',
								email: false,
								log: false,
								display: true,
								button: 'close',
								redirectOnClose: '/',
							};
							this.alreadyEntered = true;
							return;
						}

						// Is this person already on the mailing list?
						wordpress
							.exponeaConsents({ email: consolidatedData.email })
							.then((r) => r.json())
							.then(async (resp) => {
								// Identify this user to Exponea
								await exponea.identify(consolidatedData.email);

								const updateData = {
									birth_date: consolidatedData.birthday,
									childrens_menu:
										consolidatedData.childrensMenu,
									email: consolidatedData.email,
									first_name: consolidatedData.firstName,
									gluten_free: consolidatedData.glutenFree,
									last_name: consolidatedData.lastName,
									title: consolidatedData.title,
									vegetarian: consolidatedData.vegetarian,
								};

								if (consolidatedData.postcode !== null) {
									updateData.postcode =
										consolidatedData.postcode;
								}

								if (
									consolidatedData.location !== null &&
									consolidatedData.locationId !== null
								) {
									updateData.favourite_restaurant =
										consolidatedData.location;
									updateData.favourite_merchant_id =
										consolidatedData.locationId;
								}

								// Update Exponea!
								exponea.update(updateData);

								const alwaysSendConsents = true;

								const consentPayload = {
									action: 'accept',
									valid_until: 'unlimited',
									location: window.location.href,
									domain: 'cote.co.uk',
									language: 'en',
									placement: this.competitionName,
								};

								if (consolidatedData.cah) {
									consentPayload.category = 'cah';
									consentPayload.message = `${this.cahLabel} ${this.consentText}`;

									/* if we're always going to send the consent then do so */
									/* otherwise we'll only send consent if User has not previously consented */
									if (alwaysSendConsents || !resp.cah) {
										exponea.track(
											'consent',
											consentPayload
										);
									}

									const newsletterEventRequired = false;

									// Only send this if it's the first time.
									if (
										newsletterEventRequired &&
										!resp.cah_sign_up
									) {
										exponea.track('newsletter', {
											birth_date:
												consolidatedData.birthday,
											childrens_menu:
												consolidatedData.childrensMenu,
											company: 'cah',
											email: consolidatedData.email,
											first_name:
												consolidatedData.firstName,
											gluten_free:
												consolidatedData.glutenFree,
											last_name:
												consolidatedData.lastName,
											location: window.location.href,
											postcode: consolidatedData.postcode,
											title: consolidatedData.title,
											vegetarian:
												consolidatedData.vegetarian,
										});
									}
								}

								if (consolidatedData.newsletter) {
									consentPayload.category = 'newsletter';
									consentPayload.message = `${this.newsletterLabel} ${this.consentText}`;

									/* if we're always going to send the consent then do so */
									/* otherwise we'll only send consent if User has not previously consented */
									if (
										alwaysSendConsents ||
										!resp.data.newsletter
									) {
										exponea.track(
											'consent',
											consentPayload
										);
									}
								}

								if (consolidatedData.corporateSubscribe) {
									consentPayload.category = 'corporate';
									consentPayload.message = `${this.corporateSubscribeLabel} ${this.consentText}`;

									/* if we're always going to send the consent then do so */
									/* otherwise we'll only send consent if User has not previously consented */
									if (
										alwaysSendConsents ||
										!resp.data.corporate
									) {
										exponea.track(
											'consent',
											consentPayload
										);
									}
								}

								const competitionParams = {
									company: consolidatedData.company,
									competition_code:
										consolidatedData.competitionCode,
									competition_name:
										consolidatedData.competitionName,
									competition_terms:
										consolidatedData.competitionTerms,
									email: consolidatedData.email,
									first_name: consolidatedData.firstName,
									last_name: consolidatedData.lastName,
									location: window.location.href,
									title: consolidatedData.title,
									partners: consolidatedData.partners,
								};

								if (this.partner1) {
									competitionParams.partner1_terms =
										this.partner1Terms;
								}
								if (this.partner2) {
									competitionParams.partner2_terms =
										this.partner2Terms;
								}

								exponea.track('competition', competitionParams);
								document
									.getElementById('scrollTop')
									.scrollIntoView(
										{ behavior: 'smooth' },
										true
									);
								this.loading = false;
								this.sent = true;
							})
							.catch((error) => {
								this.loading = false;
								console.log('ajax_error', error);
							});
					})
					.catch((error) => {
						this.loading = false;
						console.log('ajax_error', error);
					});
			}
		},

		titleSelected(item) {
			this.title = item;
		},

		validate() {
			let errors = false;

			if (!this.emailValid) {
				errors = true;
			}

			if (this.birthdayError) {
				errors = true;
			}

			if (!this.competitionTermsAccepted) {
				this.competitionTermsError =
					'Please check this box to enter the competition.';
				errors = true;
			} else {
				this.competitionTermsError = null;
			}

			if (this.partner1TermsRequired && !this.partner1TermsAccepted) {
				this.partner1TermsError =
					'Please check this box to enter the competition.';
				errors = true;
			} else {
				this.partner1TermsError = null;
			}
			if (this.partner2TermsRequired && !this.partner2TermsAccepted) {
				this.partner2TermsError =
					'Please check this box to enter the competition.';
				errors = true;
			} else {
				this.partner2TermsError = null;
			}

			const atLeastOneNewsletterRequired = false;

			if (atLeastOneNewsletterRequired) {
				if (!this.newsletter && !this.cah) {
					this.consentError =
						'Please choose at least one newsletter.';
					errors = true;
				}
			}

			if (!errors) {
				this.submit();
			}
		},
	},
};
</script>

<style scoped>
.competition-terms-container {
	padding: 1rem 0;
}

.input,
.input-textarea,
.input-date input {
	display: inline-block;
	border-width: 1px;
	border-color: #d2c298;
	background-color: transparent;
	font-weight: 400;
	font-size: 16px;
	text-align: left;
	-webkit-border-radius: 0.125rem;
	border-radius: 0.125rem;
	/*padding: .7rem 1rem;*/
	outline: none;
}

.input-fieldset {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1rem;
}

.input-fieldset {
	max-width: 26rem;
}

@media only screen and (min-width: 768px) {
	.input-fieldset {
		min-width: 416px;
	}
}

.input-fieldset label {
	font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', sans-serif;
	color: #132632;
	font-size: 20px;
	font-style: normal;
}

.input-fieldset .input,
.input-checkbox span {
	font-family: Gill Sans, Gill Sans MT, Calibri, sans-serif !important;
}

.input-fieldset .input,
.input-fieldset .input-textarea,
.input-fieldset .input-date input,
.input-fieldset input[type='email'] {
	display: inline-block;
	border-width: 1px;
	/* border-color: #d2c298; */
	background-color: transparent;
	font-weight: 400;
	font-size: 16px;
	text-align: left;
	/*color: #fff;*/
	-webkit-border-radius: 0.125rem;
	border-radius: 0.125rem;
	/*padding: .7rem 1rem;*/
	color: #132032 !important;
}

.input-checkbox span {
	display: block;
	position: relative;
	font-size: 16px;
	line-height: 1.8;
	text-align: left;
	position: relative;
	padding-left: 2.5rem;
}

.input-checkbox span:before {
	border-width: 1px;
	border-color: #d2c298;
	background-color: transparent;
	-webkit-border-radius: 0.125rem;
	border-radius: 0.125rem;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 1.5rem;
	height: 1.5rem;
}

.input-checkbox span:after {
	color: #fff;
	content: '\2713';
	position: absolute;
	top: 0;
	left: 0;
	width: 1.5rem;
	line-height: 1.5rem;
	text-align: center;
	opacity: 0;
}

.input-checkbox input:checked + span:after {
	opacity: 1;
}

.input-checkbox.input-style-light {
	color: #132032;
}

.input-checkbox.input-style-light span:after {
	color: #132032;
}

.input-select {
	position: relative;
}

.input-select .input,
.input-select .input-textarea,
.input-select .input-date input,
.input-date .input-select input {
	cursor: pointer;
	outline: none;
}

.input-select .input:disabled,
.input-select .input-textarea:disabled,
.input-select .input-date input:disabled,
.input-date .input-select input:disabled {
	cursor: not-allowed;
}

.input-select label.input,
.input-select label.input-textarea {
	cursor: pointer;
	color: rgba(255, 255, 255, 0.5);
}

.input-select label.input.input-has-value,
.input-select label.input-has-value.input-textarea {
	color: white;
}

.input-select .input-select-list {
	width: 100%;
	position: absolute;
	z-index: 10;
	background-color: #132032;
	-webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);
	-webkit-border-radius: 0.125rem;
	border-radius: 0.125rem;
	top: -webkit-calc(100% - 1px);
	top: calc(100% - 1px);
	border: 1px solid #d2c298;
}

.input-select .input-select-list-inner {
	background-color: #132032;
	max-height: 14rem;
	border-top: 1px solid #2f3848;
	overflow: auto;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

.input-select .input-select-list-inner::-webkit-scrollbar {
	width: 10px;
}

.input-select .input-select-list-inner::-webkit-scrollbar-track {
	-webkit-border-radius: 0;
	border-radius: 0;
	background: rgba(255, 255, 255, 0.1);
}

.input-select .input-select-list-inner::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: white;
}

.input-select .input-select-item {
	display: block;
	width: 100%;
	font-weight: 400;
	border-top-width: 1px;
	border-color: #2f3848;
	font-size: 16px;
	color: #fff;
	text-align: left;
	/*padding: .7rem 1rem;*/
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	text-decoration: none;
	background-color: transparent;
}

.input-select .input-select-item:first-child {
	border-top: 0;
}

.input-select .input-select-item.focused {
	background-color: #2f3848;
	cursor: pointer;
}

.input-select .input-select-item.no-results {
	font-family: mrs-eaves, serif;
	color: #fff;
	font-style: italic;
}

.input-select.input-style-light label.input,
.input-select.input-style-light label.input-textarea {
	color: #9599a0;
}

.input-select.input-style-light label.input.input-has-value,
.input-select.input-style-light label.input-has-value.input-textarea,
.input-select.input-style-light input.input,
.input-select.input-style-light input.input-textarea,
.input-select.input-style-light .input-date input,
.input-date .input-select.input-style-light input {
	color: #132032;
}

.input-select.input-style-light .input-select-list-inner {
	background-color: #fff;
	border-top: 1px solid #f1f3f5;
}

.input-select.input-style-light
	.input-select-list-inner::-webkit-scrollbar-track {
	-webkit-border-radius: 0;
	border-radius: 0;
	background: rgba(221, 221, 221, 0.5);
}

.input-select.input-style-light
	.input-select-list-inner::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #ccc;
}

.input-select.input-style-light .input-select-item {
	border-color: #f1f3f5;
	color: #132032;
}

.input-select.input-style-light .input-select-item.focused {
	background-color: #f1f3f5;
}

.input-select.input-style-light .input-select-item.no-results {
	color: #9599a0;
}

.input-fieldset .input-checkbox input {
	position: absolute;
	left: -99999px;
	width: 0;
	height: 0;
}

.input-checkbox {
	display: block;
	min-height: 1.5rem;
	color: #fff;
	cursor: pointer;
}

.mb-half {
	margin-bottom: 0.5rem;
}

.input-fieldset .button-light-gold,
.input-fieldset .button-light-dark,
.input-fieldset .button-dark,
.input-fieldset .button-light,
.input-fieldset .button {
	display: inline-block;
	min-width: 11rem;
	border-width: 1px;
	border-color: #d2c298;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	-webkit-border-radius: 0.125rem;
	border-radius: 0.125rem;
	padding: 0.7rem;
	-webkit-transition: all 0.25s ease;
	-o-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

.input-fieldset .button-light-gold:disabled,
.input-fieldset .button-light-dark:disabled,
.input-fieldset .input-fieldset .button-dark:disabled,
.input-fieldset .button-light:disabled,
.input-fieldset .button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

.input-fieldset .button-loading.button-light-gold,
.input-fieldset .button-loading.button-light-dark,
.input-fieldset .button-loading.button-dark,
.input-fieldset .button-loading.button-light,
.input-fieldset .button-loading.button {
	position: relative;
	color: transparent;
}

.input-fieldset .button-loading.button-light-gold:before,
.input-fieldset .button-loading.button-light-dark:before,
.input-fieldset .button-loading.button-dark:before,
.input-fieldset .button-loading .button-light:before,
.input-fieldset .button-loading.button:before,
.input-fieldset .button-loading.button-light-gold:after,
.input-fieldset .button-loading.button-light-dark:after,
.input-fieldset .button-loading.button-dark:after,
.input-fieldset .button-loading.button-light:after,
.input-fieldset .button-loading.button:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -1rem;
	margin-left: -1rem;
	width: 2rem;
	height: 2rem;
	background: transparent;
	-webkit-border-radius: 100%;
	border-radius: 100%;
}

.input-fieldset .button-loading.button-light-gold:before,
.input-fieldset .button-loading.button-light-dark:before,
.input-fieldset .button-loading.button-dark:before,
.input-fieldset .button-loading.button-light:before,
.input-fieldset .button-loading.button:before {
	border: 4px solid #d2c298;
	opacity: 0.2;
}

.input-fieldset .button-loading.button-light-gold:after,
.input-fieldset .button-loading.button-light-dark:after,
.input-fieldset .button-loading.button-dark:after,
.input-fieldset .button-loading.button-light:after,
.input-fieldset .button-loading.button:after {
	border: 4px solid transparent;
	border-top: 4px solid #d2c298;
	-webkit-animation-name: button-loading;
	animation-name: button-loading;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.input-fieldset .button-loading.button-light-gold:hover,
.input-fieldset .button-loading.button-light-dark:hover,
.input-fieldset .button-loading.button-dark:hover,
.input-fieldset .button-loading.button-light:hover,
.input-fieldset .button-loading.button:hover,
.input-fieldset .button-loading.button-light-gold:focus,
.input-fieldset .button-loading.button-light-dark:focus,
.input-fieldset .button-loading.button-dark:focus,
.input-fieldset .button-loading.button-light:focus,
.input-fieldset .button-loading.button:focus {
	color: transparent !important;
}

.input-fieldset .button-small.button-light-gold,
.input-fieldset .button-small.button-light-dark,
.input-fieldset .button-small.button-dark,
.input-fieldset .button-small.button-light,
.input-fieldset .button-small.button {
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-color: #d2c298;
	font-size: 12px;
}

.input-fieldset .button {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.4);
}

.input-fieldset .button:hover,
.input-fieldset .button:focus {
	background-color: #d2c298;
	color: #132032;
}

.input-fieldset .button:disabled:hover,
.input-fieldset .button:disabled:focus {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.4);
}

.input-fieldset .button-light {
	background-color: #d2c298;
	color: #fff;
}

.input-fieldset .button-light:hover,
.input-fieldset .button-light:focus {
	background-color: #e6d9b7;
}

.button-light:disabled {
	background-color: #f1f3f5;
	border-color: #f1f3f5;
	color: #f1f3f5;
}

.button-light:disabled:hover,
.button-light:disabled:focus {
	background-color: #f1f3f5;
	border-color: #f1f3f5;
	color: #f1f3f5;
}

.input-fieldset .button-dark {
	background-color: #132032;
	color: #fff;
}

.input-fieldset .button-dark:hover,
.input-fieldset .button-dark:focus {
	background-color: #2f3848;
}

.input-fieldset .button-dark:disabled {
	background-color: #f1f3f5;
	border-color: #f1f3f5;
	color: #f1f3f5;
}

.input-fieldset .button-dark:disabled:hover,
.input-fieldset .button-dark:disabled:focus {
	background-color: #f1f3f5;
	border-color: #f1f3f5;
	color: #f1f3f5;
}

.input-fieldset .button-light-dark {
	color: #132032;
	background-color: rgba(255, 255, 255, 0.4);
}

.input-fieldset .button-light-dark:hover,
.input-fieldset .button-light-dark:focus {
	background-color: #fff;
	color: #132032;
}

.input-fieldset .button-light-dark:disabled:hover,
.input-fieldset .button-light-dark:disabled:focus {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.4);
}

.input-fieldset .button-light-gold {
	color: #132032;
	background-color: white;
}

.input-fieldset .button-light-gold:hover,
.input-fieldset button-light-gold:focus {
	background-color: #d2c298;
	color: #132032;
}

.input-fieldset .button-light-gold:disabled:hover,
.input-fieldset .button-light-gold:disabled:focus {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.4);
}

.text-red {
	color: red;
}
</style>
