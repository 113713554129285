<template><div></div></template>
<script>
export default {
	name: 'FreshDeskChatBot',
	mounted() {
		(function (d, w, c) {
			if (!d.getElementById('spd-busns-spt')) {
				const n = d.getElementsByTagName('script')[0];
				const s = d.createElement('script');
				let loaded = false;
				s.id = 'spd-busns-spt';
				s.async = 'async';
				s.setAttribute('data-self-init', 'false');
				s.setAttribute('data-init-type', 'opt');
				s.src =
					'https://cdn.euc-freshbots.ai/assets/share/js/freshbots.min.js';
				s.setAttribute(
					'data-client',
					'37ab8a3d31fa89812de640e1c8d3daf7430eb47e'
				);
				s.setAttribute(
					'data-bot-hash',
					'5b2080ccef7b166280ed38778a4b8bbabcd7cc7b'
				);
				s.setAttribute('data-env', 'prod');
				s.setAttribute('data-region', 'euc');
				if (c) {
					s.onreadystatechange = s.onload = function () {
						if (!loaded) {
							c();
						}
						loaded = true;
					};
				}
				n.parentNode.insertBefore(s, n);
			}
		})(document, window, () => {
			Freshbots.initiateWidget(
				{ autoInitChat: false, getClientParams() {} },
				(successResponse) => {},
				(errorResponse) => {}
			);
		});
	},
};
</script>

<style>
.bg-header {
}

.chat-container .active,
#joe-menu-dropdown.Slsh-list-items ul li:hover,
.chat-container .chat-footer ul li:hover,
.chat-container .chat-header ul li .joe-dropdown li:hover,
.chat-container .chat-history .other-message {
	/**
  for example:
   */
	background-color: red;
}

.chat-container .chat-header {
}

.chat-container .chat-body {
}

.other-message {
	font-size: 100px;
}

.mobile-chat-container {
}
</style>
