<template>
	<transition>
		<div
			v-if="cookieConsent === null"
			class="mx-5 lg:mx-28 xl:mx-40 2xl:mx-64 fixed bottom-24 bg-white p-3 lg:p-5 flex justify-between items-center z-70 shadow-lg"
		>
			<div>
				<p class="text-left m-0 w-full 2xl:w-8/12">
					We use cookies to ensure that we give you the best
					experience on our website. See our privacy policy for more
					info.
				</p>
			</div>
			<div
				class="flex flex-col lg:flex-row justify-between items-center ml-4 lg:ml-0"
			>
				<button
					type="button"
					class="standard-button bg-mustard text-brand border border-mustard mt-0 lg:mr-2"
					@click="setCookieConsent(true)"
				>
					Accept
				</button>
				<button
					type="button"
					class="standard-button bg-brand text-cream border border-brand mt-3 lg:mt-0 lg:ml-2"
					@click="setCookieConsent(false)"
				>
					Reject
				</button>
			</div>
		</div>
	</transition>
</template>

<script>
import { DateTime } from 'luxon';
import wordpress from '@/plugins/wordpress';

export default {
	name: 'CookieBanner',
	computed: {
		cookieConsent() {
			return this.$store.state.cookieConsent;
		},
	},
	methods: {
		setCookieConsent(accepted) {
			const expiry = DateTime.now().plus({ years: 1 }).toJSDate();
			this.$cookies.set('cookie_consent', `${accepted}`, expiry);
			this.$store.commit('setCookieConsent', accepted);
			if (accepted) {
				this.$emit('trackingAgreed');
				wordpress.countTrackingAcceptance({
					trackingStatus: 'accepted',
				});
			} else {
				// wordpress.countTrackingAcceptance({trackingStatus: 'rejected'})
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
	transition: opacity 0.25s ease;
}
.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
