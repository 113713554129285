<template>
	<div>
		<placeholder
			:show="showPlaceholder"
			:text="placeholderText"
			:class="'bg-cream fixed top-[64px] xl:top-[80px] left-0 right-0 z-70 w-screen'"
		/>
		<!--  Full screen overlay  -->
		<transition>
			<div
				v-if="open || overlayOpen"
				class="w-full h-screen bg-cream z-60 fixed top-0 left-0 overflow-y-scroll flex flex-col"
				:class="{ 'pb-[200px]': currentStep > 1 }"
			>
				<div
					class="px-5 lg:px-10 bg-cream py-6 w-full flex items-center justify-between sticky top-0 z-70"
				>
					<img
						loading="lazy"
						alt="Image"
						src="@/assets/cote-logo-dark.svg"
						class="w-32 cursor-pointer"
						@click="goHome"
						@keypress="goHome"
					/>
					<x-icon
						class="ml-6 w-10 text-brand inline cursor-pointer"
						@mouseover="hover.menu = true"
						@focusin="hover.menu = true"
						@mouseout="hover.menu = false"
						@focusout="hover.menu = false"
						@click="closeOverlay"
						:class="{ bounce: hover.menu }"
					></x-icon>
				</div>
				<transition>
					<div
						v-if="error.visible"
						class="bg-brand pt-1 p-4 w-full z-20 lg:w-1/4 fixed bottom-[0px] lg:bottom-[200px] lg:right-[30px]"
					>
						<x-icon
							class="w-5 text-cream inline cursor-pointer absolute top-2 right-2"
							@click="error.visible = false"
							:class="{ bounce: hover.menu }"
						></x-icon>
						<h5 class="smaller text-cream text-left">
							{{ error.title }}
						</h5>
						<p class="smaller text-cream text-left">
							{{ error.message }}
						</p>
					</div>
				</transition>
				<!--  Location (1) -->
				<transition>
					<div
						v-if="isScreenActive(overlayScreens.location)"
						class="w-full mx-auto mt-5 grow-[2] md:grow-[1]"
					>
						<restaurant-location-selector
							:show-location-dropdown-external-condition="
								mode !== modes.amend
							"
							:show-searching-message-external-condition="
								!isValidPromotion
							"
							:show-no-locations-external-condition="
								isValidPromotion
							"
							colour="light"
							:is-promotion="isValidPromotion"
							:isLocationRestrictedPromotion="
								isLocationRestrictedPromotion
							"
							:restaurants="filteredRestaurants"
							parent-classes="w-full lg:w-1/2 2xl:w-2/3 mx-auto px-5 max-w-[730px]"
							@location-selected="
								selectLocation(
									$event.id,
									true,
									$event.locationBlocks
								)
							"
						>
							<template #header>
								<h2>{{ widgetHeading }}</h2>
								<p v-if="!isValidPromotion && isPromotion">
									We’re sorry this offer code is not currently
									available. If you would like to continue to
									make your reservation you can find your
									restaurant below.
								</p>
								<div
									v-if="isValidPromotion"
									class="promotion mt-4 flex flex-wrap items-center"
								>
									<div class="w-full">
										<h3>
											{{
												promotion.book_a_table_page_title
											}}
										</h3>
										<h4>
											{{
												promotion.book_a_table_page_subtitle
											}}
										</h4>
										<p
											v-html="
												promotion.book_a_table_page_content
											"
										></p>
									</div>
									<div
										v-if="
											promotion.featuredImage.length > 0
										"
										class="w-full mt-4 bg-cover bg-top min-h-[288px] md:min-h-[400px] c-image-offset bottom-left bg-brand"
										v-bind:style="{
											backgroundImage:
												'url(' +
												promotion.featuredImage +
												')',
										}"
									></div>
								</div>
								<h6 class="mt-16">Select a location</h6>
							</template>

							<template #footer>
								<p class="smaller !mt-12" v-if="isPromotion">
									This promotion is only available to tables
									booked through this page.
								</p>
								<p class="smaller !mt-12" v-if="!isPromotion">
									Bookings are always recommended although we
									do keep a small number of tables available
									for walk-ins on the day. We look forward to
									hosting you soon!
								</p>
								<div
									v-if="isValidPromotion"
									class="promotion my-16"
								>
									<p
										class="smaller"
										v-html="
											promotion.book_a_table_page_footnote
										"
									></p>
								</div>
							</template>
						</restaurant-location-selector>
					</div>
				</transition>
				<!--  Guests, data and time (2)  -->
				<transition>
					<div
						v-if="isScreenActive(overlayScreens.guests_date_time)"
						class="w-full mt-5"
						:class="{
							'lg:mt-5': isEnquiry || bookingData.quandooSlot,
							'mb-72': bookingData.quandooSlot,
						}"
					>
						<div
							v-if="!isSingleRestaurantPromotion"
							class="flex justify-start items-center cursor-pointer absolute top-20 left-4 lg:top-28 lg:left-8"
							@click="goToScreen(overlayScreens.location)"
							@keydown.enter="goToScreen(overlayScreens.location)"
							tabindex="0"
						>
							<chevron-left-icon
								class="text-brand w-[26px] lg:w-[32px]"
							></chevron-left-icon>
							<h5 class="mt-0 ml-0.5 lg:ml-1.5">Back</h5>
						</div>
						<div class="w-full mx-auto px-5 mb-20 max-w-[550px]">
							<div
								class="bg-brand text-center p-5 lg:pb-8 w-full px-5 lg:mt-10 lg:relative lg:mx-auto lg:mb-20 z-40 top-10"
								v-if="isAmend"
							>
								<h3 class="text-cream">Current Booking</h3>
								<p class="smaller mb-3 text-cream">
									These are your current booking details, you
									can use the form below to amend your booking
								</p>
								<div class="mb-5">
									<p class="smaller text-cream">Location</p>
									<h4 class="text-cream mt-0">
										{{ bookingData.location.post_title }}
									</h4>
									<h6 class="text-cream mt-0">
										{{ bookingData.location.address }}
									</h6>
								</div>
								<div class="mb-5">
									<p class="smaller text-cream">Guests</p>
									<h4 class="text-cream mt-0">
										{{ humanGuests }}
									</h4>
								</div>
								<div class="mb-5">
									<p class="smaller text-cream">Date</p>
									<h4 class="text-cream mt-0.5">
										{{ humanDate }}
									</h4>
								</div>
								<div class="mb-6">
									<p class="smaller text-cream">Time</p>
									<h4 class="text-cream mt-0">
										{{ humanTime }}
									</h4>
								</div>
								<div class="mb-6">
									<h5 class="text-cream mt-0">
										Card payments
									</h5>
									<p class="smaller text-cream">
										We only accept card payments, apologies
										for any inconvenience this may cause
									</p>
								</div>
							</div>
							<h2 class="mb-10">{{ widgetHeading }}</h2>
							<div
								v-if="isValidPromotion"
								class="promotion my-4 mb-10 flex flex-wrap items-center"
							>
								<div class="w-full">
									<h3>
										{{ promotion.book_a_table_page_title }}
									</h3>
									<h4>
										{{
											promotion.book_a_table_page_subtitle
										}}
									</h4>
									<p
										v-html="
											promotion.book_a_table_page_content
										"
									></p>
								</div>
								<div
									v-if="promotion.featuredImage.length > 0"
									class="w-full mt-4 bg-cover bg-top min-h-[288px] md:min-h-[400px] c-image-offset bottom-left bg-brand"
									v-bind:style="{
										backgroundImage:
											'url(' +
											promotion.featuredImage +
											')',
									}"
								></div>
							</div>
							<div
								class="relative border border-brand h-[56px] px-2.5 py-1.5 mb-4 cursor-pointer"
								@click="toggleDropdowns('locationOverlay')"
								@keydown="toggleDropdowns('locationOverlay')"
								tabindex="0"
								ref="locationOverlayBox"
							>
								<p
									class="text-sm leading-4 leading- text-grey/90 mt-0 mb-0 text-left"
								>
									Location
								</p>
								<h4 class="m-0 text-left lg:leading-[30px]">
									{{ humanLocation }}
								</h4>
								<div
									class="w-[56px] h-full absolute absolute-center-y right-0 border-l border-brand bg-transparent hover:bg-brand text-brand hover:text-cream cursor-pointer"
								>
									<chevron-down-icon
										class="w-[33px] absolute absolute-center"
									></chevron-down-icon>
								</div>
								<ul
									v-if="
										overlayDropdowns.location.enabled &&
										overlayDropdowns.location.visible &&
										mode !== modes.amend
									"
									class="custom-dropdown-extra-width w-full border border-brand p-2.5 h-60 c-scrollbar overflow-y-scroll bg-cream absolute top-full left-0 z-20"
									ref="locationOverlayDropdown"
								>
									<li
										v-for="(
											restaurant, i
										) in filteredRestaurants"
										:key="i"
										class="cursor-pointer font-parisine font-thin text-sm text-brand py-1.5 hover:bg-brand hover:text-cream"
										@click="
											selectLocation(restaurant.ID, true)
										"
										@keydown="
											selectLocation(restaurant.ID, true)
										"
									>
										{{ restaurant.post_title }}
									</li>
								</ul>
							</div>
							<div
								class="relative border border-brand h-[56px] px-2.5 py-1.5 mb-4 cursor-pointer"
								@click="toggleDropdowns('guestsOverlay')"
								@keydown="toggleDropdowns('guestsOverlay')"
								ref="guestsOverlayBox"
							>
								<p
									class="text-sm leading-4 text-grey/90 mt-0 mb-0 text-left"
								>
									Guests
								</p>
								<h4 class="m-0 text-left lg:leading-[30px]">
									{{ humanGuests }}
								</h4>
								<div
									class="w-[56px] h-full absolute absolute-center-y right-0 border-l border-brand bg-transparent hover:bg-brand text-brand hover:text-cream cursor-pointer"
								>
									<chevron-down-icon
										class="w-[33px] absolute absolute-center"
									></chevron-down-icon>
								</div>
								<ul
									v-if="
										overlayDropdowns.guests.enabled &&
										overlayDropdowns.guests.visible
									"
									class="custom-dropdown-extra-width w-full border border-brand p-2.5 h-60 c-scrollbar overflow-y-scroll bg-cream absolute top-full left-0 z-20"
									ref="guestsOverlayDropdown"
								>
									<li
										v-for="(guest, i) in guests.guests"
										:key="i"
										class="cursor-pointer font-parisine font-thin text-sm text-brand py-1.5 hover:bg-brand hover:text-cream"
										@click="selectGuests(i, true)"
										@keydown="selectGuests(i, true)"
									>
										{{ guest.label }}
									</li>
								</ul>
							</div>
							<div v-if="mode === modes.enquiry">
								<p class="smaller text-center">
									For reservations over
									{{ this.bookingData.location.max_guests }},
									kindly submit an enquiry below and our
									reservations team will be in touch.
								</p>
								<p class="smaller text-center mb-4">
									Please note, we require 48 hours notice to
									process enquiries.
								</p>
							</div>
							<div
								class="relative border border-brand h-[56px] px-2.5 py-1.5 mb-4 cursor-pointer"
								ref="dateOverlayBox"
								@click="handleDateOverlayClick"
								@keydown="handleDateOverlayClick"
							>
								<p
									class="text-sm leading-4 text-grey/90 mt-0 mb-0 text-left"
								>
									Date
								</p>
								<h4 class="m-0 text-left lg:leading-[30px]">
									{{ humanDate }}
								</h4>
								<div
									class="w-[56px] h-full absolute absolute-center-y right-0 border-l border-brand bg-transparent hover:bg-brand text-brand hover:text-cream cursor-pointer"
								>
									<chevron-down-icon
										class="w-[33px] absolute absolute-center"
										@click="toggleDropdowns('dateOverlay')"
									></chevron-down-icon>
								</div>
								<div
									v-if="
										overlayDropdowns.date.enabled &&
										overlayDropdowns.date.visible
									"
									class="custom-dropdown-extra-width w-full border border-brand p-2.5 h-72 overflow-hidden bg-cream absolute top-full left-0 bg-cream z-20"
									ref="dateOverlayDropdown"
								>
									<DatePicker
										class="date-picker-dropdown"
										v-model="bookingData.date"
										:min-date="dates.min"
										:max-date="dates.max"
										:disabled-dates="disabledCalendarDays"
										ref="overlayPicker"
										@dayclick="selectDate(true, $event)"
										is-expanded
									></DatePicker>
								</div>
							</div>
							<div
								class="relative border border-brand h-[56px] px-2.5 py-1.5 cursor-pointer"
								@click="toggleDropdowns('timeOverlay')"
								@keydown="toggleDropdowns('timeOverlay')"
								ref="timeOverlayBox"
							>
								<p
									class="text-sm leading-4 text-grey/90 mt-0 mb-0 text-left"
								>
									Time
								</p>
								<h4 class="m-0 text-left lg:leading-[30px]">
									{{ humanTime }}
								</h4>
								<div
									class="w-[56px] h-full absolute absolute-center-y right-0 border-l border-brand bg-transparent hover:bg-brand text-brand hover:text-cream cursor-pointer"
								>
									<chevron-down-icon
										class="w-[33px] absolute absolute-center"
									></chevron-down-icon>
								</div>
								<ul
									v-if="
										overlayDropdowns.time.enabled &&
										overlayDropdowns.time.visible
									"
									class="custom-dropdown-extra-width w-full border border-brand p-2.5 h-60 c-scrollbar overflow-y-scroll bg-cream absolute top-full left-0 z-20"
									ref="timeOverlayDropdown"
								>
									<li
										v-for="(time, i) in times.times"
										:key="i"
										class="cursor-pointer font-parisine font-thin text-sm text-brand py-1.5 hover:bg-brand hover:text-cream"
										@click="selectTime(i, true)"
										@keydown="selectTime(i, true)"
									>
										{{ time }}
									</li>
								</ul>
							</div>
							<div v-if="mode === modes.enquiry">
								<div
									class="relative border border-brand h-[56px] px-2.5 py-1.5 mt-4"
									ref="durationOverlayBox"
								>
									<p
										class="text-sm leading-4 text-grey/90 mt-0 mb-0 text-left"
									>
										Duration
									</p>
									<h4 class="m-0 text-left lg:leading-[30px]">
										{{ humanDuration }}
									</h4>
									<div
										class="w-[56px] h-full absolute absolute-center-y right-0 border-l border-brand bg-transparent hover:bg-brand text-brand hover:text-cream cursor-pointer"
										@click="
											toggleDropdowns('durationOverlay')
										"
										@keydown="
											toggleDropdowns('durationOverlay')
										"
									>
										<chevron-down-icon
											class="w-[33px] absolute absolute-center"
										></chevron-down-icon>
									</div>
									<ul
										v-if="
											overlayDropdowns.duration.enabled &&
											overlayDropdowns.duration.visible
										"
										class="custom-dropdown-extra-width w-full border border-brand p-2.5 h-60 c-scrollbar overflow-y-scroll bg-cream absolute top-full left-0 z-20"
										ref="durationOverlayDropdown"
									>
										<li
											v-for="(
												duration, i
											) in times.durations"
											:key="i"
											class="cursor-pointer font-parisine font-thin text-sm text-brand py-1.5 hover:bg-brand hover:text-cream"
											@click="
												selectDuration(duration, true)
											"
											@keydown="
												selectDuration(duration, true)
											"
										>
											{{ duration }}
											{{
												duration === 1
													? 'hour'
													: 'hours'
											}}
										</li>
									</ul>
								</div>
							</div>

							<!-- Booking Widget messages -->
							<transition>
								<div>
									<booking-message
										v-for="message in messages"
										:key="message.title"
										:message="message"
										:bookingData="bookingData"
									/>
								</div>
							</transition>

							<!--  Quandoo times container  -->
							<transition name="slide">
								<div v-if="quandooTimes.length > 0">
									<h6
										class="text-center"
										v-if="!requestedTimeObtained"
									>
										Sorry, we don’t have a table available
										for your selected time. Please choose
										from one of the available times below.
									</h6>
									<div
										class="w-full py-4 grid gap-1 grid-cols-4"
										v-if="!requestedTimeObtained"
									>
										<h4
											v-for="(time, i) in quandooTimes"
											:key="i"
											class="cursor-pointer text-center py-2 mt-0 quandoo-time-slot"
											title="Choose this slot"
											:class="{
												'border border-brand':
													time ===
													bookingData.quandooSlot,
											}"
											@click="
												selectQuandooTimeSlot(
													time,
													time.slotTime.toFormat(
														'HH:mm'
													)
												)
											"
											@keydown="
												selectQuandooTimeSlot(
													time,
													time.slotTime.toFormat(
														'HH:mm'
													)
												)
											"
										>
											{{
												time.slotTime.toFormat('HH:mm')
											}}
										</h4>
									</div>
									<p class="mt-6">
										We also keep a small number of tables
										available for walk-ins on the day, so
										pop in and we will do our best to seat
										you.
									</p>
								</div>
							</transition>
							<!--  Close restaurants container  -->
							<transition>
								<div
									v-if="
										closeRestaurantsDisplayed &&
										!isEnquiry &&
										closeRestaurantsExist
									"
								>
									<h5 class="text-center">
										Select a nearby location with
										availability near this time
									</h5>
									<div class="w-full py-4">
										<p
											v-for="(
												restaurant, i
											) in closeRestaurants"
											:key="i"
											class="cursor-pointer text-center py-2 mt-0 smaller"
											@click="
												searchSlotsForCloseRestaurant(
													restaurant.restaurant.id
												)
											"
											@keydown="
												searchSlotsForCloseRestaurant(
													restaurant.restaurant.id
												)
											"
										>
											<span class="bold">{{
												restaurant.restaurant.title
											}}</span>
											({{
												restaurant.restaurant.distance
											}}
											miles away)
											<span class="underline"
												>Search</span
											>
										</p>
									</div>
								</div>
							</transition>
							<transition>
								<div
									v-if="quandooTimes.length < 1"
									class="my-4"
								>
									<h5 class="text-center">Outside tables</h5>
									<p class="smaller text-center">
										To book an outside table, please add a
										note in the special requests box on the
										next screen and we will do our best to
										accommodate your request.
									</p>
								</div>
							</transition>
							<button
								v-if="mode === modes.enquiry"
								type="button"
								class="standard-button py-5 bg-brand text-cream w-full mt-3"
								@click="
									goToScreen(
										this.overlayScreens.personal_details
									)
								"
							>
								Continue
							</button>
							<button
								v-if="
									!bookingData.quandooSlot &&
									mode !== modes.enquiry &&
									!slotsShown
								"
								type="button"
								class="standard-button py-5 bg-brand text-cream w-full mt-3"
								@click="getAvailableTimeSlotsForRestaurant"
							>
								Find a table
							</button>
							<button
								v-if="
									bookingData.quandooSlot &&
									mode !== modes.enquiry
								"
								type="button"
								class="standard-button py-5 bg-brand text-cream w-full mt-3"
								@click="continueAfterSlotChosen()"
							>
								Continue
							</button>
						</div>
						<div
							v-if="
								isValidPromotion && isSingleRestaurantPromotion
							"
							class="promotion mt-16 mx-auto w-4/12 mb-10"
						>
							<p
								class="smaller"
								v-html="promotion.book_a_table_page_footnote"
							></p>
						</div>
					</div>
				</transition>
				<!-- Experiences step (3) -->
				<transition>
					<div
						v-if="isScreenActive(overlayScreens.experiences)"
						class="w-full mx-auto mt-5"
					>
						<div
							class="flex justify-start items-center cursor-pointer absolute top-20 left-4 lg:top-28 lg:left-8"
							@click="goToScreen(overlayScreens.guests_date_time)"
							@keydown.enter="
								goToScreen(overlayScreens.guests_date_time)
							"
							tabindex="0"
						>
							<chevron-left-icon
								class="text-brand w-[26px] lg:w-[32px]"
							></chevron-left-icon>
							<h5 class="mt-0 ml-0.5 lg:ml-1.5">Back</h5>
						</div>
						<h2>Booking Options</h2>
						<div class="container w-11/12 lg:w-9/12 mx-auto mt-8">
							<!--Standard booking-->
							<div
								class="flex items-center justify-between w-full mb-5"
								v-if="showStandardBooking"
							>
								<div
									class="flex flex-col lg:flex-row w-full items-start lg:items-center bg-transparent border border-brand"
								>
									<div class="w-full lg:w-3/3 bg-transparent">
										<div>
											<h3
												class="mb-3 tracking-tight text-navy px-4 text-2xl"
											>
												Standard booking
											</h3>
											<div
												class="mb-3 text-gray-500 px-4"
											>
												{{
													this.bookingData.location
														.post_title
												}}
												menu
											</div>
											<span
												@click="selectExperience(null)"
												@keydown.enter="
													selectExperience(null)
												"
												tabindex="0"
												class="standard-button bg-mustard text-brand border border-mustard mt-5 self-center mb-5"
											>
												Select
											</span>
										</div>
									</div>
								</div>
							</div>

							<div
								v-for="experience in visibleExperiences"
								:key="experience.id"
								class="flex items-center justify-between w-full mb-5"
							>
								<div
									class="flex flex-col lg:flex-row w-full items-start lg:items-center bg-transparent border border-brand"
								>
									<div class="w-full lg:w-3/3 bg-transparent">
										<div>
											<h3
												class="mb-3 tracking-tight text-navy px-4 text-2xl"
											>
												{{ experience.title }}
											</h3>
											<h6
												v-if="experience.price"
												class="mb-5"
											>
												Price:
												<span>{{
													experience.price
												}}</span>
											</h6>
											<div
												class="mb-3 text-gray-500 px-4 description-fit"
											>
												{{ experience.description }}
											</div>
											<button
												type="button"
												href="#"
												@click="
													selectExperience(experience)
												"
												@keydown.enter="
													selectExperience(experience)
												"
												tabindex="0"
												class="standard-button bg-mustard text-brand border border-mustard mt-5 self-center mb-5"
											>
												Select
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
				<!--  Personal details (4)  -->
				<transition>
					<form
						v-if="isScreenActive(overlayScreens.personal_details)"
						ref="bookingForm"
						@submit="submitReservation($event)"
						@keydown.enter="$event.preventDefault()"
					>
						<div
							class="w-full lg:w-2/4 xl:w-1/4 mt-5 px-5 mx-auto mb-20"
						>
							<div
								class="flex justify-start items-center cursor-pointer absolute top-20 left-4 lg:top-28 lg:left-8"
								@click="goBackFromPersonalDetails"
								@keydown.enter="goBackFromPersonalDetails"
								tabindex="0"
							>
								<chevron-left-icon
									class="text-brand w-[26px] lg:w-[32px]"
								></chevron-left-icon>
								<h5 class="mt-0 ml-0.5 lg:ml-1.5">Back</h5>
							</div>
							<h2>{{ widgetHeading }}</h2>
							<p
								class="text-center"
								v-if="mode !== modes.enquiry"
							>
								We have a table available for
								<strong>{{
									this.bookingData.guests.value
								}}</strong>
								guests, at Côte
								<strong>{{
									this.bookingData.location.post_title
								}}</strong>
								on
								<strong>{{
									this.bookingData.date.toLocaleDateString()
								}}</strong>
								at <strong>{{ this.bookingData.time }}</strong
								>.
							</p>
							<p
								class="text-center"
								v-if="mode !== modes.enquiry"
							>
								Complete the form below to finish your booking
							</p>
							<p v-if="stripeCardRequiredForSelectedSlot">
								<span
									v-for="(message, index) in policyMessages"
									:key="index"
									class="mb-6 description-fit"
								>
									{{ message }}
								</span>
							</p>
							<div class="mt-10">
								<input
									type="text"
									class="sr-only"
									value="chrome-cc-autofill-bug-fix"
								/>
								<label for="firstName" class="block text-left"
									>First name *</label
								>
								<input
									id="firstName"
									type="text"
									v-model="
										bookingData.personalDetails.firstName
									"
									placeholder="Name"
									name="firstName"
									autocomplete="given-name"
									class="w-full bg-cream border border-brand h-[56px] px-3 py-1.5 mb-4 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
									required
									@input="trackReservationProgress(500)"
								/>
								<label for="surname" class="block text-left"
									>Surname *</label
								>
								<input
									type="text"
									v-model="
										bookingData.personalDetails.lastName
									"
									placeholder="Surname"
									name="surname"
									autocomplete="family-name"
									class="w-full bg-cream border border-brand h-[56px] px-3 py-1.5 mb-4 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
									required
								/>
								<label for="email" class="block text-left"
									>Email *</label
								>
								<input
									type="email"
									v-model="bookingData.personalDetails.email"
									placeholder="Email"
									name="email"
									@change="validateEmail()"
									@keyup="validateEmail()"
									@keydown="validateEmail()"
									@blur="validateEmail()"
									class="w-full bg-cream border border-brand h-[56px] px-3 py-1.5 mb-4 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
									required
								/>
								<p
									class="mt-0 text-left mb-6 mt-0"
									v-if="emailValidationError"
								>
									{{ emailValidationError }}
								</p>
								<label for="telephone" class="block text-left"
									>Telephone *</label
								>
								<vue-tel-input
									v-model="bookingData.personalDetails.phone"
									:valid-characters-only="true"
									:input-options="{
										required: true,
										showDialCode: true,
										autocomplete: 'tel',
									}"
									:default-country="'GB'"
									:autoformat="true"
									:mode="'international'"
									:name="'telephone'"
									class="w-full bg-cream border rounded-none border-brand h-[56px] mb-4 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
									@country-changed="setCountry($event)"
									@change="validatePhoneNumber(true)"
									@keyup="validatePhoneNumber(true)"
									@blur="validatePhoneNumber(true)"
									@input="validatePhoneNumber(true)"
								>
								</vue-tel-input>
								<p
									class="mt-0 text-left mb-6 mt-0"
									v-if="phoneValidationError"
								>
									{{ phoneValidationError }}
								</p>

								<div
									class="relative border border-brand h-14 px-3 py-1.5 mb-4"
									ref="specialOccasionOverlayBox"
									@click="toggleDropdowns('specialOccasion')"
									@keydown="
										toggleDropdowns('specialOccasion')
									"
								>
									<p
										v-if="
											bookingData.personalDetails
												.specialOccasion
										"
										class="text-left text-base text-brand tracking-normal mt-[10px]"
									>
										{{
											bookingData.personalDetails
												.specialOccasion
										}}
									</p>
									<p
										v-else
										class="text-left font-parisine font-normal text-brand/70 text-sm tracking-normal mt-3"
									>
										Select an occasion (optional)
									</p>
									<div
										class="w-14 h-full absolute absolute-center-y right-0 border-l border-brand bg-transparent hover:bg-brand text-brand hover:text-cream cursor-pointer"
									>
										<chevron-down-icon
											class="w-[33px] absolute absolute-center"
										></chevron-down-icon>
									</div>
									<ul
										v-if="
											overlayDropdowns.specialOccasion
												.enabled &&
											overlayDropdowns.specialOccasion
												.visible
										"
										class="custom-dropdown-extra-width w-full border border-brand p-2.5 c-scrollbar overflow-y-scroll bg-cream absolute top-full left-0 z-20"
										ref="specialOccasionOverlayDropdown"
									>
										<li
											class="cursor-pointer font-parisine font-thin text-sm text-brand py-1.5 hover:bg-brand hover:text-cream"
											@click="selectSpecialOccasion('')"
											@keydown="selectSpecialOccasion('')"
										>
											Select an occasion (optional)
										</li>
										<li
											v-for="(
												specialOccasion, i
											) in specialOccasions"
											:key="i"
											class="cursor-pointer font-parisine font-thin text-sm text-brand py-1.5 hover:bg-brand hover:text-cream"
											@click="
												selectSpecialOccasion(
													specialOccasion
												)
											"
											@keydown="
												selectSpecialOccasion(
													specialOccasion
												)
											"
										>
											{{ specialOccasion }}
										</li>
									</ul>
								</div>

								<input
									type="text"
									v-model="
										bookingData.personalDetails
											.specialRequests
									"
									placeholder="Special requests (optional)"
									class="w-full bg-cream border border-brand h-[56px] px-3 py-1.5 mb-4 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
								/>
								<InputBirthday
									:value="
										bookingData.personalDetails.birthDay
									"
									:required="false"
									@update-birthday="
										(date) =>
											(this.bookingData.personalDetails.birthDay =
												date)
									"
								/>
								<!-- newsletter -->
								<fieldset class="relative">
									<label for="consent">
										<div class="absolute t-0 l-0 w-[30px]">
											<input
												v-model="
													bookingData.personalDetails
														.consent
												"
												type="checkbox"
												class="hidden"
												id="consent"
												name="consent"
												:value="consentText"
											/>
											<img
												loading="lazy"
												alt="Image"
												:class="
													bookingData.personalDetails
														.consent
														? 'hidden'
														: ''
												"
												src="@/assets/checkbox-unchecked.svg"
												class="cursor-pointer inline w-5 mr-2"
											/>
											<img
												loading="lazy"
												alt="Image"
												:class="
													bookingData.personalDetails
														.consent
														? ''
														: 'hidden'
												"
												src="@/assets/checkbox-checked.svg"
												class="cursor-pointer inline w-5 mr-2"
											/>
										</div>
										<div class="pl-[30px]">
											<span
												class="smaller"
												v-html="getGdprText"
											></span>
										</div>
									</label>
								</fieldset>

								<card-form
									v-if="
										personalDetailsCompleted &&
										stripeCardRequiredForSelectedSlot
									"
									:resultMessage="this.resultMessage"
									:classes="'my-10'"
									:bookingData="this.bookingData"
									@on-payment="handleCardPayment"
									@on-reservation-error="
										handleReservationError
									"
									ref="cardForm"
								></card-form>

								<div class="mb-8 mt-3">
									<p class="smaller uppercase text-left">
										Terms and conditions
									</p>
									<p class="smaller text-left !mt-0.5">
										Please note, reservations for tables of
										three people or less last 1 hour 30
										minutes, and tables of four or more last
										2 hours. Our restaurant will hold your
										table reservation for 15 minutes only.
									</p>
									<p class="smaller text-left">
										By clicking on the button to book or
										enquire below, you confirm you are
										agreeing to our Terms & Conditions and
										the usage of your data according to our
										Privacy Policy.
									</p>
								</div>
								<input
									v-if="!stripeCardRequiredForSelectedSlot"
									type="submit"
									class="standard-button bg-mustard w-full mt-0 py-5"
									:class="{
										'opacity-50 pointer-events-none cursor-not-allowed':
											!personalDetailsCompleted,
										'xl:hidden': !isEnquiry,
									}"
									:value="
										!personalDetailsCompleted
											? 'Form Incomplete'
											: isEnquiry
											? 'Enquire'
											: 'Confirm Booking'
									"
								/>
							</div>
						</div>
						<div
							v-if="!isEnquiry"
							class="hidden xl:block fixed bg-brand px-10 pb-5 pt-0.5 bottom-0 left-0 w-[100vw]"
						>
							<h3 class="text-center text-cream mb-2.5">
								Your booking
							</h3>
							<div class="flex justify-between items-center">
								<div class="border border-cream w-1/5 mr-2 p-2">
									<p
										class="smaller text-cream leading-snug !mt-0"
									>
										Location
									</p>
									<h5 class="text-cream leading-snug mt-0">
										{{ humanLocation }}
									</h5>
								</div>
								<div class="border border-cream w-1/5 mx-2 p-2">
									<p
										class="smaller text-cream leading-snug !mt-0"
									>
										Number of Guests
									</p>
									<h5 class="text-cream leading-snug mt-0">
										{{ humanGuests }}
									</h5>
								</div>
								<div class="border border-cream w-1/5 mx-2 p-2">
									<p
										class="smaller text-cream leading-snug !mt-0"
									>
										Date
									</p>
									<h5 class="text-cream leading-snug mt-0">
										{{ humanDate }}
									</h5>
								</div>
								<div class="border border-cream w-1/5 mx-2 p-2">
									<p
										class="smaller text-cream leading-snug !mt-0"
									>
										Time
									</p>
									<h5 class="text-cream leading-snug mt-0">
										{{ humanSlot }}
									</h5>
								</div>
								<input
									v-if="!stripeCardRequiredForSelectedSlot"
									type="submit"
									class="standard-button bg-mustard w-1/5 ml-2 mt-0 py-5"
									:class="{
										'opacity-20 pointer-events-none cursor-not-allowed':
											!personalDetailsCompleted,
									}"
									:value="
										!personalDetailsCompleted
											? 'Form Incomplete'
											: isEnquiry
											? 'Enquire'
											: 'Confirm Booking'
									"
								/>
							</div>
						</div>
					</form>
				</transition>

				<!--  Booking confirmation (5)  -->
				<transition>
					<div
						v-if="
							isScreenActive(overlayScreens.booking_confirmation)
						"
						class="w-full px-5 lg:px-0 pt-6"
					>
						<div class="hidden lg:block"></div>
						<div
							class="bg-brand text-center p-5 lg:pb-8 w-full lg:w-1/2 px-5 lg:mt-10 lg:relative lg:mx-auto lg:mb-20 z-40 top-10"
						>
							<h3
								class="text-cream"
								v-if="this.mode === this.modes.reconfirm"
							>
								Your booking is re-confirmed
							</h3>
							<h3
								class="text-cream"
								v-if="this.mode !== this.modes.reconfirm"
							>
								Your booking is confirmed
							</h3>
							<p
								class="smaller mb-3 text-cream"
								v-if="this.mode !== this.modes.reconfirm"
							>
								Thank you for confirming your booking, we've
								sent you an email with your reservation details.
								Should you not receive this soon, please check
								your junk/spam folder or promotions folder. We
								look forward to welcoming you.
							</p>
							<div class="mb-5">
								<p class="smaller text-cream">Location</p>
								<h4 class="text-cream mt-0">
									{{ bookingData.location.post_title }}
								</h4>
								<h6 class="text-cream mt-0">
									{{ bookingData.location.address }}
								</h6>
							</div>
							<div class="mb-5">
								<p class="smaller text-cream">Guests</p>
								<h4 class="text-cream mt-0">
									{{ humanGuests }}
								</h4>
							</div>
							<div class="mb-5">
								<p class="smaller text-cream">Date</p>
								<h4 class="text-cream mt-0.5">
									{{ humanDate }}
								</h4>
							</div>
							<div class="mb-6">
								<p class="smaller text-cream">Time</p>
								<h4 class="text-cream mt-0">{{ humanTime }}</h4>
							</div>
							<div class="mb-6">
								<h5 class="text-cream mt-0">Card payments</h5>
								<p class="smaller text-cream">
									We only accept card payments, apologies for
									any inconvenience this may cause
								</p>
							</div>
							<button
								type="button"
								class="standard-button py-5 bg-mustard text-brand w-10/12 mt-4"
								@click="
									this.$router.push(
										this.getBrasserieMenusLink(
											bookingData.location.ID
										)
									)
								"
							>
								See the menu
							</button>
							<button
								type="button"
								class="standard-button py-5 bg-mustard text-brand w-10/12 mt-6"
								@click="
									this.$router.push(
										this.getBrasserieLink(
											bookingData.location.ID
										)
									)
								"
							>
								See the restaurant
							</button>
						</div>
					</div>
				</transition>
				<!--  Amend booking request (6)  -->
				<transition>
					<div
						v-if="
							isScreenActive(overlayScreens.amend_booking_request)
						"
						class="w-full lg:w-1/4 pt-16 px-5 mx-auto lg:mt-10"
					>
						<div
							class="flex justify-start items-center cursor-pointer absolute top-20 left-4 lg:top-28 lg:left-8"
							@click="goToScreen(overlayScreens.location)"
							@keydown="goToScreen(overlayScreens.location)"
						>
							<chevron-left-icon
								class="text-brand w-[26px] lg:w-[32px]"
							></chevron-left-icon>
							<h5 class="mt-0 ml-0.5 lg:ml-1.5">Back</h5>
						</div>
						<h2 class="mb-6">Amend booking</h2>
						<p>
							You can amend your booking with the link provided in
							your booking confirmation email.
						</p>
						<p>
							Below you can also request a link to your email
							address, to amend your booking
						</p>
						<form
							ref="requestAmendLink"
							@submit="requestAmendBookingLink($event)"
							class="mt-8"
						>
							<label for="amendEmailInput" class="sr-only"
								>Email</label
							>
							<input
								id="amendEmailInput"
								type="email"
								v-model="amendEmail"
								placeholder="Email"
								class="w-full bg-cream border border-brand h-[50px] px-3 py-1.5 mb-4 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
								required
							/>
							<input
								type="submit"
								value="request link"
								class="standard-button py-4 text-brand w-full mt-6"
								:class="getButtonStyle('navy')"
							/>
						</form>
					</div>
				</transition>
				<!--  Amend booking confirm (7)  -->
				<transition>
					<div
						v-if="
							isScreenActive(overlayScreens.amend_booking_confirm)
						"
						class="w-full lg:w-1/4 pt-16 px-5 mx-auto lg:mt-10"
					>
						<h2 class="mb-6">Link sent</h2>
						<p>
							If there is an active booking made by the email
							{{ amendEmail }}, you will receive an email shortly.
						</p>
						<p>
							Should you not receive this soon, please check your
							junk/spam or promotion folders.
						</p>
					</div>
				</transition>
				<!--  Cancel booking request (8)  -->
				<transition>
					<div
						v-if="
							isScreenActive(
								overlayScreens.cancel_booking_request
							)
						"
						class="w-full lg:w-1/4 pt-16 px-5 mx-auto lg:mt-10"
					>
						<div
							class="flex justify-start items-center cursor-pointer absolute top-20 left-4 lg:top-28 lg:left-8"
							@click="goToScreen(overlayScreens.location)"
							@keydown="goToScreen(overlayScreens.location)"
						>
							<chevron-left-icon
								class="text-brand w-[26px] lg:w-[32px]"
							></chevron-left-icon>
							<h5 class="mt-0 ml-0.5 lg:ml-1.5">Back</h5>
						</div>
						<h2 class="mb-6">Cancel booking</h2>
						<p>
							You can cancel your booking with the link provided
							in your booking confirmation email.
						</p>
						<p>
							Below you can also request a link to your email
							address, to cancel your booking
						</p>
						<form
							ref="requestAmendLink"
							@submit="requestCancelBookingLink($event)"
							class="mt-8"
						>
							<label for="cancelEmailInput" class="sr-only"
								>Email</label
							>
							<input
								id="cancelEmailInput"
								type="email"
								v-model="cancelEmail"
								placeholder="Email"
								class="w-full bg-cream border border-brand h-[50px] px-3 py-1.5 mb-4 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
								required
							/>
							<input
								type="submit"
								value="request link"
								class="standard-button py-4 text-brand w-full mt-6"
								:class="getButtonStyle('navy')"
							/>
						</form>
					</div>
				</transition>
				<!--  Cancel booking request confirm (9)  -->
				<transition>
					<div
						v-if="
							isScreenActive(
								overlayScreens.cancel_booking_request_confirm
							)
						"
						class="w-full lg:w-1/4 pt-16 px-5 mx-auto lg:mt-10"
					>
						<h2 class="mb-6">Link sent</h2>
						<p>
							If there is an active booking made by the email
							{{ cancelEmail }}, you will receive an email
							shortly.
						</p>
						<p>
							Should you not receive this soon, please check your
							junk/spam or promotion folders.
						</p>
					</div>
				</transition>
				<!--  Cancel booking (10)  -->
				<transition>
					<div
						v-if="isScreenActive(overlayScreens.cancel_booking)"
						class="w-full lg:w-6/12 pt-16 px-5 mx-auto lg:mt-10 flex flex-col items-center"
					>
						<h2 class="mb-6">Confirm Cancellation</h2>
						<p>
							Before you cancel, we may be able to move your
							booking to a new date that works for you. Just call
							the restaurant direct for availability.
						</p>
						<div
							class="bg-brand text-center p-5 mt-6 w-full lg:w-6/12"
						>
							<div class="mb-3">
								<p class="text-cream font-thin text-sm">
									Location
								</p>
								<h5 class="text-cream mt-0.5">
									{{ bookingData.location.post_title }}
								</h5>
								<p
									class="text-cream text-sm font-copperplate mt-0.5"
								>
									{{ bookingData.location.address }}
								</p>
							</div>
							<div class="mb-3">
								<p class="text-cream font-thin text-sm">
									Guests
								</p>
								<h5 class="text-cream mt-0.5">
									{{ humanGuests }}
								</h5>
							</div>
							<div class="mb-3">
								<p class="text-cream font-thin text-sm">Date</p>
								<h5 class="text-cream mt-0.5">
									{{ humanDate }}
								</h5>
							</div>
							<div class="mb-3">
								<p class="text-cream font-thin text-sm">Time</p>
								<h5 class="text-cream mt-0.5">
									{{ humanTime }}
								</h5>
							</div>
							<button
								type="button"
								class="standard-button py-5 bg-mustard text-brand w-full mt-6"
								@click="cancelReservation"
							>
								confirm cancellation
							</button>
						</div>
					</div>
				</transition>
				<!--  Cancel booking confirm (11)  -->
				<transition>
					<div
						v-if="
							isScreenActive(
								overlayScreens.cancel_booking_confirm
							)
						"
						class="w-full pt-10 px-5 mx-auto lg:mt-10"
					>
						<div class="hidden lg:block">
							<mood-board-block
								:fields="moodBoardConfig"
							></mood-board-block>
						</div>
						<div
							class="bg-brand text-center p-5 lg:px-8 lg:w-1/3 2xl:w-1/4 lg:fixed lg:absolute-center"
						>
							<h2 class="text-cream mb-6">
								Your booking is cancelled
							</h2>
							<p class="text-cream">
								We are sorry that you had to cancel. You will
								shortly receive an email confirming your
								cancellation.
							</p>
							<p class="text-cream">
								If you would like to re-book another table
								please click the button below
							</p>
							<button
								type="button"
								class="standard-button py-5 bg-mustard text-brand w-full mt-6"
								@click="
									goToScreen(this.overlayScreens.location)
								"
							>
								Book another table
							</button>
						</div>
					</div>
				</transition>
				<!--  Enquiry confirmation (12)  -->
				<transition>
					<div
						v-if="
							isScreenActive(overlayScreens.enquiry_confirmation)
						"
						class="w-full lg:w-2/4 pt-16 px-5 mx-auto lg:mt-10 max-w-[700px]"
					>
						<div class="bg-brand text-center p-5">
							<h2 class="text-cream mb-6">Enquiry Sent</h2>
							<p class="text-cream">
								Thank you for your enquiry, a member of our
								Reservations Team will be in touch within 48
								hours.
							</p>
							<div class="m-6">
								<h5 class="text-cream mt-0">Card payments</h5>
								<p class="smaller text-cream">
									We only accept card payments, apologies for
									any inconvenience this may cause
								</p>
							</div>
						</div>
					</div>
				</transition>
				<!--  Manage Bookings (13)  -->
				<transition>
					<div
						v-if="isScreenActive(overlayScreens.manage_bookings)"
						class="w-full lg:w-1/2 pt-16 px-5 mx-auto lg:mt-10"
					>
						<div v-if="showManageBookingsForm">
							<form
								class="text-center p-5"
								@submit.prevent="
									sendManageBookingsEmail($event)
								"
								@keydown.enter="$event.preventDefault()"
								v-if="!manageBookingRequestSubmitted"
							>
								<h2 class="text-brand mb-6">
									Please enter your email
								</h2>
								<p class="text-brand">
									And we'll send you an email with links to
									manage your existing bookings
								</p>
								<label
									for="personalDetailsEmailInput"
									class="sr-only"
									>Email</label
								>
								<input
									id="personalDetailsEmailInput"
									type="email"
									v-model="bookingData.personalDetails.email"
									placeholder="Email"
									name="email"
									@change="validateEmail()"
									@keyup="validateEmail()"
									@keydown="validateEmail()"
									@blur="validateEmail()"
									class="w-full bg-cream border border-brand h-[56px] px-3 py-1.5 my-4 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
									required
								/>
								<p
									class="mt-0 text-left mb-6 mt-0 text-brand"
									v-if="emailValidationError"
								>
									{{ emailValidationError }}
								</p>
								<input
									type="submit"
									class="standard-button bg-mustard w-full mt-0 py-5"
									value="'Submit'"
								/>
							</form>
							<div v-if="manageBookingRequestSubmitted">
								<h2 class="text-brand mb-6">Thanks</h2>
								<p class="text-brand">
									If we can find you on our system, we'll send
									you an email with your existing bookings
								</p>
								<p class="text-brand">
									Please check your junk folder!
								</p>
							</div>
						</div>
						<div v-else>
							<h2
								v-if="existingBookingEvents.length > 0"
								class="mb-10"
							>
								Your Upcoming Bookings
							</h2>
							<h2 v-else class="mb-10">No Upcoming Bookings</h2>
							<a
								class="standard-button w-full mt-0 py-4 mr-4"
								:class="getButtonStyle('navy')"
								:href="'/book-a-table'"
								@click.prevent="
									goToScreen(overlayScreens.location)
								"
								>Make a booking</a
							>
							<div
								v-for="event in existingBookingEvents"
								:key="event.timestamp"
								class="p-1 bg-brand mb-4"
							>
								<h3 class="text-white">
									{{ event.properties.actual_date }}
								</h3>
								<h4 class="text-white">
									{{ event.properties.actual_restaurant }} at
									{{ event.properties.actual_start }}
								</h4>
								<p class="text-white">
									{{ event.properties.restaurant_address1 }}
									{{ event.properties.restaurant_postcode }}
								</p>
								<p class="text-white">
									{{ event.properties.restaurant_email }}
									{{ event.properties.restaurant_phone }}
								</p>
								<p class="text-white">
									Special Requests:
									{{
										event.properties.special_requests
											? event.properties.special_requests
											: 'none'
									}}
								</p>
								<div class="flex my-4 px-4">
									<a
										class="standard-button w-full mt-0 py-4 mr-4"
										:class="getButtonStyle('yellow')"
										:href="
											'/book-a-table/cancel?reservation_id=' +
											event.properties.reservation_id
										"
										>Cancel This Reservation</a
									>
									<a
										class="standard-button w-full mt-0 py-4"
										:class="getButtonStyle('yellow')"
										:href="
											'/book-a-table?reservation_id=' +
											event.properties.reservation_id
										"
										>Amend This Reservation</a
									>
								</div>
							</div>
						</div>
					</div>
				</transition>

				<!--  existing_booking_card_confirmation (14)  -->
				<transition>
					<div
						v-if="
							isScreenActive(
								overlayScreens.existing_booking_card_confirmation
							)
						"
						class="w-full lg:w-1/2 pt-16 px-5 mx-auto lg:mt-10"
					>
						<div
							class="bg-brand text-center p-5 lg:pb-8 w-full px-5 lg:mt-10 lg:relative lg:mx-auto lg:mb-20 z-40 top-10"
							v-if="isReservationCancelled"
						>
							<h3 class="text-cream">
								This Reservation Was Cancelled
							</h3>
						</div>
						<div
							class="bg-brand text-center p-5 lg:pb-8 w-full px-5 lg:mt-10 lg:relative lg:mx-auto lg:mb-20 z-40 top-10"
							v-if="!isReservationCancelled"
						>
							<h3 class="text-cream">
								{{
									stripeDetailsAlreadyExistForBooking
										? 'YOUR BOOKING IS ALREADY CONFIRMED'
										: 'CONFIRM YOUR RESERVATION'
								}}
							</h3>
							<p
								class="smaller mb-3 text-cream"
								v-if="!stripeDetailsAlreadyExistForBooking"
							>
								Thanks for your reserving your festive party for
								{{ humanGuests }} at
								{{ bookingData.location.post_title }} on
								{{ humanDate }} at {{ humanTime }}. In order to
								secure your booking we just require a £10 per
								person holding deposit.
							</p>
							<p class="smaller mb-3 text-cream">
								Your card will not be debited unless you do not
								arrive for your booking without notifying us 24
								hours in advance.
								{{
									stripeDetailsAlreadyExistForBooking
										? ''
										: 'Please enter your card details below.'
								}}
							</p>
							<div class="mb-5">
								<p class="smaller text-cream">Location</p>
								<h4 class="text-cream mt-0">
									{{ bookingData.location.post_title }}
								</h4>
								<h6 class="text-cream mt-0">
									{{ bookingData.location.address }}
								</h6>
							</div>
							<div class="mb-5">
								<p class="smaller text-cream">Guests</p>
								<h4 class="text-cream mt-0">
									{{ humanGuests }}
								</h4>
							</div>
							<div class="mb-5">
								<p class="smaller text-cream">Date</p>
								<h4 class="text-cream mt-0.5">
									{{ humanDate }}
								</h4>
							</div>
							<div class="mb-6">
								<p class="smaller text-cream">Time</p>
								<h4 class="text-cream mt-0">{{ humanTime }}</h4>
							</div>
							<div class="mb-6">
								<h5 class="text-cream mt-0">Card payments</h5>
								<p class="smaller text-cream">
									We only accept card payments, apologies for
									any inconvenience this may cause
								</p>
							</div>
						</div>
						<card-form
							v-if="
								!stripeDetailsAlreadyExistForBooking &&
								!isReservationCancelled
							"
							:classes="'my-10'"
							:bookingData="this.bookingData"
							:successMessage="'Card Confirmed ✓ PLease wait...'"
						></card-form>
					</div>
				</transition>

				<div class="grow-[1] md:grow-[0]">
					<p class="font-thin text-sm text-brand">Powered by:</p>
					<img
						loading="lazy"
						alt="Open Table"
						class="w-[160px] m-auto p-[10px]"
						src="@/assets/ot_logo.png"
					/>
				</div>
			</div>
		</transition>

		<!--  fixed booking widget (mobile)  -->
		<div
			class="w-screen fixed lg:hidden bottom-0 left-0 z-40 c-top-shadow flex justify-between items-center bg-brand"
		>
			<router-link
				:to="mobileBottomNavBookATableLink.link"
				class="bg-mustard w-1/3 py-3 cursor-pointer"
			>
				<h5
					class="font-bold m-0"
					v-html="mobileBottomNavBookATableLink.text"
				></h5>
			</router-link>
			<router-link
				v-if="!isExternalLink(mobileBottomNavLocationsLink.link)"
				:to="generateUrl(mobileBottomNavLocationsLink.link)"
				class="bg-brand w-1/3 py-3"
			>
				<h5
					class="font-bold text-cream m-0"
					v-html="mobileBottomNavLocationsLink.text"
				></h5>
			</router-link>
			<a
				v-if="isExternalLink(mobileBottomNavLocationsLink.link)"
				:href="generateUrl(mobileBottomNavLocationsLink.link)"
				target="_blank"
				rel="noopener noreferrer"
				class="bg-brand w-1/3 py-3"
			>
				<h5
					class="font-bold text-cream m-0"
					v-html="mobileBottomNavLocationsLink.text"
				></h5>
			</a>
			<router-link
				v-if="!isExternalLink(mobileBottomNavMenusLink.link)"
				:to="generateUrl(mobileBottomNavMenusLink.link)"
				class="bg-brand w-1/3 py-3"
			>
				<h5
					class="font-bold text-cream m-0"
					v-html="mobileBottomNavMenusLink.text"
				></h5>
			</router-link>
			<a
				v-if="isExternalLink(mobileBottomNavMenusLink.link)"
				:href="generateUrl(mobileBottomNavMenusLink.link)"
				target="_blank"
				rel="noopener noreferrer"
				class="bg-brand w-1/3 py-3"
			>
				<h5
					class="font-bold text-cream m-0"
					v-html="mobileBottomNavMenusLink.text"
				></h5>
			</a>
		</div>
		<!--  fixed booking widget (desktop)  -->
		<div
			class="p-3 pt-2 w-screen hidden lg:block fixed bottom-0 left-0 z-40 c-top-shadow h-[76px]"
			:class="[
				getBackgroundColour('cream'),
				{ 'lg:px-10 xl:px-38 2xl:px-40': !showMenuButton },
			]"
		>
			<div
				class="relative w-full h-1 hidden lg:flex justify-between bg-transparent"
			>
				<transition>
					<div
						class="absolute -top-82 z-60 left-1.5 bg-cream p-3 !h-80"
						:class="
							desktopFixedBookingWidgetItemDropdownSizeClass(1)
						"
						v-show="
							dropdowns.location.visible &&
							dropdowns.location.enabled &&
							mode !== modes.amend
						"
						ref="locationsDropdown"
					>
						<label for="locationSearchInput" class="sr-only"
							>Search</label
						>
						<input
							type="text"
							class="border-2 border-brand bg-transparent w-full h-10 px-2 mb-4"
							v-model="locationSearchString"
							placeholder="Search"
						/>
						<ul class="c-scrollbar max-h-60 overflow-y-scroll">
							<li
								v-for="(restaurant, i) in filteredRestaurants"
								:key="i"
								class="p-2 cursor-pointer font-parisine"
								@click="selectLocation(restaurant.ID)"
								@keydown="selectLocation(restaurant.ID)"
							>
								{{ restaurant.post_title }}
							</li>
						</ul>
					</div>
				</transition>
				<transition>
					<div
						class="absolute -top-82 z-60 bg-cream p-5 !h-80"
						:class="
							desktopFixedBookingWidgetItemDropdownSizeClass(2)
						"
						v-show="
							dropdowns.guests.visible && dropdowns.guests.enabled
						"
						ref="guestsDropdown"
					>
						<ul class="c-scrollbar h-72 overflow-y-scroll">
							<li
								v-for="(guest, i) in guests.guests"
								:key="i"
								class="p-2 cursor-pointer font-parisine"
								@click="selectGuests(i)"
								@keydown="selectGuests(i)"
							>
								{{ guest.label }}
							</li>
						</ul>
					</div>
				</transition>
				<transition>
					<div
						class="absolute -top-82 z-60 bg-transparent py-5 px-2.5 !h-80"
						:class="
							desktopFixedBookingWidgetItemDropdownSizeClass(3)
						"
						id="desktop-footer-booking__date-picker__wrapper"
						v-show="
							dropdowns.date.visible && dropdowns.date.enabled
						"
						ref="dateDropdown"
					>
						<DatePicker
							v-model="bookingData.date"
							:min-date="dates.min"
							:max-date="dates.max"
							:disabled-dates="disabledCalendarDays"
							ref="fixedPicker"
							@dayclick="selectDate(false, $event)"
							is-expanded
						></DatePicker>
					</div>
				</transition>
				<transition>
					<div
						class="absolute -top-82 z-60 bg-cream p-5 !h-80"
						:class="
							desktopFixedBookingWidgetItemDropdownSizeClass(4)
						"
						v-show="
							dropdowns.time.visible && dropdowns.time.enabled
						"
						ref="timeDropdown"
					>
						<ul class="c-scrollbar h-full overflow-y-scroll">
							<li
								v-for="(time, i) in times.times"
								:key="i"
								class="p-2 cursor-pointer font-parisine"
								@click.prevent="selectTime(i)"
								@keydown.prevent="selectTime(i)"
							>
								{{ time }}
							</li>
						</ul>
					</div>
				</transition>
			</div>
			<div
				class="w-full hidden lg:flex justify-between bg-transparent overflow-hidden max-w-[1600px] mx-auto"
			>
				<div
					class="flex flex-col items-center justify-evenly pt-1 border border-brand cursor-pointer mx-1.5"
					:class="desktopFixedBookingWidgetItemSizeClass()"
					@click="toggleDropdowns('location')"
					@keydown="toggleDropdowns('location')"
					ref="locationBox"
				>
					<p class="text-xs text-center uppercase text-center m-0">
						Location
					</p>
					<div
						class="flex items-center justify-center my-1 relative w-full"
					>
						<p
							class="text-base font-copperplate text-center uppercase text-center m-0 whitespace-nowrap absolute left-[10px] w-[80%] overflow-hidden"
						>
							{{ humanLocation }}
						</p>
						<img
							loading="lazy"
							alt="Image"
							src="@/assets/booking-widget-down-arrow.svg"
							class="ml-1.5 max-w-full right-[10px] absolute"
						/>
					</div>
				</div>
				<div
					class="relative flex flex-col items-center justify-evenly pt-1 border border-brand cursor-pointer mx-1.5"
					:class="[
						desktopFixedBookingWidgetItemSizeClass(),
						{ 'opacity-50': !dropdowns.guests.enabled },
					]"
					@click="toggleDropdowns('guests')"
					@keydown="toggleDropdowns('guests')"
					ref="guestsBox"
				>
					<p class="text-xs text-center uppercase text-center m-0">
						Guests
					</p>
					<div class="flex items-center justify-center my-1">
						<p
							class="text-base font-copperplate text-center uppercase text-center m-0"
						>
							{{ humanGuests }}
						</p>
						<img
							loading="lazy"
							alt="Image"
							src="@/assets/booking-widget-down-arrow.svg"
							class="ml-1.5 max-w-full"
						/>
					</div>
				</div>
				<div
					class="relative flex flex-col items-center justify-evenly pt-1 border border-brand cursor-pointer mx-1.5"
					:class="[
						desktopFixedBookingWidgetItemSizeClass(),
						{ 'opacity-50': !dropdowns.date.enabled },
					]"
					@click="toggleDropdowns('date')"
					@keydown="toggleDropdowns('date')"
					ref="dateBox"
				>
					<p class="text-xs text-center uppercase text-center m-0">
						Date
					</p>
					<div class="flex items-center justify-center my-1">
						<p
							class="text-base font-copperplate text-center uppercase text-center m-0"
						>
							{{ humanDate }}
						</p>
						<img
							loading="lazy"
							alt="Image"
							src="@/assets/booking-widget-down-arrow.svg"
							class="ml-1.5 max-w-full"
						/>
					</div>
				</div>
				<div
					class="relative flex flex-col items-center justify-evenly pt-1 border border-brand cursor-pointer mx-1.5"
					:class="[
						desktopFixedBookingWidgetItemSizeClass(),
						{ 'opacity-50': !dropdowns.time.enabled },
					]"
					@click="toggleDropdowns('time')"
					@keydown="toggleDropdowns('time')"
					ref="timeBox"
				>
					<p class="text-xs text-center uppercase text-center m-0">
						Time
					</p>
					<div class="flex items-center justify-center my-1">
						<p
							class="text-base font-copperplate text-center uppercase text-center m-0"
						>
							{{ humanTime }}
						</p>
						<img
							loading="lazy"
							alt="Image"
							src="@/assets/booking-widget-down-arrow.svg"
							class="ml-1.5 max-w-full"
						/>
					</div>
				</div>
				<div
					class="flex flex-col items-center justify-evenly pt-1 bg-mustard cursor-pointer mx-1.5"
					:class="desktopFixedBookingWidgetItemSizeClass()"
					@click="findTablesFromBottomWidget()"
					@keydown="findTablesFromBottomWidget()"
				>
					<p
						class="text-base font-bold font-copperplate uppercase text-center m-0 leading-6"
					>
						Book a table
					</p>
				</div>
				<div
					v-if="showMenuButton"
					class="flex flex-col items-center justify-evenly pt-1 bg-brand cursor-pointer mx-1.5"
					:class="desktopFixedBookingWidgetItemSizeClass()"
					@click="navigateToBrasserieMenus"
					@keydown="navigateToBrasserieMenus"
				>
					<p
						class="text-base font-bold font-copperplate uppercase text-center m-0 leading-6 text-cream"
					>
						See the menu
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RestaurantLocationSelector from '@/components/RestaurantLocationSelector.vue';
import BookingMessage from '@/components/bookingWidget/steps/BookingMessage.vue';
import CardForm from '@/components/bookingWidget/steps/CardForm.vue';
import Placeholder from '@/components/layouts/Placeholder.vue';
import { SPECIAL_OCCASIONS } from '@/constants/globalConstants';
import exponea from '@/plugins/exponea';
import openTable from '@/plugins/openTable';
import wordpress from '@/plugins/wordpress';
import {
	ChevronDownIcon,
	ChevronLeftIcon,
	XIcon,
} from '@heroicons/vue/outline';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { DateTime } from 'luxon';
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import 'vue3-carousel/dist/carousel.css';
import localStore from '../plugins/localStore';
import InputBirthday from './common/InputBirthday.vue';

const RADIX = 10; // Specify 10 as the radix to avoid unexpected behaviour for parseInt() per https://eslint.org/docs/rules/radix

export default {
	name: 'BookingWidget',
	components: {
		CardForm,
		BookingMessage,
		XIcon,
		ChevronDownIcon,
		ChevronLeftIcon,
		DatePicker,
		VueTelInput,
		RestaurantLocationSelector,
		Placeholder,
		InputBirthday,
	},
	props: {
		fields: {
			type: Object,
			default: () => ({}),
		},
		open: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			policyMessages: [],
			restaurantExperiences: [],
			currentTimeslotExperiences: [],
			currentStep: 0,
			availabilityTypes: [],
			stripeTokenData: null,
			showMenuButton: false,
			fromPage: null,
			slotsShown: false,
			locationSearchString: '',
			reservationStepTrackingProgress: null,
			mode: 1,
			previousMode: 1,
			placeholderText: '',
			showPlaceholder: false,
			reservationStatus: '',
			isSingleRestaurantPromotion: false,
			manageBookingRequestSubmitted: false,
			showManageBookingsForm: true,
			existingBookingEvents: [],
			stripeDetailsAlreadyExistForBooking: false,
			reservationSource: 'website',
			modes: {
				reservation: 1,
				enquiry: 2,
				amend: 3,
				cancel: 4,
				reconfirm: 5,
				confirmCard: 6,
			},
			resultMessage: 'Card Confirmed ✓',
			error: {
				visible: false,
				title: '',
				message: '',
				callback: null,
			},
			promotionRestrictionSpecificDate: 'specificDate',
			promotionRestrictionDateRange: 'dateRange',
			isPromotion: false,
			incorrectlySetPromotion: false,
			promotionSettings: {
				restaurants: [],
			},
			overlayOpen: this.open,
			closeRestaurants: [],
			closeRestaurantsDisplayed: false,
			closeRestaurantsExist: false,
			hover: {
				menu: false,
			},
			requestedTimeObtained: null,
			slotsWithinTwoHours: null,
			typingTimeout: null,
			locationsSlider: {
				settings: {
					itemsToShow: 2.5,
					snapAlign: 'start',
				},
				breakpoints: {
					1024: {
						itemsToShow: 3.5,
						snapAlign: 'start',
					},
				},
			},
			activeOverlayScreen: 1,
			overlayScreens: {
				location: 1,
				guests_date_time: 2,
				experiences: 3,
				personal_details: 4,
				booking_confirmation: 5,
				amend_booking_request: 6,
				amend_booking_confirm: 7,
				cancel_booking_request: 8,
				cancel_booking_request_confirm: 9,
				cancel_booking: 10,
				cancel_booking_confirm: 11,
				enquiry_confirmation: 12,
				manage_bookings: 13,
				existing_booking_card_confirmation: 14,
			},
			dates: {
				min: DateTime.now().toJSDate(),
				max: DateTime.now().plus({ years: 1 }).toJSDate(),
				disabledDates: [],
				disabledWeekDays: [],
				daysOfWeek: [
					{
						name: 'Monday',
						int: 1,
					},
					{
						name: 'Tuesday',
						int: 2,
					},
					{
						name: 'Wednesday',
						int: 3,
					},
					{
						name: 'Thursday',
						int: 4,
					},
					{
						name: 'Friday',
						int: 5,
					},
					{
						name: 'Saturday',
						int: 6,
					},
					{
						name: 'Sunday',
						int: 7,
					},
				],
			},
			guests: {
				minGuests: 1,
				maxGuests: 150,
				restaurantMax: 0,
				guests: [],
			},
			times: {
				userHasSelectedATime: false,
				minTime: '09:00',
				maxTime: '22:00',
				times: [],
				overrides: [],
				durations: [1, 2, 3, 4, 5],
			},
			phoneValidationError: null,
			emailValidationError: null,
			// see created () bookingData is reset there.
			bookingData: {},
			quandooExtraInfoField: '',
			dropdowns: {
				location: {
					visible: false,
					enabled: true,
				},
				guests: {
					visible: false,
					enabled: false,
				},
				date: {
					visible: false,
					enabled: false,
				},
				time: {
					visible: false,
					enabled: false,
				},
				specialOccasion: {
					visible: false,
					enabled: false,
				},
			},
			overlayDropdowns: {
				location: {
					visible: false,
					enabled: true,
				},
				guests: {
					visible: false,
					enabled: true,
				},
				date: {
					visible: false,
					enabled: true,
				},
				time: {
					visible: false,
					enabled: true,
				},
				duration: {
					visible: false,
					enabled: true,
				},
				specialOccasion: {
					visible: false,
					enabled: true,
				},
			},
			amendEmail: null,
			cancelEmail: null,
			quandooTimes: [],
			consentText:
				'I agree to receive news and updates from Côte Brasserie via email',
			debug: process.env.NODE_ENV === 'development',
			currentLondonTime: DateTime.local().setZone('Europe/London', {
				keepLocalTime: true,
			}),
		};
	},
	beforeMount() {
		this.updateCurrentLondonTime();
		setInterval(this.updateCurrentLondonTime, 1000);
	},
	computed: {
		isReservationCancelled() {
			if (this.reservationStatus.indexOf('CONFIRMED') >= 0) {
				return false;
			}

			if (
				this.reservationStatus.indexOf('CANCELLED') >= 0 ||
				this.reservationStatus.indexOf('CANCELED') >= 0
			) {
				return true;
			}

			return false;
		},
		widgetHeading() {
			if (this.isEnquiry) {
				return 'Make an Enquiry';
			}
			if (this.isAmend) {
				return 'Amend your Reservation';
			}
			return 'Book a table';
		},

		onRestaurantPage() {
			return this.$route.path.indexOf('restaurant') >= 0;
		},
		timeSlotsConfig() {
			return this.$store.getters.getBookingWidgetConfig('slots');
		},
		messages() {
			return this.$store.getters.getBookingWidgetConfig('messages');
		},
		reminderConfig() {
			return this.$store.getters.getBookingWidgetConfig('reminder');
		},
		moodBoardConfig() {
			return {
				image_group:
					this.$store.getters.getBookingWidgetConfig('moodBoard'),
			};
		},
		mobileBottomNavBookATableLink() {
			return {
				text: 'Book a<br>table',
				link: '/book-a-table',
			};
		},
		mobileBottomNavLocationsLink() {
			if (this.$route.path.indexOf('restaurant') >= 0) {
				return {
					text: 'See<br>menu',
					link: `/restaurant/${this.$route.meta.name}/menus`,
				};
			}
			return {
				text: 'See<br>locations',
				link: '/locations',
			};
		},
		mobileBottomNavMenusLink() {
			if (
				this.$route.path.indexOf('restaurant') >= 0 &&
				this.bookingData.location !== null &&
				this.bookingData.location !== undefined
			) {
				return {
					text: 'See<br>on map',
					link: `https://www.google.com/maps/search/?api=1&query=${encodeURI(
						`cote ${
							this.restaurants.find((r) => {
								const restaurantSlug = this.getRestaurantSlug(
									r.post_title
								);
								return (
									restaurantSlug === this.$route.meta.name ||
									restaurantSlug ===
										this.$route.meta.parent_brasserie
								);
							}).address
						}`
					)}`,
				};
			}
			return {
				text: 'See<br>menus',
				link: '/menus',
			};
		},
		promotion() {
			return this.$store.state.promotion;
		},
		humanLocation() {
			if (
				this.bookingData.location === null ||
				this.bookingData.location === undefined
			) {
				return 'SELECT';
			}
			return this.bookingData.location.post_title;
		},
		humanGuests() {
			if (this.bookingData.guests === null) {
				return '2 PEOPLE';
			}
			return this.bookingData.guests.label;
		},
		humanDate() {
			let dateObj = null;
			if (this.bookingData.date === null) {
				dateObj = DateTime.now();
			} else {
				dateObj = DateTime.fromJSDate(this.bookingData.date);
			}
			let day = this.dateOrdinal(dateObj.toFormat('dd'));

			if (day[0] === '0') {
				day = day.substring(1);
			}

			return day + dateObj.toFormat(' LLLL');
		},
		humanTime() {
			if (this.bookingData.time === null) {
				return '19:00';
			}
			return this.bookingData.time;
		},
		reservationDateTime() {
			const { hour, minute } = DateTime.fromFormat(
				this.bookingData.time,
				'HH:mm'
			).toObject();
			const reservationDateTime = DateTime.fromJSDate(
				this.bookingData.date
			).set({
				hour,
				minute,
				seconds: 0,
				milliseconds: 0,
			});

			return reservationDateTime;
		},
		disabledCalendarDays() {
			const weekdays = this.dates.disabledWeekDays.map((weekday) =>
				weekday === 7 ? 1 : weekday + 1
			);
			return [{ weekdays }, ...this.dates.disabledDates];
		},
		humanSlot() {
			return this.bookingData.quandooSlot !== null
				? this.bookingData.quandooSlot.slotTime.toFormat('HH:mm')
				: '';
		},
		humanDuration() {
			if (this.bookingData.duration === null) {
				return '1 hour';
			}
			return this.bookingData.duration === 1
				? '1 hour'
				: `${this.bookingData.duration} hours`;
		},
		birthDayToUnixIntegerOrNull() {
			const { birthDay } = this.bookingData.personalDetails;

			if (!birthDay) {
				return null;
			}

			return DateTime.fromJSDate(birthDay)
				.set({
					hour: 12,
					minute: 0,
					second: 0,
				})
				.toUnixInteger();
		},
		isValidPromotion() {
			const now = DateTime.now().startOf('day');
			if (!this.isPromotion || this.incorrectlySetPromotion) {
				return false;
			}
			if (
				this.promoCodeLatestDateRestrictionType ===
				this.promotionRestrictionSpecificDate
			) {
				return this.promotion.book_a_table_date_inclusions.some(
					(item) => item.date >= now.toFormat('yyyy-MM-dd')
				);
			}

			if (
				this.promoCodeLatestDateRestrictionType ===
				this.promotionRestrictionDateRange
			) {
				const minDate = DateTime.fromISO(
					this.promotion.book_a_table_min_date
				);
				const maxDate = DateTime.fromISO(
					this.promotion.book_a_table_max_date
				);

				// Restricting the promotion to a day that
				// - is greater than the minDate and less than the maxDate
				// - either the starting or the ending dates are in the future
				// - does not fall into a weekday that is disabled
				// (adding +1 day as WP starts the week on a Sunday while luxon always uses Monday)
				// - does not fall into a date that is set as excluded
				const isAllowedDate = minDate >= now || maxDate >= now;
				return isAllowedDate;
			}

			if (this.isPromotion && this.isSingleRestaurantPromotion) {
				return true;
			}
			return false;
		},
		isLocationRestrictedPromotion() {
			return (
				this.promotionSettings.restaurants.length <
				this.$store.state.restaurants.length
			);
		},
		restaurants() {
			return this.$store.state.restaurants;
		},
		selectedRestaurant() {
			return this.$store.state.selectedRestaurant;
		},
		filteredRestaurants() {
			const restaurants = this.isValidPromotion
				? this.promotionSettings.restaurants
				: this.restaurants;
			return this.filterByLocationString(restaurants);
		},
		personalDetailsCompleted() {
			const props = this.bookingData.personalDetails;
			return (
				this.personalDetailsValid &&
				props.firstName !== null &&
				props.firstName !== '' &&
				props.lastName !== null &&
				props.lastName !== '' &&
				props.email !== null &&
				props.email !== '' &&
				props.phone !== null &&
				props.phone !== '' &&
				this.validatePhoneNumber()
			);
		},
		personalDetailsValid() {
			return !this.phoneValidationError && !this.emailValidationError;
		},
		isEnquiry() {
			return this.mode === this.modes.enquiry;
		},
		isAmend() {
			return this.mode === this.modes.amend;
		},
		getGdprText() {
			return this.$store.getters.getWpOptions().options_gdpr_text;
		},
		stripeCardRequiredForSelectedSlot() {
			if (!this.bookingData.quandooSlot) {
				return false;
			}

			return this.bookingData.quandooSlot.cc;
		},
		restaurantIdString() {
			return (
				this.bookingData.location.open_table_id ||
				this.bookingData.location.quandoo_id
			);
		},
		restaurantIdInteger() {
			return parseInt(this.restaurantIdString, RADIX);
		},
		isRestaurantInOpenTable() {
			return !!this.bookingData.location.open_table_id;
		},
		isReservationTokenValid() {
			const { token, tokenExpirationDateTime } =
				this.bookingData.tokenData;

			if (
				!token ||
				!tokenExpirationDateTime ||
				this.isReservationDataDifferentFromTokenData
			) {
				return false;
			}

			const expirationDateTime = DateTime.fromISO(
				tokenExpirationDateTime,
				{ zone: 'utc' }
			);
			const currentDateTime = DateTime.now();

			return expirationDateTime > currentDateTime;
		},
		isReservationDataDifferentFromTokenData() {
			const { guests, tokenData } = this.bookingData;
			const { party_size, date_time, restaurant_id } =
				tokenData.reservationData;
			const dateTimeFormatted =
				this.reservationDateTime.toFormat("yyyy-MM-dd'T'HH:mm");

			const isReservationDataDifferent =
				guests.value !== party_size ||
				dateTimeFormatted !== date_time ||
				this.restaurantIdInteger !== restaurant_id ||
				this.isSelectedExperienceDifferent;

			return isReservationDataDifferent;
		},
		isSelectedExperienceDifferent() {
			const oldExperience =
				this.bookingData.tokenData.reservationData.experience;
			const newExperience = this.bookingData.experience;

			if (!newExperience && !oldExperience) {
				return false;
			}

			if (
				(!newExperience && oldExperience) ||
				(newExperience && !oldExperience)
			) {
				return true;
			}

			return (
				newExperience.id !== oldExperience.id ||
				newExperience.version !== oldExperience.version
			);
		},
		visibleExperiences() {
			return this.restaurantExperiences.filter((experience) =>
				this.currentTimeslotExperiences.includes(experience.id)
			);
		},
		showStandardBooking() {
			return this.availabilityTypes.includes('Standard');
		},
		handleDateOverlayClick() {
			if (!this.overlayDropdowns.date.visible) {
				this.toggleDropdowns('dateOverlay');
			}
		},
		navigateToBrasserieMenus() {
			this.$router.push(this.getBrasserieMenusLink(this.$route.meta.id));
		},
		isScreenActive() {
			return (screenName) => this.activeOverlayScreen === screenName;
		},
	},

	/**
	 * Watchers
	 */
	watch: {
		// Handles redirecting to book-a-table page,
		// loads amend/cancel links
		// and redirects to previous page before opening widget when closing widget.
		// Note: Hash changed do not trigger this.

		$route(to, from) {
			this.isPromotion = false;
			this.$store.state.queryObject = this.$route.query;

			this.overlayOpen = false;

			// We're on a book-a-table page
			if (to.path.indexOf('book-a-table') >= 0) {
				// Stash the previous page so we can go there when the overlay is closed.
				if (from.path !== '/book-a-table') {
					this.fromPage = from.path;
				}

				this.toggleSplash('show', 'Loading');

				// Get URL params
				const params = this.getUrlSearchParams();

				// Check the params
				const isAmend = this.checkForAmendUrl(params);
				const isCancel = this.checkForCancelUrl(params);
				const isReconfirm = this.checkForReconfirmationUrl(params);
				const isCardConfirmation =
					this.checkForExistingBookingCardConfirmationUrl(params);
				const isManage = this.checkForManageUrl(params);
				const isContinue = this.checkForContinueUrl(params);
				const hasPresetParams = this.checkForPresetParams(params);

				this.toggleSplash('hide');

				// Is there a promotion on the url ?
				this.checkForPromotion();

				// If we're past step 1, then we need to make sure we have valid data, otherwise we need to go
				// back to step 1 unless we're on the manage url
				if (window.location.href.indexOf('#step') > -1 && !isManage) {
					const screen = this.getScreen();

					if (
						!this.bookingData.location &&
						screen > this.overlayScreens.location
					) {
						// go back to step 1.
						this.goToScreen(
							this.overlayScreens.location,
							false,
							true,
							true
						);
					} else {
						// go to the specific screen
						this.goToScreen(screen, false, true, false);
					}
				}

				// TODO: why are we doing this check?
				if (this.bookingData.location && !this.selectedRestaurant) {
					this.selectLocation(this.bookingData.location.ID, true);
				}

				// TODO: why are we doing this ??
				if (this.selectedRestaurant) {
					this.selectLocation(this.selectedRestaurant.ID, true);
				}
				this.overlayOpen = true;
			}
			this.showMenuButton =
				to.path.indexOf('restaurant') >= 0 &&
				to.path.indexOf('menus') <= 0;
			this.setSelectedRestaurant();
			this.toggleSplash('hide');
		},
		mode(newVal, oldVal) {
			this.previousMode = oldVal;
		},
		open(newVal) {
			if (this.isValidPromotion && this.bookingData.location !== null) {
				this.goToScreen(this.overlayScreens.guests_date_time);
			}
			if (newVal === true) {
				this.trackReservationProgress(100);
			}
		},
		'error.title': function () {
			this.error.callback && this.error.callback();
			this.error.visible = true;
			setTimeout(() => {
				this.error.visible = false;
			}, 15000);
		},
	},
	methods: {
		/**
		 * Handles errors that occur during the reservation process in a booking widget.
		 * It takes an error object as its parameter and sets the title, errorMessage, and visible properties of an error object based on the error status.
		 * If the error status is 424, it extracts the error details from the response body and passes it to the handleSecurePayment method of the cardForm component.
		 * Finally, it sets a callback function that resets the widget after an error, hides the slots, and goes to the guests and date/time screen.
		 * @param {*} error
		 */
		async handleReservationError(result) {
			const reservationError = result.details?.reason?.errors[0];
			let errorMessage =
				reservationError?.message ??
				'There was an issue during creating a new reservation';
			let error409;

			if (result.code === 409) {
				if (
					errorMessage.toLowerCase().includes('sca') ||
					errorMessage.includes('InvalidCreditCardToken')
				) {
					error409 = 'Card secure verification failed.';
					errorMessage =
						'Please try again or contact the restaurant to make this booking';
				} else {
					error409 =
						'It looks like you already have a booking for this date and time. Please choose another date and time to proceed.';
					errorMessage =
						'Alternatively, check your inbox for your booking confirmation email to modify or cancel your existing booking.';
				}
			} else if (result.code === 424) {
				const securePaymentResponse = reservationError?.details ?? null;
				if (securePaymentResponse) {
					await this.$refs.cardForm.handleSecurePayment(
						securePaymentResponse
					);
					return;
				}
				errorMessage = 'There was an issue processing secure payment';
			}

			if (error409) {
				this.error.title = error409;
				this.error.message = errorMessage;
			} else {
				this.error.title = `Sorry there has been an error. ${errorMessage}`;
				this.error.message =
					'Please try again or contact the restaurant to make this booking';
			}

			this.error.visible = true;
			this.error.callback = () => {
				this.resetWidgetAfterError();
				this.slotsShown = false;
				this.goToScreen(this.overlayScreens.guests_date_time);
				this.toggleSplash('hide');
			};
		},
		updateCurrentLondonTime() {
			this.currentLondonTime = DateTime.local({ zone: 'Europe/London' })
				.setZone('Europe/London', { keepLocalTime: false })
				.setLocale('en-GB');
		},
		/**
		 * Work out what screen we're on
		 *
		 * @returns number
		 */
		getScreen() {
			const { hash } = window.location;
			return !hash ? 1 : parseInt(hash.slice(hash.length - 1));
		},
		/**
		 * Push selected experience to the booking data
		 */
		async selectExperience(experience) {
			this.bookingData.experience = experience;

			await this.lockReservation();
			if (this.error.visible) {
				return;
			}

			this.goToScreen(this.overlayScreens.personal_details);
		},
		/**
		 * Go to previous screen from personal details depending on experience
		 */
		goBackFromPersonalDetails() {
			const screen = this.currentTimeslotExperiences.length
				? this.overlayScreens.experiences
				: this.overlayScreens.guests_date_time;
			this.goToScreen(screen);
		},
		/**
		 * Send a consent event into exponea if the user has agreed to be on the newsletter.
		 * This is done for restaurants on Quandoo only.
		 * For restaurants in Open table this is done in Azure function.
		 */
		maybeTrackConsent() {
			const { consent } = this.bookingData.personalDetails;

			if (!consent) {
				return;
			}

			exponea.track('consent', {
				action: 'accept',
				category: 'newsletter',
				domain: 'cote.co.uk',
				language: 'en',
				location: window.location.href,
				merchant_id: this.bookingData.location.quandoo_id,
				message: this.consentText,
				placement: 'Booking Form',
				valid_until: 'unlimited',
			});
		},

		/**
		 * TODO:  this should be done through wordpress
		 */
		validatePhoneNumber(showError = false) {
			const isPhoneValid = isValidPhoneNumber(
				this.bookingData.personalDetails.phone,
				this.bookingData.personalDetails.country
			);
			// const value = this.bookingData.personalDetails.phone.replace(/\s+/g, '')
			// const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
			if (!isPhoneValid) {
				if (showError) {
					this.phoneValidationError = 'Phone number invalid';
				}
				return false;
			}
			this.phoneValidationError = null;
			return true;
		},
		/**
		 * TODO:  this should be done through wordpress
		 */
		validateEmail() {
			const value = this.bookingData.personalDetails.email;
			const re =
				/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			if (!re.test(value)) {
				this.emailValidationError = 'Email Address invalid';
			} else {
				this.emailValidationError = null;
			}
		},

		/**
		 * maybe uncompress a reservation id if it's been shortened.
		 *
		 * @param reservationId
		 * @returns {string|*}
		 */
		maybeUncompressReservationId(reservationId) {
			if (reservationId.indexOf('-') < 0) {
				return this.uncompressUuid(reservationId);
			}
			return reservationId;
		},

		desktopFixedBookingWidgetItemSizeClass() {
			return this.showMenuButton ? 'w-2/12' : 'w-1/5';
		},

		desktopFixedBookingWidgetItemDropdownSizeClass(position) {
			if (position === 1) {
				return 'c-w-1/5-m';
			}
			if (position === 2) {
				return 'c-w-1/5-m c-l-1/5-m';
			}
			if (position === 3) {
				return 'c-w-1/5-m c-l-2/5-m';
			}
			if (position === 4) {
				return 'c-w-1/5-m c-l-3/5-m';
			}
			return '';
		},

		/**
		 * back to the home page
		 */
		goHome() {
			this.overlayOpen = false;
			if (this.open) {
				this.$emit('closeBookings');
			}
			window.location.href = `${window.location.protocol}//${window.location.host}`;
		},

		/**
		 * Check for a url in this format, typically coming from abandoned/incomplete bookings
		 * xyz/book-a-table/?isContinue=true&dateTime=2022-07-27T19:00:00.501+01:00&guests=2&brasserieId=50874
		 * Populate booking form with the params in the URL and perform a search for available times
		 *
		 */
		async checkForContinueUrl(params) {
			if (params.isContinue) {
				// We have a continue URL

				// Turn the parameters into usable variables
				const { time } = params;
				const date = new Date(params.date);
				const guests = parseInt(params.guests, RADIX);
				const { brasserieId } = params;

				// Fill bookingData with the parameters
				await this.selectLocation(brasserieId);
				this.bookingData.guests = {
					label: `${guests} people`,
					value: guests,
				};
				this.bookingData.time = time;
				this.times.userHasSelectedATime = true;
				this.bookingData.date = date;

				// Go to step 2
				this.goToScreen(this.overlayScreens.guests_date_time);

				// Search for a table with the parameters
				this.getAvailableTimeSlotsForRestaurant();

				this.toggleSplash('hide');
				return true;
			}
			return false;
		},

		/**
		 * Check the url for the following params and populate the booking widget for each one
		 * date
		 * time
		 * guests
		 * brasserieId
		 */
		async checkForPresetParams(params) {
			if (
				params.time ||
				params.date ||
				params.guests ||
				params.brasserieId ||
				params.firstName ||
				params.lastName ||
				params.reservationSource ||
				params.specialRequest
			) {
				// At least one of the four params is set

				// Turn the parameters into usable variables
				const time = params?.time ? params.time : false;
				const date = params?.date ? new Date(params.date) : false;
				const guests = params?.guests
					? parseInt(params.guests, RADIX)
					: false;
				const firstName = params?.firstName
					? params.firstName.charAt(0).toUpperCase() +
					  params.firstName.slice(1)
					: false;
				const lastName = params?.lastName
					? params.lastName.charAt(0).toUpperCase() +
					  params.lastName.slice(1)
					: false;
				const brasserieId = params?.brasserieId
					? params.brasserieId
					: false;
				const reservationSource = params?.reservationSource
					? params.reservationSource
					: false;
				const specialRequest = params?.specialRequest
					? params.specialRequest
					: false;

				// Fill bookingData with the parameters

				if (brasserieId) {
					await this.selectLocation(brasserieId);
					// Go to step 2 only if a brasserie ID was provided
					this.goToScreen(this.overlayScreens.guests_date_time);
				}

				if (guests) {
					this.bookingData.guests = {
						label: `${guests} people`,
						value: guests,
					};
				}

				if (specialRequest) {
					this.bookingData.personalDetails.specialRequests =
						specialRequest;
				}

				// https://cote.co.uk/book-a-table?time=15:45&guests=5&date=23 Sep 2022

				if (time) {
					this.bookingData.time = time;
					this.times.userHasSelectedATime = true;
				}

				if (date) {
					this.bookingData.date = date;
				}

				if (firstName) {
					this.bookingData.personalDetails.firstName = firstName;
				}

				if (lastName) {
					this.bookingData.personalDetails.lastName = lastName;
				}

				if (reservationSource) {
					this.reservationSource = reservationSource;
				}

				// Search for a table with the parameters
				if (brasserieId && guests && time && date) {
					this.getAvailableTimeSlotsForRestaurant();
				}

				this.toggleSplash('hide');
				return true;
			}
			return false;
		},

		/**
		 * let's check if there is a promocode on the url , and if so we need to grab the
		 * override settings from WordPress and apply them.
		 */
		checkForPromotion() {
			if (
				(this.$route.query.promocode === undefined &&
					this.$store.state.promotion === null) ||
				!this.$route.query.promocode.trim().length
			) {
				this.isPromotion = false;
				this.promotionSettings = { restaurants: [] };
				return;
			}

			// If there is a promocode in the query
			if (this.$route.query.promocode !== undefined) {
				// So we have a promotion. this.promotion refers to the store!
				this.$store
					.dispatch(
						'getPromotionByCode',
						this.$store.state.queryObject
					)
					.then(() => {
						this.updateSettingsOnPromotion();
					});
			} else if (this.$store.state.promotion !== null) {
				// If there is a promotion saved in the store from a previous visit
				// Just use that promotion
				this.updateSettingsOnPromotion();
			}
		},

		updateSettingsOnPromotion(locationAlreadyset = false) {
			this.isPromotion = true;
			// the promotion is valid...

			// Set guests
			if (this.promotion.book_a_table_min_guests) {
				this.guests.minGuests = parseInt(
					this.promotion.book_a_table_min_guests,
					RADIX
				);
			}

			if (this.promotion.book_a_table_max_guests) {
				this.guests.restaurantMax = parseInt(
					this.promotion.book_a_table_max_guests,
					RADIX
				);
				this.guests.maxGuests = parseInt(
					this.promotion.book_a_table_max_guests,
					RADIX
				);
			}

			if (this.guests.minGuests > 1) {
				this.bookingData.guests = {
					label: `${this.guests.minGuests} people`,
					value: this.guests.minGuests,
				};
			} else if (this.guests.maxGuests > 1) {
				this.bookingData.guests = {
					label: '2 people',
					value: 2,
				};
			} else {
				this.bookingData.guests = {
					label: '1 person',
					value: 1,
				};
			}

			// Set restaurants

			if (
				this.promotion.book_a_table_specific_restaurants &&
				this.promotion.book_a_table_restaurants.length > 0
			) {
				const promoRestaurants =
					this.promotion.book_a_table_restaurants;
				this.promotionSettings.restaurants = this.restaurants.filter(
					(r) => promoRestaurants.includes(r.ID)
				);

				if (this.promotionSettings.restaurants.length === 1) {
					if (!locationAlreadyset) {
						this.selectLocation(
							this.restaurants.find(
								(r) =>
									r.ID ===
									this.promotionSettings.restaurants[0].ID
							).ID
						);
					}
					// If there is only one restaurant, we want to skip a step in the booking process
					this.goToScreen(this.overlayScreens.guests_date_time);
					this.isSingleRestaurantPromotion = true;
				}
			} else {
				// use them all.
				this.promotionSettings.restaurants = this.restaurants;
			}

			// Set dates & times
			// If date_restrictions is === 'exclusion', there is a Date range set in the WP instance
			if (this.promotion.date_restrictions === 'exclusion') {
				this.promoCodeLatestDateRestrictionType =
					this.promotionRestrictionDateRange;
				// Min and max dates
				if (this.promotion.book_a_table_min_date.length > 0) {
					// override default this.dates.min if it has been set in the Promotion
					const now = DateTime.now();
					const dateMinPromo = DateTime.fromISO(
						this.promotion.book_a_table_min_date
					);

					this.dates.min =
						dateMinPromo < now
							? now.toJSDate()
							: dateMinPromo.toJSDate();
				}

				if (this.promotion.book_a_table_max_date.length > 0) {
					// override default this.dates.max if it has been set in the Promotion
					this.dates.max = DateTime.fromISO(
						this.promotion.book_a_table_max_date
					).toJSDate();
				}

				// Disabled days
				this.dates.daysOfWeek.forEach((d) => {
					if (!this.promotion.book_a_table_days.includes(d.name)) {
						this.dates.disabledWeekDays.push(d.int);
					}
				});

				// Disabled dates
				if (this.promotion.book_a_table_date_exclusions.length > 0) {
					this.promotion.book_a_table_date_exclusions.forEach((d) => {
						this.dates.disabledDates.push(
							DateTime.fromISO(d.date).toJSDate()
						);
					});
				}

				// Specific times
				if (this.promotion.book_a_table_min_time) {
					this.times.minTime = this.promotion.book_a_table_min_time;
					this.bookingData.time =
						this.promotion.book_a_table_min_time;
				}

				if (this.promotion.book_a_table_max_time) {
					this.times.maxTime = this.promotion.book_a_table_max_time;
				}
			}
			//   If date_restrictions is === 'inclusion', there is a specific date set in the WP instance
			if (this.promotion.date_restrictions === 'inclusion') {
				this.promoCodeLatestDateRestrictionType =
					this.promotionRestrictionSpecificDate;
				// Included dates
				let date = DateTime.fromJSDate(this.dates.min);
				const maxDate = DateTime.fromJSDate(this.dates.max);

				// Adding +1 day otherwise the last day of the year is not disabled
				while (date < maxDate.plus({ days: 1 })) {
					const dateString = date.toISODate();

					if (
						this.promotion.book_a_table_date_inclusions.filter(
							(d) => d.date === dateString
						).length === 0
					) {
						this.dates.disabledDates.push(date.toJSDate());
					}

					date = date.plus({ days: 1 });
				}

				// Specific times
				if (this.promotion.book_a_table_min_time) {
					this.times.minTime = this.promotion.book_a_table_min_time;
					this.times.maxTime = this.promotion.book_a_table_max_time;
				}
			}

			if (this.promotion.date_restrictions === 'multi') {
				// Included dates
				let date = DateTime.fromJSDate(this.dates.min);
				const maxDate = DateTime.fromJSDate(this.dates.max);

				while (date < maxDate) {
					const dateString = date.toISODate();

					if (
						this.promotion.book_a_table_date_inclusions?.filter(
							(d) => d.date === dateString
						).length === 0
					) {
						this.dates.disabledDates.push(date.toJSDate());
					}

					date = date.plus({ days: 1 });
				}
			}
			this.setFirstAvailableDate();
		},

		/**
		 * Returns a proxy with URLSearchParams
		 */
		getUrlSearchParams() {
			return new Proxy(new URLSearchParams(window.location.search), {
				get: (searchParams, prop) => searchParams.get(prop),
			});
		},
		/**
		 * Map utm fields to target object if they exist
		 * @param {*} target
		 */
		mapUtmFields(target) {
			const utm = localStore.get('utm') ?? this.getUtmFieldsFromUrl();

			if (!utm) {
				return;
			}

			const utmMapping = {
				c: 'utm_campaign',
				s: 'utm_source',
				m: 'utm_medium',
			};

			Object.keys(utm).forEach((key) => {
				const field = utmMapping[key];
				if (field && utm[key]) {
					target[field] = utm[key];
				}
			});
		},
		getUtmFieldsFromUrl() {
			const params = new URLSearchParams(window.location.search);
			const utm_campaign = params.get('utm_campaign');
			const utm_source = params.get('utm_source');
			const utm_medium = params.get('utm_medium');

			const utm = {
				...(utm_campaign && { c: utm_campaign }),
				...(utm_source && { s: utm_source }),
				...(utm_medium && { m: utm_medium }),
			};

			return Object.keys(utm).length ? utm : null;
		},

		/**
		 * Checks if we're on the manage-bookings url
		 */
		checkForManageUrl(params) {
			if (window.location.href.indexOf('manage-bookings') > -1) {
				this.goToScreen(this.overlayScreens.manage_bookings);
				this.toggleSplash('hide');

				// Is there an email present?
				if (params?.ee) {
					this.showManageBookingsForm = false;
					const userEmail = atob(params.ee);
					try {
						this.getExistingBookingsFromExponea(userEmail);
					} catch (error) {
						wordpress.errorLog({
							eID: 'getExistingBookingsFromExponea',
							metaData: {
								error,
							},
						});
					}
				}

				return true;
			}
			return false;
		},

		/**
		 * Check if this is for amending the reservation
		 */
		checkForAmendUrl(params) {
			if (
				window.location.href.indexOf('reservation_id=') > -1 &&
				window.location.href.indexOf('cancel') === -1 &&
				window.location.href.indexOf('reconfirm-booking') === -1 &&
				window.location.href.indexOf('confirm-card-details') === -1
			) {
				wordpress
					.quandooGetReservation(
						this.maybeUncompressReservationId(params.reservation_id)
					)
					.then((r) => r.json())
					.then((resp) => {
						this.setBookingDataFromExistingReservation(
							resp.reservation,
							resp.promotion,
							this.modes.amend
						);
						setTimeout(() => {
							this.toggleSplash('hide');
						}, 500);
					});
				return true;
			}
			return false;
		},

		/**
		 * Check if this is a cancel link
		 */
		checkForCancelUrl(params) {
			if (window.location.href.indexOf('cancel?reservation_id=') > -1) {
				wordpress
					.quandooGetReservation(
						this.maybeUncompressReservationId(params.reservation_id)
					)
					.then((r) => r.json())
					.then((resp) => {
						this.setBookingDataFromExistingReservation(
							resp.reservation,
							resp.promotion,
							this.modes.cancel
						);
						setTimeout(() => {
							this.toggleSplash('hide');
						}, 500);
					});
				return true;
			}
			return false;
		},

		/**
		 * Check if  this is re-confirm booking
		 */
		checkForReconfirmationUrl(params) {
			if (
				window.location.href.indexOf(
					'reconfirm-booking?reservation_id='
				) > -1
			) {
				wordpress
					.quandooGetReservation(
						this.maybeUncompressReservationId(params.reservation_id)
					)
					.then((r) => r.json())
					.then((resp) => {
						//  setTimeout(() => {
						this.setBookingDataFromExistingReservation(
							resp.reservation,
							resp.promotion,
							this.modes.reconfirm
						);
						setTimeout(() => {
							this.toggleSplash('hide');
						}, 500);
						//  }, 3000)
					});
				return true;
			}
			return false;
		},

		/**
		 * Check if  this a card confirmation url
		 */
		async checkForExistingBookingCardConfirmationUrl(params) {
			if (window.location.href.indexOf('confirm-card-details') > -1) {
				wordpress
					.quandooGetReservation(
						this.maybeUncompressReservationId(params.reservation_id)
					)
					.then((r) => r.json())
					.then((resp) => {
						// this.getExistingBookingsFromExponea(resp.customer.email)
						this.setBookingDataFromExistingReservation(
							resp.reservation,
							resp.promotion,
							this.modes.confirmCard,
							resp.customer
						);

						if (resp.reservation?.extraInfo) {
							// We check both for *DEPOSIT CARD PROVIDED ON STRIPE* (legacy message) and **DEPOSIT_INFO** (new message)
							if (
								resp.reservation.extraInfo.indexOf(
									'**DEPOSIT_INFO**'
								) >= 0 ||
								resp.reservation.extraInfo.indexOf(
									'*DEPOSIT CARD PROVIDED ON STRIPE*'
								) >= 0
							) {
								// If these exist in the booking extra info, then we know we've already got stripe details for the booking
								this.stripeDetailsAlreadyExistForBooking = true;
							}
						}

						setTimeout(() => {
							this.toggleSplash('hide');
						}, 500);
					});
				return true;
			}
			return false;
		},

		/**
		 * calculate the date ordinal for a day.
		 *
		 * @param n
		 * @returns {string}
		 */
		dateOrdinal(n) {
			const s = ['th', 'st', 'nd', 'rd'];
			const v = n % 100;
			return n + (s[(v - 20) % 10] || s[v] || s[0]);
		},

		/**
		 * Gets the date in format Thursday 8th June 2023, known as long date format
		 */
		getDateInFormatLongDate(date) {
			return `${date.toFormat('cccc')} ${this.dateOrdinal(
				date.toFormat('dd')
			).replace(/^0(?:0:0?)?/, '')} ${date.toFormat('LLLL yyyy')}`;
		},

		/**
		 * populate data from an existing reservation
		 *
		 * @param reservation
		 * @param promotion
		 * @param mode
		 */
		setBookingDataFromExistingReservation(
			reservation,
			promotion,
			mode,
			customer = false
		) {
			this.bookingData.reservationId = reservation.id;
			this.bookingData.location = this.restaurants.find(
				(r) =>
					parseInt(r.quandoo_id, RADIX) ===
					parseInt(reservation.merchantId, RADIX)
			);
			this.bookingData.date = new Date(reservation.startTime);
			this.quandooExtraInfoField = reservation.extraInfo;
			this.selectDate(false, { date: this.bookingData.date });

			this.reservationStatus = reservation.status;

			if (customer) {
				this.bookingData.personalDetails.email = customer.email;
				this.bookingData.personalDetails.firstName = customer.firstName;
				this.bookingData.personalDetails.lastName = customer.lastName;
				this.bookingData.personalDetails.phone = customer.phoneNumber;
			}

			if (reservation?.extraInfo) {
				// Pulling out the special request from between the tags
				this.bookingData.personalDetails.specialRequests =
					reservation.extraInfo
						.substring(
							// 19 is the length of **SPECIAL_REQUEST**
							reservation.extraInfo.indexOf(
								'**SPECIAL_REQUEST**'
							) + 19,
							reservation.extraInfo.lastIndexOf(
								'**END_SPECIAL_REQUEST**'
							)
						)
						.trim();
			}

			// Set the promotion if it's set
			if (promotion) {
				this.$store.commit('initPromotion', promotion);
				this.updateSettingsOnPromotion(true);
			}

			this.bookingData.guests = {
				value: reservation.capacity,
				label:
					reservation.capacity === 1
						? '1 person'
						: `${reservation.capacity} people`,
			};
			this.bookingData.time = DateTime.fromJSDate(
				this.bookingData.date
			).toFormat('HH:mm');
			this.mode = mode;

			switch (mode) {
				case this.modes.amend:
					this.selectLocation(this.bookingData.location.ID);
					this.goToScreen(this.overlayScreens.guests_date_time);
					break;
				case this.modes.cancel:
					this.goToScreen(this.overlayScreens.cancel_booking);
					break;
				case this.modes.reconfirm:
					this.goToScreen(this.overlayScreens.booking_confirmation);
					break;
				case this.modes.confirmCard:
					this.goToScreen(
						this.overlayScreens.existing_booking_card_confirmation
					);
					break;
				default:
					console.error('Unexpected mode:', mode);
					break;
			}

			this.overlayOpen = true;
		},

		/**
		 * A location has been selected!
		 *
		 * @param id
		 * @param overlay
		 * @param locationBlocks
		 * @param openOverlay
		 * @param updateUrl
		 */
		async selectLocation(
			id,
			overlay = false,
			locationBlocks = false,
			openOverlay = true,
			updateUrl = true
		) {
			id = parseInt(id, RADIX);
			this.populateGuestDropdown();
			this.bookingData.location = this.restaurants.filter(
				(r) => r.ID === id
			)[0];
			// If Promotion this has own guest/date/time restrictions
			if (!this.isValidPromotion) {
				this.guests.restaurantMax = parseInt(
					this.bookingData.location.max_guests,
					RADIX
				);

				this.setTemporaryClosedDates();
				this.setFirstAvailableDate();

				// Set initial Booking Data if not in amend mode
				if (!this.bookingData.guests) {
					this.bookingData.guests = {
						label: '2 people',
						value: 2,
					};
				}
				this.bookingData.time =
					typeof this.bookingData.time === 'string'
						? this.bookingData.time
						: '19:00';
			}

			if (!this.isAmend) {
				if (
					this.bookingData.guests.value >
					this.bookingData.location.max_guests
				) {
					this.mode = this.modes.enquiry;

					/* only change dates.min if it is before the earliest enquiry booking */
					/* dates.min could be today or it could already be more than 2 days ahead in the case of promotions */
					if (
						!this.isValidPromotion ||
						DateTime.fromJSDate(this.dates.min) <
							DateTime.now().plus({ days: 2 })
					) {
						this.dates.min = DateTime.now()
							.plus({ days: 2 })
							.toJSDate();
					}
				} else if (
					this.bookingData.guests.value <=
					this.bookingData.location.max_guests
				) {
					this.mode = this.modes.reservation;

					/* only change dates.min if it is before the earliest reservation booking */
					/* dates.min could be today or it could already be a future date in the case of promotions */
					if (
						!this.isValidPromotion ||
						DateTime.fromJSDate(this.dates.min) <= DateTime.now()
					) {
						this.dates.min = DateTime.now().toJSDate();
					}
				}
			}

			this.quandooTimes = [];
			this.slotsShown = false;
			this.closeRestaurantsDisplayed = false;
			this.overlayDropdowns.specialOccasion.enabled = true;
			if (!overlay) {
				this.dropdowns.date.visible = false;
				if (this.dropdowns.location.visible) {
					this.toggleDropdowns('location');
				}
				this.dropdowns.guests.enabled = true;
				this.dropdowns.date.enabled = true;
				this.dropdowns.time.enabled = true;
			} else {
				if (!locationBlocks) {
					this.overlayDropdowns.location.visible = false;
					this.overlayDropdowns.guests.enabled = true;
					this.overlayDropdowns.date.enabled = true;
					this.overlayDropdowns.time.enabled = true;
				}
				// Move to next screen
				this.goToScreen(
					this.overlayScreens.guests_date_time,
					true,
					openOverlay,
					updateUrl
				);
				this.overlayDropdowns.date.visible = false;
			}
		},

		/**
		 * The number of guests has changed.
		 *
		 * @param i
		 * @param overlay
		 */
		selectGuests(i, overlay = false) {
			this.bookingData.guests = this.guests.guests[i];

			let firstAvailableCalendarDate;

			if (
				this.guests.guests[i].value >
				this.bookingData.location.max_guests
			) {
				this.mode = this.modes.enquiry;
				firstAvailableCalendarDate = DateTime.now().plus({ days: 2 });
			} else {
				this.mode = this.modes.reservation;
				firstAvailableCalendarDate = DateTime.now();
			}

			if (
				DateTime.fromJSDate(this.dates.min)
					.startOf('day')
					.toMillis() !==
				firstAvailableCalendarDate.startOf('day').toMillis()
			) {
				this.dates.min = firstAvailableCalendarDate.toJSDate();
				this.setFirstAvailableDate();
			}

			this.clearPreviousTimeSlotData();

			if (!overlay) {
				this.toggleDropdowns('guests');
			} else {
				this.toggleDropdowns('guestsOverlay');
			}
		},

		/**
		 * A new date has been selected.
		 *
		 * @param overlay
		 * @param event
		 */
		selectDate(overlay, event) {
			if (!event.isDisabled) {
				this.bookingData.date = event.date;

				// promotions do their own thing.
				if (!this.isValidPromotion && this.bookingData.location) {
					const selectedDay = DateTime.fromJSDate(
						this.bookingData.date
					)
						.toFormat('EEEE')
						.toLowerCase();
					this.times.minTime =
						this.bookingData.location[`${selectedDay}_open`];
					this.times.maxTime =
						this.bookingData.location[`${selectedDay}_close`];
					this.setTemporaryOpeningTimes();
					this.setTimeOverrides();
				}

				this.setTimeOptions();

				this.clearPreviousTimeSlotData();

				if (!overlay) {
					this.toggleDropdowns('date');
				} else {
					this.toggleDropdowns('dateOverlay');
				}
			}
		},

		/**
		 * A time has been selected
		 *
		 * @param i
		 * @param overlay
		 */
		selectTime(i, overlay = false) {
			this.times.userHasSelectedATime = true;

			this.clearPreviousTimeSlotData();

			this.bookingData.time = this.times.times[i];
			if (!overlay) {
				this.toggleDropdowns('time');
			} else {
				this.toggleDropdowns('timeOverlay');
			}
		},

		clearPreviousTimeSlotData() {
			this.quandooTimes = [];
			this.bookingData.quandooSlot = null;
			this.slotsShown = false;
			this.closeRestaurantsDisplayed = false;
		},

		/**
		 * A duration has been selcted
		 *
		 * @param duration
		 */
		selectDuration(duration) {
			this.bookingData.duration = duration;
			this.toggleDropdowns('durationOverlay');
		},

		/**
		 * Select a special occasion for the reservation
		 *
		 * @param specialOccasion
		 */
		selectSpecialOccasion(specialOccasion) {
			this.bookingData.personalDetails.specialOccasion = specialOccasion;
			this.toggleDropdowns('specialOccasionOverlay');
		},

		/**
		 * toggle dropdowns
		 * @param dropdown
		 */
		toggleDropdowns(dropdown) {
			if (dropdown === 'location') {
				this.dropdowns.location.visible =
					!this.dropdowns.location.visible;
				this.restaurantsFromLocationSearch = [];
			}
			if (dropdown === 'locationOverlay') {
				this.locationSearchString = '';
				this.overlayDropdowns.location.visible =
					!this.overlayDropdowns.location.visible;
				this.restaurantsFromLocationSearchSearchedLocation = [];
			}
			if (dropdown === 'guests') {
				this.dropdowns.guests.visible = !this.dropdowns.guests.visible;
			}
			if (dropdown === 'guestsOverlay') {
				this.overlayDropdowns.guests.visible =
					!this.overlayDropdowns.guests.visible;
			}
			if (dropdown === 'date') {
				this.dropdowns.date.visible = !this.dropdowns.date.visible;
			}
			if (dropdown === 'dateOverlay') {
				this.overlayDropdowns.date.visible =
					!this.overlayDropdowns.date.visible;
			}
			if (dropdown === 'time') {
				this.dropdowns.time.visible = !this.dropdowns.time.visible;
			}
			if (dropdown === 'timeOverlay') {
				this.overlayDropdowns.time.visible =
					!this.overlayDropdowns.time.visible;
			}
			if (dropdown === 'durationOverlay') {
				this.overlayDropdowns.duration.visible =
					!this.overlayDropdowns.duration.visible;
			}
			if (dropdown === 'specialOccasion') {
				this.overlayDropdowns.specialOccasion.visible =
					!this.overlayDropdowns.specialOccasion.visible;
			}
			if (
				this.dropdowns.location.visible ||
				this.overlayDropdowns.location.visible ||
				this.dropdowns.guests.visible ||
				this.overlayDropdowns.guests.visible ||
				this.overlayDropdowns.date.visible ||
				this.dropdowns.time.visible ||
				this.overlayDropdowns.time.visible ||
				this.overlayDropdowns.duration.visible ||
				this.overlayDropdowns.specialOccasion.visible
			) {
				this.trackReservationProgress(200);
			}
		},
		closeDropdownOnOutsideClick(event) {
			for (const ref in this.$refs) {
				if (ref === null || ref === 'undefined') {
					return;
				}
			}
			if (
				this.dropdowns.location.visible &&
				this.$refs.locationsDropdown !== event.target &&
				!this.$refs.locationsDropdown.contains(event.target) &&
				this.$refs.locationBox !== event.target &&
				!this.$refs.locationBox.contains(event.target)
			) {
				this.dropdowns.location.visible = false;
			}
			if (
				this.overlayDropdowns.location.visible &&
				(this.overlayOpen || this.open) &&
				this.$refs.locationOverlayDropdown !== event.target &&
				!this.$refs.locationOverlayDropdown?.contains(event.target) &&
				this.$refs.locationOverlayBox !== event.target &&
				!this.$refs.locationOverlayBox?.contains(event.target)
			) {
				this.overlayDropdowns.location.visible = false;
			}
			if (
				this.dropdowns.guests.visible &&
				this.$refs.guestsDropdown !== event.target &&
				!this.$refs.guestsDropdown.contains(event.target) &&
				this.$refs.guestsBox !== event.target &&
				!this.$refs.guestsBox.contains(event.target)
			) {
				this.dropdowns.guests.visible = false;
			}
			if (
				this.overlayDropdowns.guests.visible &&
				(this.overlayOpen || this.open) &&
				this.$refs.guestsOverlayDropdown !== event.target &&
				!this.$refs.guestsOverlayDropdown.contains(event.target) &&
				this.$refs.guestsOverlayBox !== event.target &&
				!this.$refs.guestsOverlayBox.contains(event.target)
			) {
				this.overlayDropdowns.guests.visible = false;
			}
			if (
				this.dropdowns.date.visible &&
				this.$refs.dateDropdown !== event.target &&
				!this.$refs.dateDropdown.contains(event.target) &&
				this.$refs.dateBox !== event.target &&
				!this.$refs.dateBox.contains(event.target)
			) {
				this.dropdowns.date.visible = false;
			}
			if (
				this.overlayDropdowns.date.visible &&
				(this.overlayOpen || this.open) &&
				this.$refs.dateOverlayDropdown !== event.target &&
				!this.$refs.dateOverlayDropdown.contains(event.target) &&
				this.$refs.dateOverlayBox !== event.target &&
				!this.$refs.dateOverlayBox.contains(event.target)
			) {
				this.overlayDropdowns.date.visible = false;
			}
			if (
				this.dropdowns.time.visible &&
				this.$refs.timeDropdown !== event.target &&
				!this.$refs.timeDropdown.contains(event.target) &&
				this.$refs.timeBox !== event.target &&
				!this.$refs.timeBox.contains(event.target)
			) {
				this.dropdowns.time.visible = false;
			}
			if (
				this.overlayDropdowns.time.visible &&
				(this.overlayOpen || this.open) &&
				this.$refs.timeOverlayDropdown !== event.target &&
				!this.$refs.timeOverlayDropdown.contains(event.target) &&
				this.$refs.timeOverlayBox !== event.target &&
				!this.$refs.timeOverlayBox.contains(event.target)
			) {
				this.overlayDropdowns.time.visible = false;
			}
			if (
				this.overlayDropdowns.duration.visible &&
				(this.overlayOpen || this.open) &&
				this.$refs.durationOverlayDropdown !== event.target &&
				!this.$refs.durationOverlayDropdown.contains(event.target) &&
				this.$refs.durationOverlayBox !== event.target &&
				!this.$refs.durationOverlayBox.contains(event.target)
			) {
				this.overlayDropdowns.duration.visible = false;
			}
			if (
				this.overlayDropdowns.specialOccasion.visible &&
				(this.overlayOpen || this.open) &&
				this.$refs.specialOccasionOverlayDropdown !== event.target &&
				!this.$refs.specialOccasionOverlayDropdown.contains(
					event.target
				) &&
				this.$refs.specialOccasionOverlayBox !== event.target &&
				!this.$refs.specialOccasionOverlayBox.contains(event.target)
			) {
				this.overlayDropdowns.specialOccasion.visible = false;
			}
		},

		/**
		 * what do we do after a slot has been selected
		 */
		async continueAfterSlotChosen() {
			this.error.visible = false;

			if (!this.currentTimeslotExperiences.length) {
				await this.lockReservation();
				if (this.error.visible) {
					return;
				}

				this.goToScreen(this.overlayScreens.personal_details, false);
				return;
			}

			this.goToScreen(this.overlayScreens.experiences);
			this.toggleSplash('hide');
		},

		async lockReservation() {
			if (!this.isRestaurantInOpenTable || this.isReservationTokenValid) {
				return;
			}

			await this.releaseExistingTimeSlot();

			this.toggleSplash('show');

			const { guests } = this.bookingData;

			const dateTimeFormatted =
				this.reservationDateTime.toFormat("yyyy-MM-dd'T'HH:mm");

			const reservationData = {
				party_size: guests.value,
				date_time: dateTimeFormatted,
				restaurant_id: this.restaurantIdInteger,
			};

			if (this.bookingData.experience) {
				const { id, version } = this.bookingData.experience;
				reservationData.experience = {
					id,
					version,
				};
			}

			try {
				const response = await openTable.lockReservation(
					reservationData
				);

				if (response.status !== 200) {
					throw new Error('Lock reservation failed');
				}

				const { reservation_token, expires_at } = await response.json();

				this.bookingData.tokenData = {
					reservationData,
					token: reservation_token,
					tokenExpirationDateTime: expires_at,
				};

				this.toggleSplash('hide');
			} catch (error) {
				// TODO error shows only once, change behaviour when decided
				this.toggleSplash('hide');
				this.error.visible = true;
				this.error.title = `Sorry there has been an error. ${error.message}`;
				this.error.message =
					'Please contact the restaurant to make this booking';
				this.error.callback = () => {
					this.resetWidgetAfterError();
					this.slotsShown = false;
					this.goToScreen(this.overlayScreens.guests_date_time);
				};
			}
		},
		async releaseExistingTimeSlot() {
			if (!this.bookingData.tokenData.token) {
				return;
			}

			const {
				token,
				reservationData: { restaurant_id },
			} = this.bookingData.tokenData;

			await openTable.releaseReservation({
				token,
				rid: restaurant_id,
			});

			this.bookingData.tokenData = {
				reservationData: null,
				token: null,
				tokenExpirationDateTime: null,
			};
		},

		/**
		 * Close the overlay
		 */
		closeOverlay() {
			this.releaseExistingTimeSlot();

			this.overlayOpen = false;
			if (this.open) {
				this.$emit('closeBookings');
			}
			this.$router.push(this.fromPage);
			this.resetBookingWidget();
			this.goToScreen(this.overlayScreens.location, false, false);
		},

		/**
		 * Go to a specific Screen
		 *
		 * @param screen
		 * @param delay
		 * @param openOverlay
		 * @param updateUrl
		 */
		goToScreen(
			screen,
			delay = false,
			openOverlay = true,
			updateUrl = true
		) {
			if (!delay) {
				this.activeOverlayScreen = screen;
			} else {
				setTimeout(() => {
					this.activeOverlayScreen = screen;
				}, 750);
			}

			if (screen === this.overlayScreens.location) {
				this.bookingData.location = null;
			}

			if (screen === this.overlayScreens.guests_date_time) {
				this.populateGuestDropdown();
			}

			if (!this.overlayOpen && openOverlay) {
				this.overlayOpen = true;
			}

			if (updateUrl) {
				history.pushState(
					history.state,
					null,
					`${window.location.protocol}//${
						window.location.host
					}${this.$route.fullPath.replace(
						this.$route.hash,
						''
					)}#step${screen}`
				);
			}
			this.currentStep = screen;
			// scroll to top
			document.body.scrollTop = document.documentElement.scrollTop = 0;
		},

		/**
		 * called when the phone number country changes
		 * @param event
		 */
		setCountry(event) {
			this.bookingData.personalDetails.country = event.iso2;
		},
		findFirstAvailableFromDateRange() {
			const start = DateTime.fromJSDate(new Date(this.dates.min)).startOf(
				'day'
			);
			const end = DateTime.fromJSDate(new Date(this.dates.max));
			const dateFormat = 'dd-MM-yyyy';

			const disabledDates = Object.values(this.dates.disabledDates).map(
				(date) =>
					DateTime.fromJSDate(new Date(date)).toFormat(dateFormat)
			);

			for (let day = start; day <= end; day = day.plus({ day: 1 })) {
				const formattedDay = day.toFormat(dateFormat);
				if (
					!disabledDates?.includes(formattedDay) &&
					!this.dates.disabledWeekDays?.includes(day.weekday)
				) {
					return day.toJSDate();
				}
			}

			return null;
		},

		/**
		 * set the first available date to be selected from the date dropdown
		 * Look at the currently elected date and change it if it's no longer valid.
		 */
		setFirstAvailableDate() {
			const now = DateTime.now();
			const lastTime = DateTime.fromFormat(this.times.maxTime, 'HH:mm');
			const dateMin = DateTime.fromJSDate(this.dates.min);

			// if the min date is today, and we're past the last slot, add one day to the cal.
			if (
				now > lastTime &&
				dateMin.toFormat('YYYY-mm-dd') === now.toFormat('YYYY-mm-dd')
			) {
				this.dates.min = dateMin.plus({ day: 1 }).toJSDate();
			}

			let firstAvailableDate = this.dates.min;

			// When we have a specificDate
			if (
				this.promoCodeLatestDateRestrictionType ===
				this.promotionRestrictionSpecificDate
			) {
				const sortDates = (firstDate, secondDate) =>
					DateTime.fromISO(firstDate.date).toFormat('yyyyMMdd') -
					DateTime.fromISO(secondDate.date).toFormat('yyyyMMdd');
				const sortedDates =
					this.promotion.book_a_table_date_inclusions.sort(sortDates);
				firstAvailableDate = DateTime.fromISO(
					sortedDates[0].date
				).toJSDate();
			} else if (
				!this.incorrectlySetPromotion &&
				this.promoCodeLatestDateRestrictionType ===
					this.promotionRestrictionDateRange &&
				(this.dates.disabledDates.length ||
					this.dates.disabledWeekDays.length)
			) {
				firstAvailableDate = this.findFirstAvailableFromDateRange();

				if (!firstAvailableDate) {
					this.incorrectlySetPromotion = true;
					console.log(
						'Incorrect promotion, could not get first available date!'
					);

					this.resetBookingWidget();
					this.goToScreen(this.overlayScreens.location, false, true);

					return;
				}
			} else {
				firstAvailableDate = this.findFirstAvailableFromDateRange();
			}

			// If we already have a date, and it's after the min date, use that. otherwise use the min date
			if (
				this.bookingData.date &&
				this.bookingData.date > firstAvailableDate
			) {
				this.selectDate(false, { date: this.bookingData.date });
			} else {
				this.selectDate(false, { date: firstAvailableDate });
			}
		},
		setTemporaryClosedDates() {
			this.dates.disabledDates =
				this.bookingData.location.temporaryOpenTimes
					.filter((time) => !time.open)
					.map((time) => DateTime.fromISO(time.date).toJSDate());
		},
		setTemporaryOpeningTimes() {
			if (this.bookingData.location.temporaryOpenTimes.length > 0) {
				this.bookingData.location.temporaryOpenTimes.forEach((time) => {
					if (
						time.open &&
						DateTime.fromISO(time.date).hasSame(
							DateTime.fromJSDate(this.bookingData.date),
							'day'
						)
					) {
						this.times.minTime = time.open_time;
						this.times.maxTime = time.close_time;
					}
				});
			}
		},
		setTimeOverrides() {
			const date = DateTime.fromJSDate(this.bookingData.date).toISODate();
			const overrides = this.bookingData.location.timeOverrides.BR;
			const cardRequiredTimes =
				this.bookingData.location.timeOverrides.CC;
			this.times.overrides = [];
			this.times.cardRequiredTimes = [];

			if (date in overrides) {
				overrides[date].forEach((override) => {
					this.times.overrides.push({
						guests: parseInt(override.covers, RADIX),
						start: DateTime.fromSeconds(override.start),
						end: DateTime.fromSeconds(override.end),
					});
				});
			}

			if (date in cardRequiredTimes) {
				cardRequiredTimes[date].forEach((override) => {
					this.times.cardRequiredTimes.push({
						chargePerHead: override.charge_per_head,
						lateCancellationPeriodHours:
							override.late_cancellation_period_hours,
						guests: parseInt(override.covers, RADIX),
						start: DateTime.fromSeconds(override.start),
						end: DateTime.fromSeconds(override.end),
					});
				});
			}
		},
		addLeadingZeroToTime(time) {
			// return if time e.g. 09:00
			if (time.length >= 5) return time;

			// if time e.g. 9:00 add the 0 to make 09:00
			const splitTime = time.split(':');

			// add leading 0's
			if (splitTime[0].length < 2) {
				splitTime[0] = `0${splitTime[0]}`;
			}
			if (splitTime[1].length < 2) {
				splitTime[1] = `0${splitTime[1]}`;
			}

			const leadingZeroTime = `${splitTime[0]}:${splitTime[1]}`;

			return leadingZeroTime;
		},
		setTimeOptions() {
			this.times.times = [];

			const now = DateTime.now();
			const bookingDate = DateTime.fromJSDate(this.bookingData.date);
			const lastTime = DateTime.fromFormat(this.times.maxTime, 'HH:mm');
			const preferredTime = DateTime.fromFormat('19:00', 'HH:mm');

			let time = this.addLeadingZeroToTime(this.times.minTime);

			// is it today?
			if (
				bookingDate.toFormat('YYYY-mm-dd') ===
				now.toFormat('YYYY-mm-dd')
			) {
				if (now > preferredTime && now < lastTime) {
					// round to the next 30
					const remainder = 30 - (now.minute % 30);
					time = now.plus({ minute: remainder }).toFormat('HH:mm');
				}
			}

			const blockedTimes = [];
			if (!this.isValidPromotion) {
				if (this.times.overrides.length > 0) {
					this.times.overrides.forEach((override) => {
						if (this.bookingData.guests?.value >= override.guests) {
							let blockedTime = override.start.toFormat('HH:mm');
							const endTime = override.end.toFormat('HH:mm');
							while (blockedTime !== endTime) {
								blockedTimes.push(blockedTime);
								blockedTime = DateTime.fromFormat(
									blockedTime,
									'HH:mm'
								)
									.plus({ minutes: 15 })
									.toFormat('HH:mm');
							}
							blockedTimes.push(endTime);
						}
					});
				}
			} else if (
				this.isValidPromotion &&
				this.promotion.date_restrictions === 'multi'
			) {
				const times = this.promotion.book_a_table_availability.filter(
					(d) =>
						d.availabilityDate ===
						DateTime.fromJSDate(this.bookingData.date).toISODate()
				)[0].availabilityTimes;
				if (times) {
					const keepTimes = [];
					times.forEach((t) => {
						let tempTime = this.times.minTime;
						while (tempTime !== this.times.maxTime) {
							const tempTimeOnlyObj = DateTime.fromFormat(
								tempTime,
								'HH:mm'
							);
							const tempTimeObj = DateTime.now().set({
								hour: tempTimeOnlyObj.hour,
								minute: tempTimeOnlyObj.minute,
							});
							const minTimeOnlyObj = DateTime.fromFormat(
								t.book_from_time,
								'HH:mm'
							);
							const minTimeObj = DateTime.now().set({
								hour: minTimeOnlyObj.hour,
								minute: minTimeOnlyObj.minute,
							});
							const maxTimeOnlyObj = DateTime.fromFormat(
								t.book_to_time,
								'HH:mm'
							);
							const maxTimeObj = DateTime.now().set({
								hour: maxTimeOnlyObj.hour,
								minute: maxTimeOnlyObj.minute,
							});
							if (
								tempTimeObj >= minTimeObj &&
								tempTimeObj <= maxTimeObj
							) {
								keepTimes.push(
									tempTimeOnlyObj.toFormat('HH:mm')
								);
							}
							tempTime = tempTimeOnlyObj
								.plus({ minutes: 30 })
								.toFormat('HH:mm');
						}
					});
					if (keepTimes) {
						let tempTime = this.times.minTime;
						while (tempTime !== this.times.maxTime) {
							if (!keepTimes.includes(tempTime)) {
								blockedTimes.push(tempTime);
							}
							tempTime = DateTime.fromFormat(tempTime, 'HH:mm')
								.plus({ minutes: 30 })
								.toFormat('HH:mm');
						}
						if (!keepTimes.includes(this.times.maxTime)) {
							blockedTimes.push(this.times.maxTime);
						}
					}
				}
			}

			while (time !== this.times.maxTime) {
				if (!blockedTimes.includes(time)) {
					this.times.times.push(time);
				}
				time = DateTime.fromFormat(time, 'HH:mm')
					.plus({ minutes: 15 })
					.toFormat('HH:mm');
			}
			if (!blockedTimes.includes(this.times.maxTime)) {
				this.times.times.push(this.times.maxTime);
			}

			if (
				!this.bookingData.time ||
				(!this.times.userHasSelectedATime && !this.isAmend)
			) {
				if (this.times.times.includes('19:00')) {
					this.bookingData.time = '19:00';
				} else {
					this.bookingData.time = this.times.times[0];
				}
			}

			// if we have a time, and it's no longer available
			// use the fist available time.
			if (
				this.bookingData.time &&
				!this.times.times.includes(this.bookingData.time)
			) {
				this.bookingData.time = this.times.times[0];
			}
		},

		/**
		 * populate all available options in the guest dropdown.
		 */
		populateGuestDropdown() {
			// first clear the dropdown
			this.guests.guests = [];

			// When in Amend Mode - they cannot switch to an enquiry
			const maxGuests = this.isAmend
				? this.guests.restaurantMax
				: this.guests.maxGuests;

			for (let i = this.guests.minGuests; i <= maxGuests; i++) {
				this.guests.guests.push({
					value: i,
					label: i === 1 ? '1 person' : `${i} people`,
				});
			}
		},

		resetBookingWidget() {
			this.mode = this.modes.reservation;
			this.times.userHasSelectedATime = false;
			this.reservationStepTrackingProgress = null;
			this.bookingData = {
				reservationId: null,
				location: null,
				guests: null,
				date: null,
				time: null,
				duration: null,
				quandooSlot: null,
				personalDetails: {
					firstName: null,
					lastName: null,
					email: null,
					phone: null,
					specialOccasion: null,
					specialRequests: null,
					country: null,
					consent: null,
				},
				tokenData: {
					reservationData: null,
					token: null,
					tokenExpirationDateTime: null,
				},
			};
			this.dates.min = DateTime.now().toJSDate();
			this.dates.max = DateTime.now().plus({ years: 1 }).toJSDate();
		},

		resetWidgetAfterError() {
			this.mode = this.modes.reservation;
			this.times.userHasSelectedATime = false;
			this.reservationStepTrackingProgress = null;
			this.bookingData.reservationId = null;
			this.bookingData.quandooSlot = null;
		},

		findTablesFromBottomWidget() {
			this.$router.push('/book-a-table');
			setTimeout(() => {
				this.getAvailableTimeSlotsForRestaurant();
			}, 500);
		},

		getAvailableTimeSlotsForRestaurant() {
			this.toggleSplash(
				'show',
				`Searching for tables at Côte ${this.bookingData.location.post_title}`
			);
			const simpleDate = DateTime.fromJSDate(this.bookingData.date);

			// Get the experiences for the restaurant by ID
			openTable
				.getExperiences(this.restaurantIdInteger)
				.then((r) => r.json())
				.then((data) => {
					const experiences = data.map((experience) => ({
						id: experience.experience_id,
						version: experience.version,
						type: experience.type,
						title: experience.name,
						description: experience.description,
						price: experience.price_per_cover?.min_unit_amount
							? `£${(
									experience.price_per_cover.min_unit_amount /
									100
							  ).toFixed(2)}`
							: '',
						pre_payment_required:
							experience.price_per_cover?.pre_payment_required,
					}));
					this.restaurantExperiences = experiences;
				});

			wordpress
				.getAvailableTimesForReservation(
					this.restaurantIdInteger,
					this.bookingData.guests.value,
					simpleDate.toISODate(),
					this.bookingData.time,
					this.isRestaurantInOpenTable
				)
				.then((r) => r.json())
				.then((data) => {
					if (!data.timeSlots) {
						throw new Error(data.message);
					}

					// If there are time slots
					if (data.timeSlots.length > 0) {
						this.determineAvailableSlots(
							data.timeSlots,
							simpleDate,
							data.close
						);
						this.slotsShown = true;
					} else {
						// If no slots have been passed, show close
						this.requestedTimeObtained = false;
						this.displayCloseRestaurants(data.close, simpleDate);
					}

					this.trackReservationSearch();
					this.trackReservationProgress(300);
					this.toggleSplash('hide');
				})
				.catch((error) => {
					if (this.isRestaurantInOpenTable) {
						window
							.open(
								`https://www.opentable.co.uk/restref/client/?restref=${this.restaurantIdInteger}`,
								'_blank'
							)
							.focus();

						this.toggleSplash('hide');
						this.error.visible = true;
						this.error.title = 'Oops';
						this.error.message =
							"We're sorry we're having a little difficulty making your reservation. We're redirecting you to Opentable where you'll be able to complete your reservation.";
						return;
					}

					console.log(error);
					this.toggleSplash('hide');
					this.error.visible = true;
					this.error.title = 'Error';
					this.error.message =
						"There's been an error, please refresh and try again";
				});
		},
		determineAvailableSlots(timeSlots, requestedDate, closeRestaurants) {
			const requestedTimeObj = DateTime.fromFormat(
				this.bookingData.time,
				'HH:mm'
			);

			const requestedTime = DateTime.fromJSDate(
				this.bookingData.date
			).set({
				hour: requestedTimeObj.hour,
				minute: requestedTimeObj.minute,
			});

			const minTime = DateTime.fromFormat(
				this.times.minTime,
				'HH:mm'
			).set({
				year: requestedDate.year,
				month: requestedDate.month,
				day: requestedDate.day,
			});

			let maxTime = DateTime.fromFormat(this.times.maxTime, 'HH:mm').set({
				year: requestedDate.year,
				month: requestedDate.month,
				day: requestedDate.day,
			});

			const startTime = requestedDate
				.set({
					hour: requestedTime.hour,
					minute: requestedTime.minute,
				})
				.minus({ minutes: this.timeSlotsConfig.threshold });

			const endTime = requestedDate
				.set({
					hour: requestedTime.hour,
					minute: requestedTime.minute,
				})
				.plus({ minutes: this.timeSlotsConfig.threshold });

			const minStartTime = requestedDate
				.set({
					hour: requestedTime.hour,
					minute: requestedTime.minute,
				})
				.minus({ minutes: this.timeSlotsConfig.before });

			const maxEndTime = requestedDate
				.set({
					hour: requestedTime.hour,
					minute: requestedTime.minute,
				})
				.plus({ minutes: this.timeSlotsConfig.after });

			let found = 0;
			let timesBefore = [];
			let timesAfter = [];
			this.quandooTimes = [];

			if (
				this.times.maxTime === '00:00' ||
				this.times.maxTime === '01:00' ||
				this.times.maxTime === '02:00' ||
				this.times.maxTime === '03:00'
			) {
				maxTime = maxTime.plus({ days: 1 });
			}
			timeSlots.forEach((slot) => {
				const slotTime = DateTime.fromISO(slot.dateTime);
				let { cardRequired } = slot;
				const charge_per_head = slot.charge_per_head
					? slot.charge_per_head
					: 0;
				const { late_cancellation_period_hours } = slot;

				let timeBlocked = false;
				if (this.times.overrides.length > 0) {
					this.times.overrides.forEach((override) => {
						if (this.bookingData.guests?.value >= override.guests) {
							if (
								slotTime >= override.start &&
								slotTime <= override.end
							) {
								timeBlocked = true;
							}
						}
					});
				}

				if (timeBlocked) {
					return;
				}

				const timeToStart = slotTime
					.diff(DateTime.now(), 'minutes')
					.toObject().minutes;
				if (
					timeToStart <
					this.$store.getters.getBookingWidgetConfig(
						'blockInNextMinutes'
					)
				) {
					return;
				}

				if (cardRequired && timeToStart < this.timeSlotsConfig.cutOff) {
					cardRequired = false;
				}

				// Find the times before
				if (
					slotTime >= minStartTime &&
					slotTime < requestedTime &&
					slotTime >= minTime &&
					slotTime <= maxTime
				) {
					timesBefore.push({
						time: slotTime.toFormat('h:mm a'),
						slotTime,
						cc: cardRequired,
						charge_per_head,
						late_cancellation_period_hours,
						po: slot.preOrder,
						vaultSettingId: slot.vaultSettingId,
						experiences: slot.experiences,
						availabilityTypes: slot.availabilityType,
					});
				}
				// Find the times after
				if (
					slotTime >= requestedTime &&
					slotTime <= maxEndTime &&
					slotTime >= minTime &&
					slotTime <= maxTime
				) {
					timesAfter.push({
						time: slotTime.toFormat('h:mm a'),
						slotTime,
						cc: cardRequired,
						charge_per_head,
						late_cancellation_period_hours,
						po: slot.preOrder,
						vaultSettingId: slot.vaultSettingId,
						experiences: slot.experiences,
						availabilityTypes: slot.availabilityType,
					});
				}
			});

			let beforeSlots =
				timesBefore.length < this.timeSlotsConfig.maximumBefore
					? timesBefore.length
					: this.timeSlotsConfig.maximumBefore;
			let afterSlots = this.timeSlotsConfig.maximum - beforeSlots;

			// for late bookings, increase number of 'before' slots to retain a grid of 12 slots total
			if (timesAfter.length < afterSlots) {
				beforeSlots = this.timeSlotsConfig.maximum - timesAfter.length;
				afterSlots = timesAfter.length;
			}

			if (beforeSlots > 0) {
				timesBefore = timesBefore.slice(
					timesBefore.length - beforeSlots
				);
			}

			if (timesAfter.length > 0) {
				timesAfter = timesAfter.slice(0, afterSlots);
			}

			this.quandooTimes = timesBefore.concat(timesAfter);
			this.quandooTimes.forEach((time) => {
				if (time.slotTime >= startTime && time.slotTime <= endTime) {
					this.currentTimeslotExperiences = time.experiences.map(
						(experience) => experience.id
					);
					found++;
				}
			});
			this.requestedTimeObtained = found > 0;

			if (this.requestedTimeObtained && this.quandooTimes.length > 0) {
				const requestedQuandooTimeSlot = this.quandooTimes.find(
					(time) =>
						time.slotTime.toFormat('HH:mm') ===
						requestedTimeObj.toFormat('HH:mm')
				);

				if (!requestedQuandooTimeSlot) {
					// For some reason this.requestedTimeObtained doesn't always work, so this is a quickfix
					this.requestedTimeObtained = false;
					const simpleDate = DateTime.fromJSDate(
						this.bookingData.date
					);
					this.displayCloseRestaurants(closeRestaurants, simpleDate);
				} else {
					this.selectQuandooTimeSlot(
						requestedQuandooTimeSlot,
						requestedTimeObj.toFormat('HH:mm')
					);
					this.continueAfterSlotChosen();
				}
			} else {
				const simpleDate = DateTime.fromJSDate(this.bookingData.date);
				this.requestedTimeObtained = false;
				this.displayCloseRestaurants(closeRestaurants, simpleDate);
			}

			this.slotsWithinTwoHours = this.quandooTimes.length > 0;
		},
		displayCloseRestaurants(closeRestaurants, requestedDate) {
			this.closeRestaurantsDisplayed = true;
			if (closeRestaurants.length > 0) {
				this.closeRestaurantsExist = true;
				this.closeRestaurants = closeRestaurants;
				this.toggleSplash('hide');
			}
		},
		searchSlotsForCloseRestaurant(restaurantId) {
			// Set the restaurant
			this.selectLocation(restaurantId, false);
			// Hide the 'close restaurants'
			this.closeRestaurantsDisplayed = false;
			// Do the search
			this.getAvailableTimeSlotsForRestaurant();
		},
		selectQuandooTimeSlot(time, formatTime) {
			this.currentTimeslotExperiences = time.experiences.map(
				(experience) => experience.id
			);
			this.availabilityTypes = time.availabilityTypes;
			this.requestedTimeObtained = true;
			this.bookingData.quandooSlot = time;
			this.bookingData.time = formatTime;
			if (this.stripeCardRequiredForSelectedSlot) {
				this.getRestaurantPolicies();
			}
			this.trackReservationProgress(400);
		},
		requestAmendBookingLink(e) {
			if (this.$refs.requestAmendLink[0].checkValidity()) {
				e.preventDefault();
				this.goToScreen(this.overlayScreens.amend_booking_confirm);
				// TODO: Send request link
			}
		},
		requestCancelBookingLink(e) {
			if (this.$refs.requestAmendLink[0].checkValidity()) {
				e.preventDefault();
				this.goToScreen(
					this.overlayScreens.cancel_booking_request_confirm
				);
				// TODO: Send request link
			}
		},

		async sendManageBookingsEmail() {
			await exponea.identify(
				this.bookingData.personalDetails.email.toLowerCase().trim()
			);
			const hashedEmail = btoa(
				this.bookingData.personalDetails.email.toLowerCase().trim()
			);
			exponea.track('request_manage_booking', {
				hashedUserEmail: hashedEmail,
			});
			this.manageBookingRequestSubmitted = true;
		},

		async getExistingBookingsFromExponea(email) {
			wordpress
				.exponeaGetCustomerEvents({ email })
				.then((r) => r.json())
				.then((resp) => {
					if (!resp?.success) {
						throw new Error('exponeaGetCustomerEvents failed');
					}
					const allEvents = resp.customer_events;

					// These are the reservation_statuses that signify whether the event is active or not
					const activeBookingStatuses = [
						'AUTOMATIC_CONFIRMED',
						'CONFIRMED',
						'NOTIFIED',
						'RECONFIRMED',
					];

					// There will be two events if the customer cancels, we need to make sure a cancel instance doesn't exist.
					const cancelledEvents = allEvents.filter(
						(event) =>
							event.properties.reservation_status ===
							'CUSTOMER_CANCELED'
					);

					// Only show events with active statuses that are in the future
					const futureEvents = allEvents.filter(
						(event) =>
							activeBookingStatuses.includes(
								event.properties.reservation_status
							) &&
							event.properties.actual_datetime >
								new Date().getTime() / 1000
					);

					// Remove all future events that are in the cancelled events array
					const nonCancelledFutureEvents = futureEvents.filter(
						(futureEvent) =>
							!cancelledEvents.some(
								(cancelledEvent) =>
									cancelledEvent.properties.reservation_id ===
									futureEvent.properties.reservation_id
							)
					);

					// Check for duplicates, show the most recent
					const uniqueMostRecentReservationEvents = [];

					nonCancelledFutureEvents.forEach((event) => {
						// If the UMRRE array already contains this event
						const isDuplicate =
							uniqueMostRecentReservationEvents.find(
								(UMRRE) =>
									UMRRE.properties.reservation_id ===
									event.properties.reservation_id
							);

						if (!isDuplicate) {
							// If not in the UMRRE array, add the event
							uniqueMostRecentReservationEvents.push(event);
						} else {
							// If the event ID already exists in the UMRRE array, we want to check to see which event has the latest timestamp
							const isDuplicateTimestamp = isDuplicate.timestamp;
							const eventTimestamp = event.timestamp;

							if (eventTimestamp > isDuplicateTimestamp) {
								// If the event is newer thatn the existing event
								// Find the index of the existing event
								const indexOfDuplicate =
									uniqueMostRecentReservationEvents
										.map((e) => e.properties.reservation_id)
										.indexOf(
											event.properties.reservation_id
										);
								// Remove it
								uniqueMostRecentReservationEvents.splice(
									indexOfDuplicate,
									1
								);
								// And replace it with the new event
								uniqueMostRecentReservationEvents.push(event);
							}
						}
					});
					this.existingBookingEvents =
						uniqueMostRecentReservationEvents;
				});
		},
		async getRestaurantPolicies() {
			// Set the default message
			this.policyMessages = [
				'Please note that we require a £10 per person holding deposit to secure your reservation. Your card will not be debited unless you do not arrive for your booking without notifying us 72 hours in advance.',
			];

			const formattedDate = DateTime.fromJSDate(
				this.bookingData.date
			).toFormat('yyyy-MM-dd');
			openTable
				.getPolicies(
					this.bookingData.location.open_table_id,
					formattedDate,
					this.bookingData.time,
					this.bookingData.guests.value
				)
				.then((r) => r.json()) // parse response as JSON
				.then((data) => {
					const dayPolicies = data.Policies.filter(
						(policy) => policy.Type === 'Day'
					) // filter policies by Type
						.map((policy) => policy.Message); // map to Message

					if (dayPolicies.length > 0) {
						// Replace the default message with the policies
						this.policyMessages = dayPolicies;
					}
				})
				.catch((error) => {
					console.error('Error fetching policies:', error);
				});
		},
		/**
		 * Complete a reservation
		 * @param e
		 */
		submitReservation(e) {
			this.toggleSplash('show', 'Submitting your request');
			if (this.$refs.bookingForm[0].checkValidity()) {
				e.preventDefault();
				if (this.mode !== this.modes.enquiry) {
					const simpleDate = DateTime.fromJSDate(
						this.bookingData.date
					);

					if (
						this.isRestaurantInOpenTable &&
						this.isReservationTokenValid
					) {
						this.createOpenTableReservation();
						return;
					}

					wordpress
						.getAvailableTimesForReservation(
							this.restaurantIdInteger,
							this.bookingData.guests.value,
							simpleDate.toISODate(),
							this.bookingData.time,
							this.isRestaurantInOpenTable
						)
						.then((r) => r.json())
						.then(async (resp) => {
							let stillAvailable = false;
							let creditCardVaultSettingsId = false;

							const requestedTime = this.reservationDateTime;

							resp.timeSlots.every((slot) => {
								const slotTime = DateTime.fromISO(
									slot.dateTime
								);
								if (
									slotTime.toMillis() ===
									requestedTime.toMillis()
								) {
									stillAvailable = true;
									if (slot.vaultSettingId !== false) {
										creditCardVaultSettingsId =
											slot.vaultSettingId;
									}
									return false;
								}
								return true;
							});

							if (!stillAvailable) {
								this.error.title = 'Table No Longer Available';
								this.error.message =
									'Sorry this time is no longer available. Please try searching for another time';
								this.error.callback = () => {
									this.quandooTimes = [];
									this.bookingData.time = null;
									this.bookingData.quandooSlot = null;
									this.slotsShown = false;
									this.goToScreen(
										this.overlayScreens.guests_date_time
									);
									this.toggleSplash('hide');
								};
							} else {
								if (this.mode === this.modes.reservation) {
									await this.lockReservation();
									if (this.error.visible) {
										return;
									}

									this.createReservation(
										creditCardVaultSettingsId
									);
								}
								if (this.mode === this.modes.amend) {
									this.amendReservation(requestedTime);
								}
							}
						});
				} else if (this.mode === this.modes.enquiry) {
					this.createEnquiry();
				}
			}
		},
		// Create a reservation with card details attached
		handleCardPayment(data) {
			this.stripeTokenData = data;
			this.createOpenTableReservation();
		},
		toggleSplash(showHide = 'hide', placeholderText = '') {
			if (showHide === 'hide') {
				this.showPlaceholder = false;
			} else if (showHide === 'show') {
				this.showPlaceholder = true;
			} else {
				this.showPlaceholder = !this.showPlaceholder;
			}
			this.placeholderText = placeholderText;
		},

		/**
		 * Returns a luxon DateTime in GMT to stop pesky timezone issues
		 * @param {dateTime} date luxon dateTime object
		 * @param {string} time time in fmt HH:mm
		 */
		makeDateTimeGMT(date, time) {
			const specificTime = DateTime.fromFormat(time, 'HH:mm', {
				zone: 'Europe/London',
				setZone: true,
				locale: 'en-GB',
			});

			const requestedTimeInGmt = DateTime.local(
				date.year,
				date.month,
				date.day,
				specificTime.hour,
				specificTime.minute,
				specificTime.second,
				{ zone: 'Europe/London' }
			)
				.setZone('Europe/London', { keepLocalTime: false })
				.setLocale('en-GB');

			return requestedTimeInGmt;
		},

		/**
		 * create a reservation
		 *
		 * @param creditCardVaultSettingsId
		 */
		createReservation(creditCardVaultSettingsId) {
			if (this.isRestaurantInOpenTable) {
				this.createOpenTableReservation();
			} else {
				this.createQuandooReservation(creditCardVaultSettingsId);
			}
		},
		createQuandooReservation(creditCardVaultSettingsId) {
			let extraInfo = '';

			if (this.bookingData.personalDetails.specialOccasion) {
				extraInfo += ` **SPECIAL_OCCASION** ${this.bookingData.personalDetails.specialOccasion} **END_SPECIAL_OCCASION**`;
			}

			if (this.bookingData.personalDetails?.specialRequests) {
				extraInfo += ` **SPECIAL_REQUEST** ${this.bookingData.personalDetails.specialRequests} **END_SPECIAL_REQUEST**`;
			}

			if (
				this.isValidPromotion &&
				this.promotion.promo_code &&
				this.promotion.offer_text
			) {
				extraInfo += ` **PROMOCODE** ${
					this.promotion.promo_code
				} **ENDPROMOCODE** **PROMOINFO** ${
					this.promotion.offer_text
				} **ENDPROMOINFO** **PROMOENDTIME** ${DateTime.fromJSDate(
					this.dates.max
				)} **ENDPROMOENDTIME** **PROMOTAGS** ${
					this.promotion.tags
				} **ENDPROMOTAGS**`;
			}
			// Recreate the time in GMT as there is some weirdness going on with timeZones.
			const requestedTimeInGmt = this.makeDateTimeGMT(
				this.reservationDateTime,
				this.bookingData.time
			);

			const reservationData = {
				reservation: {
					id: this.uuidV4(),
					merchantId: this.bookingData.location.quandoo_id,
					capacity: this.bookingData.guests.value,
					dateTime: `${requestedTimeInGmt.toFormat(
						'yyyy-MM-dd'
					)}T${requestedTimeInGmt.toFormat('HH:mm:ssZZ')}`,
					extraInfo,
					offerText: this.isValidPromotion
						? this.promotion.offer_text
						: '',
					offerCodeExpiry: this.isValidPromotion
						? this.promotion.book_a_table_max_date
						: '',
				},
				customer: {
					firstName: this.bookingData.personalDetails.firstName,
					lastName: this.bookingData.personalDetails.lastName,
					emailAddress: this.bookingData.personalDetails.email,
					phoneNumber: this.bookingData.personalDetails.phone,
					locale: `en_${this.bookingData.personalDetails.country}`,
					country: this.bookingData.personalDetails.country,
					birthDate: this.birthDayToUnixIntegerOrNull,
				},
				tracking: {
					agent: {
						id: 85,
					},
				},
			};

			if (creditCardVaultSettingsId) {
				reservationData.reservation.creditCardVaultSettingsId =
					creditCardVaultSettingsId;
			}

			wordpress.errorLog({
				eID: 'preQuandoCreateRes',
				metaData: {
					reservationData,
				},
			});

			wordpress
				.quandooCreateReservation({ data: reservationData })
				.then((r) => r.json())
				.then((resp) => {
					wordpress.errorLog({
						eID: 'quandooResponse',
						metaData: {
							quandooResponse: resp.body,
						},
					});

					const email = resp?.customer?.email;

					if (email) {
						if (email.includes('@cote-testing.co.uk')) {
							// Testing!

							const emailStart = email.split('@')[0];

							switch (emailStart) {
								// no-tables@cote-testing.co.uk
								case 'no-tables':
									throw new Error(
										'RESERVATION_NO_TABLES_AVAILABLE'
									);
								case 'no-tables-1':
									throw new Error(
										'RESERVATION_NO_TABLES_AVAILABLE'
									);
								case 'no-tables-2':
									throw new Error(
										'RESERVATION_NO_TABLES_AVAILABLE'
									);
								case 'no-tables-3':
									throw new Error(
										'RESERVATION_NO_TABLES_AVAILABLE'
									);
								case 'no-tables-4':
									throw new Error(
										'RESERVATION_NO_TABLES_AVAILABLE'
									);
								case 'no-tables-5':
									throw new Error(
										'RESERVATION_NO_TABLES_AVAILABLE'
									);
								case 'no-tables-6':
									throw new Error(
										'RESERVATION_NO_TABLES_AVAILABLE'
									);
								case 'invalid-phone':
									throw new Error(
										'CUSTOMER_PHONE_NUMBER_INVALID'
									);
								case 'invalid-phone-1':
									throw new Error(
										'CUSTOMER_PHONE_NUMBER_INVALID'
									);
								case 'invalid-phone-2':
									throw new Error(
										'CUSTOMER_PHONE_NUMBER_INVALID'
									);
								case 'invalid-phone-3':
									throw new Error(
										'CUSTOMER_PHONE_NUMBER_INVALID'
									);
								case 'invalid-phone-4':
									throw new Error(
										'CUSTOMER_PHONE_NUMBER_INVALID'
									);
								case 'invalid-phone-5':
									throw new Error(
										'CUSTOMER_PHONE_NUMBER_INVALID'
									);
								case 'invalid-phone-6':
									throw new Error(
										'CUSTOMER_PHONE_NUMBER_INVALID'
									);
								case 'invalid-phone-7':
									throw new Error(
										'CUSTOMER_PHONE_NUMBER_INVALID'
									);
								case 'invalid-email':
									throw new Error('CUSTOMER_EMAIL_INVALID');
								case 'invalid-email-1':
									throw new Error('CUSTOMER_EMAIL_INVALID');
								case 'invalid-email-2':
									throw new Error('CUSTOMER_EMAIL_INVALID');
								case 'invalid-email-3':
									throw new Error('CUSTOMER_EMAIL_INVALID');
								case 'invalid-email-4':
									throw new Error('CUSTOMER_EMAIL_INVALID');
								case 'invalid-email-5':
									throw new Error('CUSTOMER_EMAIL_INVALID');
								case 'invalid-email-6':
									throw new Error('CUSTOMER_EMAIL_INVALID');
								case 'invalid-email-7':
									throw new Error('CUSTOMER_EMAIL_INVALID');
								case 'blocked':
									throw new Error('BAD_REQUEST');
								case 'blocked-1':
									throw new Error('BAD_REQUEST');
								case 'blocked-2':
									throw new Error('BAD_REQUEST');
								case 'blocked-3':
									throw new Error('BAD_REQUEST');
								case 'blocked-4':
									throw new Error('BAD_REQUEST');
								case 'blocked-5':
									throw new Error('BAD_REQUEST');
								case 'blocked-6':
									throw new Error('BAD_REQUEST');
								case 'blocked-7':
									throw new Error('BAD_REQUEST');
								case 'error':
									throw new Error('BAD_REQUEST');
								case 'error-1':
									throw new Error('BAD_REQUEST');
								case 'error-2':
									throw new Error('BAD_REQUEST');
								case 'error-3':
									throw new Error('BAD_REQUEST');
								case 'error-4':
									throw new Error('BAD_REQUEST');
								case 'error-5':
									throw new Error('BAD_REQUEST');
								case 'error-6':
									throw new Error('BAD_REQUEST');
								case 'error-7':
									throw new Error('BAD_REQUEST');
								default:
									break;
							}
						}
					}

					if (resp?.code && resp.code !== 200) {
						throw new Error('BAD_REQUEST');
					}

					const customer = {
						first_name: this.bookingData.personalDetails.firstName,
						last_name: this.bookingData.personalDetails.lastName,
						email: resp.customer.email,
						phone: this.bookingData.personalDetails.phone,
						birth_date: this.birthDayToUnixIntegerOrNull,
						quandoo_id: resp.customer.id,
					};

					this.trackReservation(resp.reservation, customer);
					this.goToScreen(this.overlayScreens.booking_confirmation);
					this.trackReservationProgress(700);
					this.maybeTrackConsent();
					this.toggleSplash('hide');
				})
				.catch((error) => {
					this.toggleSplash('hide');
					// To do implement error log
					wordpress.errorLog({
						eID: 'quandooCreateResError',
						metaData: {
							reservationData,
						},
						errorData: {
							error:
								error instanceof Error ? error.message : error,
						},
					});

					this.error.title = '';
					this.error.message = '';

					if (error.message === 'CUSTOMER_PHONE_NUMBER_INVALID') {
						this.error.visible = true;
						this.error.title = 'Invalid phone number';
						this.error.message =
							'Please re-enter your phone number and try again';
						this.error.callback = () => {
							this.resetWidgetAfterError();
							this.slotsShown = false;
						};
						return;
					}

					if (error.message === 'CUSTOMER_EMAIL_INVALID') {
						this.error.visible = true;
						this.error.title = 'Invalid Email';
						this.error.message =
							'Please re-enter your email and try again';
						this.error.callback = () => {
							this.resetWidgetAfterError();
							this.slotsShown = false;
						};
						return;
					}

					if (error.message === 'RESERVATION_NO_TABLES_AVAILABLE') {
						this.error.visible = true;
						this.error.title = 'Table No Longer Available';
						this.error.message =
							'Sorry this time is no longer available. Please try searching for another time';
						this.error.callback = () => {
							this.resetWidgetAfterError();
							this.slotsShown = false;
							this.goToScreen(
								this.overlayScreens.guests_date_time
							);
						};
						return;
					}

					if (error.message === 'BAD_REQUEST') {
						this.error.visible = true;
						this.error.title = 'Sorry there has been an error';
						this.error.message =
							'Please contact the restaurant to make this booking';
						this.error.callback = () => {
							this.resetWidgetAfterError();
							this.slotsShown = false;
						};
						return;
					}

					this.error.visible = true;
					this.error.title = 'Sorry there has been an error';
					this.error.message =
						'Please contact the restaurant to make this booking';
					this.error.callback = () => {
						this.resetWidgetAfterError();
						this.slotsShown = false;
						this.goToScreen(this.overlayScreens.guests_date_time);
					};
				});
		},
		async createOpenTableReservation() {
			const { tokenData, guests, time, personalDetails, experience } =
				this.bookingData;

			const dateTimeGMT = this.makeDateTimeGMT(
				this.reservationDateTime,
				time
			);

			const durationInSecs = this.getReservationDurationInSecs(
				guests.value
			);
			const endTime = dateTimeGMT.plus({ seconds: durationInSecs });

			const reminderData =
				this.getReminderDataForReservationDateTime(dateTimeGMT);

			const reservationData = {
				reservation_id: tokenData.token,
				first_name: personalDetails.firstName,
				last_name: personalDetails.lastName,
				email_address: personalDetails.email.toLowerCase(),
				phone_number: personalDetails.phone,

				merchant_id: this.restaurantIdInteger,
				created_at_timestamp: Math.floor(Date.now() / 1000),
				location: window.location.href,
				time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,

				actual_datetime: Math.floor(dateTimeGMT.toSeconds()),
				actual_date: this.getDateInFormatLongDate(dateTimeGMT),
				seconds_to_actual_datetime: Math.floor(
					Math.floor(dateTimeGMT.toSeconds()) -
						DateTime.now().toSeconds()
				),
				send_reminder: reminderData.sendReminder,
				seconds_to_reminder: Math.floor(reminderData.secondsToReminder),
				actual_start: time,
				actual_end: endTime.toFormat('HH:mm'),

				actual_guests: guests.value,
				special_event: personalDetails.specialOccasion || undefined,
				special_requests: personalDetails.specialRequests ?? '',
				offer_code: this.isValidPromotion
					? this.promotion.promo_code
					: '',
				offer_code_expiry: this.isValidPromotion
					? DateTime.fromJSDate(this.dates.max).toUnixInteger()
					: 0,

				message: this.consentText,
				consent: personalDetails.consent ?? false,
				country_code: personalDetails.country,
			};

			if (experience) {
				reservationData.experience_id = experience.id;
				reservationData.experience_version = experience.version;
				reservationData.experience_name = experience.title;
				reservationData.experience_description = experience.description;
				reservationData.experience_type = experience.type;
				reservationData.experience_price_per_cover = experience.price;
				reservationData.experience_pre_payment_required =
					experience.pre_payment_required;
			}

			if (this.birthDayToUnixIntegerOrNull) {
				reservationData.birth_date = this.birthDayToUnixIntegerOrNull;
			}

			this.mapUtmFields(reservationData);

			if (this.stripeTokenData) {
				const creditCardData = {};
				if (
					this.stripeTokenData.id &&
					this.stripeTokenData.card &&
					this.stripeTokenData.card.last4
				) {
					creditCardData.token = this.stripeTokenData.id;
					creditCardData.last4 = this.stripeTokenData.card.last4;
				}
				// Only happens during 3DS payments
				if (
					this.stripeTokenData.paymentMethod &&
					this.stripeTokenData.setupIntent
				) {
					creditCardData.setupIntent =
						this.stripeTokenData.setupIntent;
					creditCardData.paymentMethod =
						this.stripeTokenData.paymentMethod;
				}
				reservationData.credit_card = creditCardData;
			}

			try {
				const reservation = await openTable.makeReservation(
					reservationData
				);

				if (reservation.status === 200) {
					this.goToScreen(this.overlayScreens.booking_confirmation);
					this.trackReservationProgress(700);
					this.toggleSplash('hide');
					return;
				}

				const result = await reservation.json();

				switch (result.code) {
					case 424:
					case 409:
						await this.handleReservationError(result);
						break;
					default:
						throw new Error(
							'There was an issue during creating a new reservation'
						);
				}
			} catch (error) {
				await this.handleReservationError(error);
			}
		},
		amendReservation(requestedTime, extraInfoField = false) {
			const reservationData = {
				reservation_id: this.bookingData.reservationId,
				guests: this.bookingData.guests.value,
				dateTime: `${requestedTime.toFormat(
					'yyyy-MM-dd'
				)}T${requestedTime.toFormat('HH:mm:ssZZ')}`,
			};

			if (extraInfoField) {
				reservationData.extraInfo = extraInfoField;
			}

			wordpress
				.quandooAmendReservation({ ...reservationData })
				.then((r) => r.json())
				.then((resp) => {
					const customer = {
						first_name: resp.customer.firstName,
						last_name: resp.customer.lastName,
						phone: resp.customer.phoneNumber,
						email: resp.customer.email,
					};

					this.trackReservation(resp.reservation, customer);
					this.maybeTrackConsent();
					this.toggleSplash('hide');
					this.goToScreen(this.overlayScreens.booking_confirmation);
				})
				.catch((error) => {
					if (error.errorType === 'CUSTOMER_EMAIL_INVALID') {
						this.error.visible = true;
						this.error.title = 'Invalid email';
						this.error.message =
							'Please re-enter your email and try again';
						this.error.callback = () => {
							this.bookingData.personalDetails.email = '';
						};
						return;
					}
					if (error.errorType === 'CUSTOMER_PHONE_NUMBER_INVALID') {
						this.error.visible = true;
						this.error.title = 'Invalid phone number';
						this.error.message =
							'Please re-enter your phone number and try again';
						this.error.callback = () => {
							this.bookingData.personalDetails.phone = '';
						};
						return;
					}
					if (error.errorType === 'RESERVATION_NO_TABLES_AVAILABLE') {
						this.error.visible = true;
						this.error.title = 'Table No Longer Available';
						this.error.message =
							'Sorry this time is no longer available. Please try searching for another time';
						this.error.callback = () => {
							this.quandooTimes = [];
							this.bookingData.time = null;
							this.bookingData.quandooSlot = null;
							this.slotsShown = false;

							this.goToScreen(
								this.overlayScreens.guests_date_time
							);
						};
						return;
					}
					this.error.visible = true;
					this.error.title = 'Sorry there has been a problem';
					this.error.message = error.message;
					this.error.callback = () => {};
				});
		},
		cancelReservation() {
			wordpress
				.quandooCancelReservation(
					this.bookingData.reservationId,
					this.bookingData.email
				)
				.then((r) => r.json())
				.then((resp) => {
					exponea.identify(this.bookingData.email);

					exponea.track('debug', {
						description:
							'This debug event is to help with understanding the cancellation process. At this point the user has clicked the cancel reservation button on the first splash screen which can be viewed after clicking the button on the email.',
						response_code: resp.code,
						response_body: resp.body,
						response_success: resp.success,
						reservationId: this.bookingData.reservationId,
						booking_data_email: this.bookingData.email,
						reservationStatus: this.reservationStatus,
						cookie_consent: this.$store.state.cookieConsent,
					});

					this.goToScreen(this.overlayScreens.cancel_booking_confirm);
				});
		},

		/**
		 * Create an enquiry in Freshdesk
		 *
		 * @param requestedTime
		 * @param requestedEndTime
		 */
		createEnquiry() {
			const { location, guests, date, time, duration, personalDetails } =
				this.bookingData;

			const isoDate = DateTime.fromJSDate(date).toISODate();
			const phoneNumber = parseInt(
				personalDetails.phone.replace(/\s/g, '').replace('+', ''),
				RADIX
			);

			const { hour, minute } = DateTime.fromFormat(
				time,
				'HH:mm'
			).toObject();
			const reservationDateTime = DateTime.fromJSDate(date).set({
				hour,
				minute,
			});
			const reservationDateTimeGMT = this.makeDateTimeGMT(
				reservationDateTime,
				time
			);
			const formattedDate = this.getDateInFormatLongDate(
				reservationDateTimeGMT
			);

			const reservationInfo = {
				restaurant: location.post_title.replace(
					/[.,/#!$%^&*;:{}=\-_`~()]/g,
					''
				),
				guests: guests.value,
				date: isoDate,
				time,
				duration: duration ?? 1,
				firstName: personalDetails.firstName,
				lastName: personalDetails.lastName,
				email: personalDetails.email,
				phoneNumber,
				specialRequests: personalDetails.specialRequests || undefined,
				specialOccasion: personalDetails.specialOccasion || undefined,
			};

			const trackingInfo = {
				time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				event_source: this.reservationSource,
				merchant_id: this.restaurantIdInteger,
				actual_date: formattedDate,
				actual_datetime: Math.floor(reservationDateTimeGMT.toSeconds()),
				created_at_timestamp: Math.floor(Date.now() / 1000),
				phone: personalDetails.phone,
				location: window.location.href,
				consent: personalDetails.consent ?? false,
			};

			if (this.birthDayToUnixIntegerOrNull) {
				trackingInfo.birth_date = this.birthDayToUnixIntegerOrNull;
			}

			this.mapUtmFields(trackingInfo); // map the available utm fields to the trackingInfo object

			const reservationEnquiryData = {
				reservationInfo,
				trackingInfo,
			};

			openTable
				.createEnquiry(reservationEnquiryData)
				.then((r) => r.json())
				.then((response) => {
					if (response?.code && response.code !== 200) {
						this.toggleSplash('hide');

						// TODO error shows only once, change behaviour when decided
						this.error.title = 'Sorry there has been an error';
						this.error.message =
							'Please contact the restaurant to make this booking';

						this.goToScreen(this.overlayScreens.guests_date_time);
						return;
					}

					this.trackGAEvent('enquiry', 'conversions');

					this.goToScreen(this.overlayScreens.enquiry_confirmation);
					this.toggleSplash('hide');
				})
				.catch(() => {
					// TODO error shows only once, change behaviour when decided
					this.toggleSplash('hide');

					this.error.title = 'Sorry there has been an error';
					this.error.message =
						'Please contact the restaurant to make this booking';
				});
		},

		/**
		 * Updates customer data in exponea
		 */
		async updateCustomerInExponea() {
			await exponea.identify(
				this.bookingData.personalDetails.email.toLowerCase().trim()
			);

			const customer = {
				first_name: this.bookingData.personalDetails.firstName,
				last_name: this.bookingData.personalDetails.lastName,
				email: this.bookingData.personalDetails.email,
				phone: this.bookingData.personalDetails.phone,
			};

			exponea.update(customer);
		},

		/**
		 * Track event in Google Analythics
		 * @param {string} event
		 * @param {string} category
		 */
		trackGAEvent(event, category) {
			this.$gtag.event(event, {
				event_category: category,
			});
		},

		/**
		 * send an event into exponea when someone searches for a reservation
		 */
		trackReservationSearch() {
			const time = DateTime.fromFormat(this.bookingData.time, 'HH:mm');
			const dateTime = DateTime.fromJSDate(this.bookingData.date).set({
				hour: time.hour,
				minute: time.minute,
				second: 0,
			});

			const requestedTimeInGmt = this.makeDateTimeGMT(
				dateTime,
				this.bookingData.time
			);

			const reservationSearch = {
				time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				reservation_type:
					this.mode === this.modes.enquiry ? 'group' : 'standard',
				reservation_source: this.reservationSource,
				reservation_status: 'SEARCH',
				requested_restaurant: this.bookingData.location.post_title,
				requested_restaurant_merchant_id: this.restaurantIdString,
				requested_datetime: requestedTimeInGmt.toMillis(),
				requested_guests: this.bookingData.guests.value,
				requested_date: `${requestedTimeInGmt.toFormat(
					'cccc'
				)} ${this.dateOrdinal(
					requestedTimeInGmt.toFormat('dd')
				)} ${requestedTimeInGmt.toFormat('LLLL yyyy')}`,
				requested_start: this.bookingData.time,
				created_at_timestamp: DateTime.now().toMillis(),
				merchant_id: this.restaurantIdString,
				location: window.location.href,
				requested_result: this.requestedTimeObtained,
				restaurant_menu_link: `${window.location.protocol}//${window.location.host}${this.bookingData.location.menusLink}`,
				restaurant_phone: this.bookingData.location.phone_number,
				continue_url: `${window.location.protocol}//${
					window.location.host
				}/book-a-table/?isContinue=true&time=${
					this.bookingData.time
				}&date=${requestedTimeInGmt.toISODate()}&guests=${
					this.bookingData.guests.value
				}&brasserieId=${this.bookingData.location.ID}`,
			};
			this.$store.dispatch('getCookieConsent').then((resp) => {
				if (resp.consent === 'true') {
					exponea.track('reservation', reservationSearch);
				}
			});
		},

		/**
		 * Send a tracking event into exponea.
		 *
		 *  customer = {
		 *    first_name: '',
		 *    last_name: '',
		 *    email: '',
		 *    phone: ''
		 *  }
		 *
		 * @param reservation
		 * @param customer
		 */
		async trackReservation(reservation, customer) {
			if (!reservation?.id) {
				throw new Error(
					`No reservation ID - response: ${JSON.stringify(
						reservation
					)}`
				);
			}
			const shortId = this.compressUuid(reservation.id);

			await exponea.identify(customer.email.toLowerCase().trim());

			exponea.update(customer);

			// Do the bits to construct the GMT time
			const time = DateTime.fromFormat(this.bookingData.time, 'HH:mm');
			const requestedTime = DateTime.fromJSDate(
				this.bookingData.date
			).set({
				hour: time.hour,
				minute: time.minute,
			});
			const dateTime = this.makeDateTimeGMT(
				requestedTime,
				this.bookingData.time
			);

			// Calculate end time and duration
			const numGuests = parseInt(this.bookingData.guests.value, RADIX);
			const durationInSecs = this.getReservationDurationInSecs(numGuests);
			const endTime = dateTime.plus({ seconds: durationInSecs });
			const durationInMins = durationInSecs / 60;

			const reminderData =
				this.getReminderDataForReservationDateTime(dateTime);

			// Pre order bits
			const { pre_order } = reservation;
			const { pre_order_status } = reservation;
			const { pre_order_url } = reservation;

			const reservationEvent = {
				time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				cookie_consent: customer.cookie_consent,
				event_source: this.reservationSource,
				reservation_id: reservation.id,
				reservation_type: 'standard',
				reservation_source: this.reservationSource,
				merchant_id: this.bookingData.location.quandoo_id,
				actual_restaurant: this.bookingData.location.post_title,
				actual_guests: parseInt(numGuests, RADIX),
				actual_date: `${dateTime.toFormat('cccc')} ${this.dateOrdinal(
					dateTime.toFormat('dd')
				).replace(/^0(?:0:0?)?/, '')} ${dateTime.toFormat(
					'LLLL yyyy'
				)}`,
				actual_datetime: Math.floor(dateTime.toSeconds()),
				actual_start: this.bookingData.time,
				actual_end: endTime.toFormat('HH:mm'),
				created_at_timestamp: Math.floor(DateTime.now().toSeconds()),
				duration: durationInMins,
				tracking_engine: customer.tracking_engine,
				first_name: customer.first_name,
				last_name: customer.last_name,
				email: customer.email,
				phone: customer.phone,
				special_event:
					this.bookingData.personalDetails.specialOccasion ||
					undefined,
				special_requests:
					this.bookingData.personalDetails.specialRequests,
				location: window.location.href,
				offer_code: this.isValidPromotion
					? this.promotion.promo_code
					: '',
				offer_code_text: this.isValidPromotion
					? this.promotion.offer_text
					: '',
				offer_code_expiry: this.isValidPromotion
					? DateTime.fromJSDate(this.dates.max).toUnixInteger()
					: '',
				offer_code_tags: this.isValidPromotion
					? this.promotion.tags
					: '',
				restaurant_phone: this.bookingData.location.phone_number,
				restaurant_address: this.bookingData.location.address,
				restaurant_address1: this.bookingData.location.address_1,
				restaurant_city: this.bookingData.location.town_city,
				restaurant_postcode: this.bookingData.location.postcode,
				restaurant_email: this.bookingData.location.email,
				restaurant_map: this.bookingData.location.mapLink,
				restaurant_image: this.bookingData.location.main_image,
				restaurant_link: `${window.location.protocol}//${window.location.host}${this.bookingData.location.link}`,
				restaurant_menu_link: `${window.location.protocol}//${window.location.host}${this.bookingData.location.menusLink}`,
				seconds_to_actual_datetime: Math.floor(
					Math.floor(dateTime.toSeconds()) -
						DateTime.now().toSeconds()
				),
				send_reminder: reminderData.sendReminder,
				seconds_to_reminder: Math.floor(reminderData.secondsToReminder),
				reservation_number: reservation.number,
				reservation_status: reservation.status,
				cancel_url: `https://${window.location.hostname}/book-a-table/cancel?reservation_id=${reservation.id}`,
				reconfirm_url: `https://${window.location.hostname}/book-a-table/reconfirm-booking?reservation_id=${reservation.id}`,
				amend_url: `https://${window.location.hostname}/book-a-table?reservation_id=${reservation.id}`,
				short_cancel_url: `https://${window.location.hostname}/c/${shortId}`,
				short_amend_url: `https://${window.location.hostname}/a/${shortId}`,
				pre_order,
				pre_order_status,
				pre_order_url,
				reservation_operator: 'Quandoo',
			};

			this.mapUtmFields(reservationEvent); // map the available utm fields to the reservation event object

			exponea.track('reservation', reservationEvent);

			// Track GA Event
			this.$gtag.event('reservation', {
				event_category: 'conversions',
			});
			// Track in Bing !
			// window.uetq = window.uetq || [];
			// window.uetq.push({ ec: 'conversions', ea: 'reservation' })
			// TODO:  check if facebook is enabled and track there
			// Track in FB
			if (typeof fbq !== 'undefined') {
				// fbq('track', 'Lead')
				fbq('track', 'Lead');
			}
		},
		/**
		 * Gets reminder data for reservation in DateTime format
		 * @param reservationDateTimeGMT
		 */
		getReminderDataForReservationDateTime(reservationDateTimeGMT) {
			const reminder = {
				sendReminder: false,
				secondsToReminder: 0,
			};

			const {
				leadDays: reminderDays,
				cutOff,
				emailTime,
			} = this.reminderConfig;

			if (reminderDays <= 0) {
				return reminder;
			}

			const lastPossibleTimeForMail = reservationDateTimeGMT;
			lastPossibleTimeForMail.minus({ hours: cutOff });
			const shouldSendReminder =
				lastPossibleTimeForMail.toMillis() > DateTime.now().toMillis();

			const emailReminderTime = reservationDateTimeGMT;
			emailReminderTime.minus({ days: reminderDays });
			emailReminderTime.set({
				hour: emailTime[0],
				minute: emailTime[1],
			});

			const oneDayInSeconds = 86400;
			const secondsUntilReminderEmail =
				emailReminderTime.toSeconds() -
				DateTime.now().toSeconds() -
				reminderDays * oneDayInSeconds;

			if (shouldSendReminder && secondsUntilReminderEmail >= 0) {
				reminder.sendReminder = true;
				reminder.secondsToReminder = secondsUntilReminderEmail;
			}

			return reminder;
		},
		/**
		 * Gets reservation duration in seconds
		 * @param numberOfGuests
		 */
		getReservationDurationInSecs(numberOfGuests) {
			const twoHoursInSeconds = 7200;
			const oneHourThirtyMinutesInSeconds = 5400;

			return numberOfGuests >= 4
				? twoHoursInSeconds
				: oneHourThirtyMinutesInSeconds;
		},

		/**
		 * Track reservation progress
		 * @param step
		 */
		trackReservationProgress(step) {
			const properties = {
				progress: 0,
				progress_name: '',
				progress_description: '',
			};
			if (step === 100 && step > this.reservationStepTrackingProgress) {
				properties.progress = step;
				properties.progress_name = 'Seen';
				properties.progress_description =
					'Opened booking page and widget can be seen.';
			}
			if (step === 200 && step > this.reservationStepTrackingProgress) {
				properties.progress = step;
				properties.progress_name = 'Interacted';
				properties.progress_description =
					'User clicks in to any box to start (sign of engagement).';
			}
			if (step === 300 && step > this.reservationStepTrackingProgress) {
				properties.progress = step;
				properties.progress_name = 'Searched';
				properties.progress_description =
					'When search is clicked, to find available times.';
			}
			if (step === 400 && step > this.reservationStepTrackingProgress) {
				properties.progress = step;
				properties.progress_name = 'Chose Slot';
				properties.progress_description =
					'Selects and available time from the presented options.';
			}
			if (step === 500 && step > this.reservationStepTrackingProgress) {
				properties.progress = step;
				properties.progress_name = 'Entered Details';
				properties.progress_description =
					'Fired when user starts entering their details.';
			}
			if (step === 600 && step > this.reservationStepTrackingProgress) {
				properties.progress = step;
				properties.progress_name = 'Paid';
				properties.progress_description =
					'Only if required by restaurant/booking.';
			}
			if (step === 700 && step > this.reservationStepTrackingProgress) {
				properties.progress = step;
				properties.progress_name = 'Confirmed';
				properties.progress_description =
					'booking confirmation screen has been reached.';
			}
			if (step > this.reservationStepTrackingProgress) {
				this.log('booking_progress', step, properties);
				this.reservationStepTrackingProgress = step;
				this.$store.dispatch('getCookieConsent').then((resp) => {
					if (resp.consent === 'true') {
						exponea.track('booking_progress', properties);
						this.$gtag.event('checkout_progress', {
							checkout_step: Number(String(step)[0]),
							event_label: `${properties.progress_name} - Step ${step}`,
						});
					}
				});
			}
		},

		/**
		 * Check if we're on a specific restaurant page, and set the selected restaurant to that.
		 */
		setSelectedRestaurant() {
			if (this.onRestaurantPage) {
				this.$store.commit(
					'setSelectedRestaurant',
					this.restaurants.find((r) => {
						const restaurantSlug = this.getRestaurantSlug(
							r.post_title
						);
						return (
							restaurantSlug === this.$route.meta.name ||
							restaurantSlug === this.$route.meta.parent_brasserie
						);
					})
				);
				this.selectLocation(
					this.selectedRestaurant.ID,
					false,
					false,
					false,
					false
				);
			}
		},
		log(...params) {
			if (this.debug) {
				console.log(...params);
			}
		},
	},

	/**
	 *
	 */
	created() {
		this.resetBookingWidget();
		this.setSelectedRestaurant();
		this.specialOccasions = SPECIAL_OCCASIONS;

		document.addEventListener('click', (e) => {
			this.closeDropdownOnOutsideClick(e);
		});

		window.addEventListener('beforeunload', (event) => {
			event.stopImmediatePropagation();
			this.releaseExistingTimeSlot();
		});
	},
};
</script>

<style lang="scss">
.v-enter-active,
.v-leave-active {
	transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}

.slide-enter-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: ease-in;
	-webkit-transition-timing-function: ease-in;
	-o-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave-from {
	max-height: 100px;
	overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
	overflow: hidden;
	max-height: 0;
}

.c-inset-shadow {
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 4px;
		background: rgba(130, 137, 143, 0.4);
		filter: blur(2px);
	}
}

.c-top-shadow {
	box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.3);
}

.c-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: #0a2036 #d7d7cb;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #0a2036;
		border-radius: 0px;
	}
}

.c-w-1\/5-m {
	width: calc(20% - 0.75rem);
}

.c-l-1\/5-m {
	left: calc(20% + 0.375rem);
}

.c-l-2\/5-m {
	left: calc(40% + 0.375rem);
}

.c-l-3\/5-m {
	left: calc(60% + 0.375rem);
}

.custom-dropdown-extra-width {
	width: calc(100% + 2px);
	left: -1px;
}

.quandoo-time-slot {
	position: relative;
	border: 1px solid transparent;
	transition: 0.3s;

	&:hover {
		border: 1px solid;
		border-color: rgb(10 32 54 / var(--tw-border-opacity));
	}
}

.your-booking-bar {
	left: 50%;
	transform: translateX(-50%);
}

#desktop-footer-booking__date-picker__wrapper > div {
	position: absolute;
	left: -50%;
	transform: translateX(25%);
	bottom: 0;
}

.description-fit {
	text-wrap: pretty;
	white-space-collapse: preserve;
}
</style>
