<template>
	<div :class="parentClasses">
		<slot name="header"></slot>
		<div
			v-if="
				getNearbyRestaurants.length > 0 &&
				!isLocationRestrictedPromotion
			"
		>
			<carousel
				:settings="settings"
				:breakpoints="breakpoints"
				class="w-11/12 lg:w-9/12 mx-auto mt-10 c-hide-inactive-nav"
				:class="{ 'c-dark-nav': colour === 'light' }"
			>
				<slide
					v-for="(restaurant, index) in getNearbyRestaurants"
					:key="index"
					class="px-2"
					@click="
						selectLocation({
							id: restaurant.ID,
							locationBlocks: true,
						})
					"
				>
					<div
						class="slide-content bg-brown flex w-full flex-col px-1.5 py-0 lg:py-10 min-h-[130px] max-h-[140px] justify-center shadow-xl cursor-pointer"
					>
						<p class="smaller text-center mt-0">Nearby</p>
						<h6
							class="text-center my-0.5 break-words text-[16px] leading-[22px] tracking-[0.8px]"
						>
							{{ restaurant.post_title }}
						</h6>
						<p class="smaller text-center mt-0.5">
							{{ restaurant.distance }} miles
						</p>
					</div>
				</slide>
				<template #addons>
					<Navigation />
				</template>
			</carousel>
			<h4 class="mt-6" :class="getStyles('text')">or</h4>
		</div>
		<div
			class="w-10/12 lg:w-8/12 mx-auto border h-[50px] mt-6 relative"
			:class="getStyles('border')"
		>
			<input
				type="text"
				v-model="locationSearchString"
				class="pl-3 border-0 outline-0 w-full bg-transparent h-full placeholder:font-parisine placeholder:font-thin placeholder:text-sm"
				:class="getStyles(['placeholder', 'text'])"
				placeholder="Search Postcode / Town / Restaurant"
				@input="resetLocationOnInputChange"
			/>
			<div
				class="w-[50px] h-full absolute absolute-center-y right-0 border-l bg-transparent cursor-pointer"
				:class="getStyles('chevron')"
				@click="toggleLocationDropdown"
			>
				<chevron-down-icon
					class="w-[22px] absolute absolute-center"
				></chevron-down-icon>
			</div>
			<ul
				v-if="
					canShowLocationDropdown && !hasSelectedLocationFromDropdown
				"
				class="custom-dropdown-extra-width w-full border p-2.5 h-auto max-h-60 c-scrollbar overflow-y-scroll z-80"
				:class="
					!filteredRestaurants.length
						? 'hidden'
						: getStyles(['border', 'background'])
				"
			>
				<!--  Shows when results are loading  -->
				<li
					v-if="
						locationSearchRequired &&
						locationSearching &&
						showPostcodesProp &&
						!isEventsPageLocationDropdown
					"
					class="cursor-pointer font-parisine font-thin text-sm py-1.5 italic"
					:class="getStyles('text')"
				>
					thinking...
				</li>
				<li
					v-else-if="
						!showPostcodesProp && filteredRestaurants.length < 1
					"
					class="cursor-pointer font-parisine font-thin text-sm py-1.5 italic"
					:class="getStyles('text')"
				>
					No locations found
				</li>

				<li
					v-for="(restaurant, i) in filteredRestaurants"
					:key="i"
					class="cursor-pointer font-parisine font-thin text-sm py-1.5"
					:class="getStyles(['text', 'item-hover'])"
					@click="
						selectLocation({
							id: restaurant.ID,
							locationBlocks: false,
						})
					"
				>
					{{ restaurant.post_title }}
				</li>
				<template v-for="(restaurant, i) in locationSearchResults">
					<!--  Locations/Post codes found by user typing, only shows when filtered restaurants are empty  -->
					<li
						v-if="!isEventsPageLocationDropdown"
						:key="i"
						class="cursor-pointer font-parisine font-thin text-sm py-1.5"
						:class="getStyles(['text', 'item-hover'])"
						@click="
							restaurantLocationSearchFromSearchedLocation(
								restaurant
							)
						"
					>
						{{ restaurant.display_name }}
					</li>
				</template>

				<template
					v-for="(
						restaurant, i
					) in restaurantsFromLocationSearchSearchedLocation"
				>
					<!--  Restaurants found by clicking location search result and using coordinates to find restaurants  -->
					<li
						v-if="!isEventsPageLocationDropdown"
						:key="i"
						class="cursor-pointer font-parisine font-thin text-sm py-1.5 flex justify-between content-center"
						:class="getStyles(['text', 'item-hover'])"
						@click="
							selectLocation({
								id: restaurant.ID,
								locationBlocks: false,
							})
						"
					>
						<p class="font-normal text-base px-2 m-0">
							{{ restaurant.post_title }}
						</p>
						<p class="font-normal text-xs px-2 m-0">
							{{ restaurant.distance }} miles
						</p>
					</li>
				</template>
			</ul>
		</div>
		<slot name="footer"></slot>
	</div>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import wordpress from '@/plugins/wordpress';
import { ChevronDownIcon } from '@heroicons/vue/outline';
import { mapGetters } from 'vuex';

export default {
	name: 'RestaurantLocationSelector',
	components: {
		Carousel,
		Slide,
		Navigation,
		ChevronDownIcon,
	},
	props: {
		isEventsPageLocationDropdown: {
			type: Boolean,
			default: false,
		},
		isPromotion: {
			type: Boolean,
			default: false,
		},
		isLocationRestrictedPromotion: {
			type: Boolean,
			default: false,
		},
		parentClasses: {
			type: String,
			required: true,
		},
		restaurants: {
			type: Array,
			default: [],
		},
		colour: {
			type: String,
			required: true,
			validator(value) {
				return ['light', 'dark'].includes(value);
			},
		},
		showLocationDropdownExternalCondition: {
			type: Boolean,
			default: true,
		},
		showSearchingMessageExternalCondition: {
			type: Boolean,
			default: true,
		},
		showNoLocationsExternalCondition: {
			type: Boolean,
			required: false,
		},
		showPostcodesProp: {
			type: Boolean,
			default: true,
			required: false,
		},
	},
	data() {
		return {
			hasSelectedLocationFromDropdown: false,
			typingTimeout: null,
			locationSearchString: '',
			locationSearchResults: [],
			restaurantsFromLocationSearchSearchedLocation: [],
			locationSearching: false,
			showLocationDropdown: false,
			settings: {
				itemsToShow: 2,
				snapAlign: 'center',
			},
			breakpoints: {
				1024: {
					itemsToShow: 2,
					snapAlign: 'center',
				},
				1280: {
					itemsToShow: 3,
					snapAlign: 'center',
				},
			},
			hover: {
				menu: false,
				chat: false,
			},
		};
	},
	computed: {
		canShowLocationDropdown() {
			return (
				(this.showLocationDropdownExternalCondition &&
					this.showLocationDropdown) ||
				this.locationSearchString !== ''
			);
		},
		canShowNoLocations() {
			return (
				this.showLocationDropdownExternalCondition &&
				this.locationSearching
			);
		},
		selectedRestaurant() {
			return this.$store.state.selectedRestaurant;
		},
		locationSearchRequired() {
			return this.filteredRestaurants.length === 0;
		},
		filteredRestaurants() {
			const rest =
				this.restaurants.length === 0
					? this.$store.state.restaurants
					: this.restaurants;

			return this.filterByLocationString(rest);
		},
		...mapGetters(['getNearbyRestaurants']),
	},
	watch: {
		locationSearchString(newVal) {
			if (this.locationSearchRequired && newVal !== '') {
				this.getLocationSearchResults();
			}

			if (this.isEventsPageLocationDropdown && newVal === '') {
				this.hasSelectedLocationFromDropdown = false;
			}
			this.restaurantsFromLocationSearchSearchedLocation = [];
		},
	},
	methods: {
		// In case of input change in the location search, reset the selected restaurant
		resetLocationOnInputChange() {
			if (this.isEventsPageLocationDropdown) {
				this.$emit('locationSelected', {
					id: null,
					locationBlocks: false,
				});
				this.hasSelectedLocationFromDropdown = false;
			}
		},
		getStyles(element) {
			let styles = '';
			const isLight = this.colour === 'light';
			if (!Array.isArray(element)) {
				element = [element];
			}
			element.forEach((el) => {
				if (el === 'text') {
					styles += isLight ? ' text-brand' : ' text-cream';
				}
				if (el === 'border') {
					styles += isLight ? ' border-brand' : ' border-cream';
				}
				if (el === 'placeholder') {
					styles += isLight
						? ' placeholder:text-brand'
						: ' placeholder:text-cream';
				}
				if (el === 'chevron') {
					styles += isLight
						? ' border-brand bg-transparent hover:bg-brand text-brand hover:text-cream'
						: ' border-cream bg-transparent hover:bg-cream text-cream hover:text-brand';
				}
				if (el === 'background') {
					styles += isLight ? ' bg-cream' : ' bg-brand';
				}
				if (el === 'item-hover') {
					styles += isLight
						? ' text-brand hover:bg-brand hover:text-cream'
						: ' text-cream hover:bg-cream hover:text-brand';
				}
			});
			return styles;
		},

		maybeSetRestaurant() {
			if (this.filteredRestaurants.length === 1) {
				this.$store.commit(
					'setSelectedRestaurant',
					this.filteredRestaurants[0].ID
				);
			}
		},

		toggleLocationDropdown() {
			this.showLocationDropdown = !this.showLocationDropdown;
			this.locationSearchString = '';
			this.restaurantsFromLocationSearchSearchedLocation = [];
			this.$emit('locationDropdownToggle', this.showLocationDropdown);
			// If we re-render the drop-down, selected restaurant will be lost so we need to emit the event to the parent component
			if (this.isEventsPageLocationDropdown) {
				this.$emit('locationSelected', {
					id: null,
					locationBlocks: false,
				});
			}
		},
		restaurantLocationSearchFromSearchedLocation(location) {
			this.locationSearching = true;
			this.locationSearchResults = [];
			wordpress
				.getRestaurantsByDistance(location.lat, location.lon)
				.then((r) => r.json())
				.then((resp) => {
					this.restaurantsFromLocationSearchSearchedLocation = resp;
					this.locationSearching = false;
				});
		},
		getLocationSearchResults() {
			this.locationSearchResults = [];
			clearTimeout(this.typingTimeout);
			this.typingTimeout = setTimeout(() => {
				if (this.showPostcodesProp) {
					this.locationSearching = true;
					if (!this.isPromotion) {
						const isPostCode = this.locationSearchString.search(
							/[A-Za-z]{1,2}[0-9]{1,2}/
						);
						if (isPostCode > -1) {
							this.getPostcodes();
						} else {
							this.getAddresses();
						}
					}
				}
			}, 750);
		},
		getPostcodes() {
			fetch(
				`https://api.postcodes.io/postcodes/${this.locationSearchString}/autocomplete`
			)
				.then((r) => r.json())
				.then((resp) => {
					resp.result.slice(0, 5).forEach((p) => {
						fetch(`https://api.postcodes.io/postcodes/${p}`)
							.then((r) => r.json())
							.then((resp) => {
								this.locationSearchResults.push({
									display_name: resp.result.postcode,
									lat: resp.result.latitude,
									lon: resp.result.longitude,
								});
								this.locationSearching = false;
							});
					});
				});
		},
		getAddresses() {
			if (this.locationSearchString !== '') {
				fetch(
					`https://api.locationiq.com/v1/autocomplete.php?key=3a70f48b1804e7&limit=5&countrycodes=gb&q=${this.locationSearchString}&format=json`
				)
					.then((r) => r.json())
					.then((resp) => {
						this.locationSearchResults = resp;
						this.locationSearching = false;
					});
			}
		},
		selectLocation(data) {
			this.$emit('locationSelected', data);
			if (this.isEventsPageLocationDropdown) {
				this.locationSearchString =
					this.$store.getters.getRestaurantById(data.id).post_title;
				this.hasSelectedLocationFromDropdown = true;
				this.showLocationDropdown = false;
				this.$emit('locationDropdownToggle', this.showLocationDropdown);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.c-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: #d7d7cb #0a2036;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #d7d7cb;
		border-radius: 0px;
	}
}

.custom-dropdown-extra-width {
	width: calc(100% + 2px);
	left: -1px;
	position: relative;
}

.carousel__slide.slide-content {
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.4);
}

.events-locations-dropdown input {
	background: #0a2036;
}
.events-locations-dropdown > div {
	width: 350px;
	margin-bottom: 30px;
}
</style>
